import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatAccordion } from '@angular/material/expansion';
import { TemplateWorkflowSchemaDto, DocumentWorkflowDto } from 'src/app/api';
import { WorkflowModeEdition } from 'src/app/core/standard/enums/workflow/workflow-edition-type.enum';
import { WorkflowConfiguration } from 'src/app/shared/components/ctbox-workflow/models/workflow-configuration.model';

@Component({
    selector: 'ctbox-workflow-panel',
    templateUrl: './ctbox-workflow-panel.component.html',
    styleUrls: ['./ctbox-workflow-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxWorkflowPanelComponent implements OnInit {

    @Input() workflowList: WorkflowConfiguration[];
    @Input() modeEdition: WorkflowModeEdition;
    @Input() workFlowStarted = false;

    @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
    @Output() resumeWorkflowSelected = new EventEmitter<void>();

    public panelOpenState: boolean;
    public isEditable: boolean;

    public approvers: Array<TemplateWorkflowSchemaDto | DocumentWorkflowDto>;
    public negotiators: Array<TemplateWorkflowSchemaDto | DocumentWorkflowDto>;

    public document: any;

    constructor() {
    }

    public ngOnInit(): void {
        this.isEditable = this.modeEdition === WorkflowModeEdition.editionTemplate ? true : false;
    }

    public checkAction(ob: MatCheckboxChange, panel) {
        if (ob.checked) {
            panel.open();
            return;
        } else {
            panel.close();
        }
    }

    public hasSeveralUser(): boolean {
        return this.isEditable;
    }

    public beforePanelClosed(panel) {
        panel.isExpanded = true;
    }

    public beforePanelOpened(panel) {
        panel.isExpanded = true;
    }

    public closeAllPanels() {
        this.Accordion.closeAll();
    }

    public openAllPanels() {
        this.Accordion.openAll();
    }

    public resumeWorkflow() {
        this.resumeWorkflowSelected.emit();
    }
}
