import { Directive, Injectable, OnDestroy } from "@angular/core";
import { BasePlugin } from "../base/base-plugin";
import { Editor, ContextualBalloon, Widget, ViewDocumentClickEvent, ClickObserver, DowncastWriter, DomEventData, Writer, Element, Node, ViewElement, ViewDocumentKeyDownEvent, EventInfo, KeyEventData } from "ckeditor5";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { GlobalConstant } from "../../models/base/global-constant";
import { ContextEditorTypes } from "../../models/base/context-editor-types";
import { RepeatableFragmentSchemaService } from "../../schema/repeatable-fragment/repeatable-fragment-schema.service";
import { RepeatableFragmentDataViewToModelConverterService } from "../../converters/repeatable-fragment/repeatable-fragment-data-view-to-model-converter.service";
import { RepeatableFragmentModelToEditorViewConverterService } from "../../converters/repeatable-fragment/repeatable-fragment-model-to-editor-view-converter.service";
import { RepeatableFragmentModelToDataViewConverterService } from "../../converters/repeatable-fragment/repeatable-fragment-model-to-data-view-converter.service";
import DeleteRepeatableFragmentCommand from "../../commands/repeatable-fragment/delete-repeatable-fragment-command";
import AddRepeatableFragmentCommand from "../../commands/repeatable-fragment/add-repeatable-fragment-command";
import { SchemaModel } from "../../models/schema/schema-model";
import RepeatableFragmentBalloonView from "../../ui/repeatable-fragment/repeatable-fragment-balloon-view.directive";
import { RepeatableFragmentDataViewUtilsService } from "../../utils/repeatable-fragment/repeatable-fragment-data-view-utils.service";
import { CheckInputToDeleteKeyCode } from "../../models/base/keycode";

@Directive({
    selector: 'app-repeatable-fragment-plugin',
})
@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = 'RepeatableFragment';
    public static readonly ADD_COMMAND_NAME = 'add-repeatable-fragment';
    public static readonly DELETE_COMMAND_NAME = 'delete-repeatable-fragment';
    public static readonly DELETE_OPTION = $localize`:@@BorrarCheckFragment:BORRAR`;
    public static readonly INSERT_REPEATABLE_FRAGMENT = $localize`:@@InsertarFragmentoRepetibleEtiquetaBotonBotoneraPlugin:Insertar un fragmento repetible`;

    public static get pluginToolbarElementName() { return RepeatableFragmentPlugin.PLUGIN_NAME; }
    public static get visualSelectionMarker() { return RepeatableFragmentPlugin.PLUGIN_NAME; }
    public static get requires() { return [Widget, ContextualBalloon]; }

    public static readonly MODEL_ENTITIES: { [name: string]: SchemaModel; } = {
        'container':            {   model: 'repeatable-fragment-container',     dataView: 'opt',    editionView: 'repeatable-fragment-container'    },
        'fragmentCount':        {   model: 'repeatable-fragment-count',         dataView: '',       editionView: 'repeatable-fragment-count'        },
        'actionContainer':      {   model: 'action-container',                  dataView: '',       editionView: 'action-container'                 },
        'action':               {   model: 'action',                            dataView: '',       editionView: 'action'                           },
        'dataType':             {   model: 'data-type',                         dataView: '',       editionView: 'data-type'                        },
        'descriptionAction':    {   model: 'description-action',                dataView: '',       editionView: 'plusminusgroup-text'              },
        'fragment':             {   model: 'repeatable-fragment-group',         dataView: '',       editionView: 'repeatable-fragment-group'        },
        'description':          {   model: 'repeatable-fragment-description',   dataView: 'hDesc',  editionView: 'repeatable-fragment-description'  },
        'content':              {   model: 'repeatable-fragment',               dataView: '',       editionView: 'repeatable-fragment'              },
        'fragmentPosition':     {   model: 'position',                          dataView: '',       editionView: 'position'                         },
        'plusButton':           {   model: '',                                  dataView: '',       editionView: 'button-repeatable-plus'           },
        'minusButton':          {   model: '',                                  dataView: '',       editionView: 'button-repeatable-minus'          },
    };

    public static readonly ATTRIBUTE_REPEATABLE_FRAGMENT_IS_VALID_CLASS_EDITION_VIEW = "repeatable-fragment-is-valid";
    public static readonly ATTRIBUTE_REPEATABLE_FRAGMENT_IS_NOT_VALID_CLASS_EDITION_VIEW = "repeatable-fragment-is-not-valid";
    public static readonly ID_REPEATABLE_FRAGMENT_PREFIX = 'ck-repeable-fragment-id-';

    protected override mappers = [
        RepeatableFragmentPlugin.MODEL_ENTITIES.container.editionView,
        RepeatableFragmentPlugin.MODEL_ENTITIES.action.editionView,
        RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.editionView,
        RepeatableFragmentPlugin.MODEL_ENTITIES.description.editionView,
        RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.editionView,
        RepeatableFragmentPlugin.MODEL_ENTITIES.content.editionView
    ];

    protected override commands = {
        [RepeatableFragmentPlugin.ADD_COMMAND_NAME]: AddRepeatableFragmentCommand,
        [RepeatableFragmentPlugin.DELETE_COMMAND_NAME]: DeleteRepeatableFragmentCommand,
    };

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.REPEATABLE_FRAGMENT,
        pluginToolbarElementName: RepeatableFragmentPlugin.pluginToolbarElementName,
        tooltip: RepeatableFragmentPlugin.INSERT_REPEATABLE_FRAGMENT,
        hasTooltip: true,
        hasText: true
    };

    private balloonView: RepeatableFragmentBalloonView;
    private userInterfaceService: UserInterfaceService;
    private dataViewToModelConverter: RepeatableFragmentDataViewToModelConverterService;
    private modelToDataViewConverter: RepeatableFragmentModelToDataViewConverterService;
    private modelToEditorViewConverter: RepeatableFragmentModelToEditorViewConverterService;
    private repeatableFragmentSchema: RepeatableFragmentSchemaService;
    private respeatableFragmentDataViewUtilsService: RepeatableFragmentDataViewUtilsService;

    private readonly FIRST_POSITION_CHILD = 0;
    private readonly CHILD_FRAGMENT_GROUP = 3;

    constructor(editor: Editor) {
        super(editor);
        this.repeatableFragmentSchema = new RepeatableFragmentSchemaService();
        this.dataViewToModelConverter = new RepeatableFragmentDataViewToModelConverterService();
        this.modelToDataViewConverter = new RepeatableFragmentModelToDataViewConverterService();
        this.modelToEditorViewConverter = new RepeatableFragmentModelToEditorViewConverterService();
        this.userInterfaceService = new UserInterfaceService();
        this.respeatableFragmentDataViewUtilsService = new RepeatableFragmentDataViewUtilsService();
    }

    public init(): void {
        super.init();
        this.defineCustomListeners();
    }

    protected defineSchema(): void {
        const schema = this.editor.model.schema;
        this.repeatableFragmentSchema.defineSchema(schema);
    }

    protected defineConverters(): void {
        const conversion = this.editor.conversion;
        this.dataViewToModelConverter.configureConverters(conversion);
        this.modelToDataViewConverter.configureConverters(conversion);
        this.modelToEditorViewConverter.configureConverters(conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
        const viewDocument = this.editor.editing.view.document;
        this.editor.plugins.get('ClipboardPipeline').on('inputTransformation', this.handlePasteEvent.bind(this), { priority: 'high' });
        this.listenTo<ViewDocumentKeyDownEvent>(viewDocument, 'keydown', this.handleKeyDownEvent.bind(this), { priority: 'high' });
        this.disableToolbarButton(RepeatableFragmentPlugin.ADD_COMMAND_NAME);
    }

    protected override toolbarExecuteOperation(): void {
        this.editor.execute(RepeatableFragmentPlugin.ADD_COMMAND_NAME);
    }

    protected enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
    }

    protected override getPluginDataViewClass(): string {
        return RepeatableFragmentPlugin.MODEL_ENTITIES.container.dataView;
    }

    protected override getPluginModelName(): string {
        return RepeatableFragmentPlugin.MODEL_ENTITIES.container.model;
    }

    protected override processElementsPaste(elements: Node[]): void {
        const view = this.editor.editing.view;

        view.change(writer => {
            elements.forEach(element => {
                if (element instanceof ViewElement && this.respeatableFragmentDataViewUtilsService.isRepeatableFragment(element)) {
                    const childFragmentGroup = (element as Element).getChild(this.CHILD_FRAGMENT_GROUP);
                    if (childFragmentGroup instanceof ViewElement) {
                        const newId = this.pluginUtils.generateId(RepeatableFragmentPlugin.ID_REPEATABLE_FRAGMENT_PREFIX) + "_0";
                        writer.setAttribute(GlobalConstant.ATTRIBUTE_ID, newId, childFragmentGroup);
                    }
                }
            });
        });
    }

    protected override setupModelPostFixing(): void {
        const model = this.editor.model;
        const useGrandparent = true;
        model.document.registerPostFixer(writer => this.contentFirstTypeShouldInheritStylesPostFixer(model, writer, RepeatableFragmentPlugin.MODEL_ENTITIES.content.model, useGrandparent));
    }

    private handleClickEvent(_event: Event, data: DomEventData): void {
        this.handleClickEventOnUI();
        this.handleClickEventOnAction(data);
    }

    private handleClickEventOnAction(data: DomEventData): void {
        const clickedViewElement = data.target;
        const selectedElement = this.editor.editing.mapper.toModelElement(clickedViewElement);

        if (!selectedElement || selectedElement.name !== RepeatableFragmentPlugin.MODEL_ENTITIES.action.model) {
            return;
        }

        const dataType = selectedElement.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model);
        const parentElement = selectedElement.parent as Element;

        if (dataType === GlobalConstant.ADD) {
            this.handleAddAction(parentElement);
        } else if (dataType === GlobalConstant.DELETE) {
            this.handleDeleteAction(selectedElement, parentElement);
        }
    }

    private handleAddAction(parentElement: Element): void {
        const model = this.editor.model;
        const repeatableFragmentCount = Number(parentElement.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model));
        const newRepeatableFragmentCount = repeatableFragmentCount + 1;

        if (newRepeatableFragmentCount > 1) {
            const deleteAction = this.getDeleteAction(parentElement);
            model.change(writer => {
                this.enableDeleteAction(writer, deleteAction);
            });
            this.editor.editing.view.change((writer: DowncastWriter) => {
                const deleteActionView = this.editor.editing.mapper.toViewElement(deleteAction);
                writer.removeClass(GlobalConstant.ATTRIBUTE_DISABLED, deleteActionView);
            });
        }

        model.change(writer => {
            this.updateRepeatableFragmentCount(writer, parentElement, newRepeatableFragmentCount);
            this.createNewFragment(writer, parentElement, newRepeatableFragmentCount);
        });
    }

    private handleDeleteAction(selectedElement: Element, parentElement: Element): void {
        const model = this.editor.model;
        const repeatableFragmentCount = Number(parentElement.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model));
        const newRepeatableFragmentCount = repeatableFragmentCount - 1;

        if (newRepeatableFragmentCount === 0) {
            return;
        }

        if (newRepeatableFragmentCount === 1) {
            model.change(writer => {
                this.disabledDeleteAction(writer, selectedElement);
            });

            this.editor.editing.view.change((writer: DowncastWriter) => {
                const selectedElementView = this.editor.editing.mapper.toViewElement(selectedElement);
                writer.addClass(GlobalConstant.ATTRIBUTE_DISABLED, selectedElementView);
            });
        }

        model.change(writer => {
            this.updateRepeatableFragmentCount(writer, parentElement, newRepeatableFragmentCount);
            this.deleteLastFragment(writer, parentElement, repeatableFragmentCount);
        });
    }

    private updateRepeatableFragmentCount(writer: Writer, parentElement: Element, newRepeatableFragmentCount: number): void {
        writer.setAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model, newRepeatableFragmentCount, parentElement);
    }

    private disabledDeleteAction(writer: Writer, selectedElement: Element): void {
        writer.setAttribute(GlobalConstant.ATTRIBUTE_DISABLED, 'true', selectedElement);
    }

    private enableDeleteAction(writer: Writer, selectedElement: Element): void {
        writer.setAttribute(GlobalConstant.ATTRIBUTE_DISABLED, 'false', selectedElement);
    }

    private createNewFragment(writer: Writer, parentElement: Element, position: number): void {
        const repeatableFragmentGroup = this.getRepeatableFragmentGroup(parentElement);
        const newFragment = this.createNewFragmentElement(writer, position);
        writer.insert(newFragment, writer.createPositionAt(repeatableFragmentGroup, 'end'));

        this.createFragmentElementContent(repeatableFragmentGroup, writer, newFragment);
    }

    private deleteLastFragment(writer: Writer, parentElement: Element, position: number): void {
        const repeatableFragmentGroup = this.getRepeatableFragmentGroup(parentElement);
        const fragmentToDelete = this.getRepeatableFragmentByPosition(repeatableFragmentGroup, position);

        this.pluginUtils.removeMarkersInElement(writer, fragmentToDelete);
        writer.remove(fragmentToDelete);
    }

    private createNewFragmentElement(writer: Writer, position: number): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.content.model, { position });
    }

    private createFragmentElementContent(repeatableFragmentGroup: Element, writer: Writer, newFragment: Element) {
        const firstRepeatableFragment = this.getFirstRepeatableFragment(repeatableFragmentGroup);

        const newContent = this.simulatePasteClipboard(firstRepeatableFragment);
        const modelContent = this.editor.data.toModel(newContent);

        writer.append(modelContent, newFragment);
    }

    private getFirstRepeatableFragment(repeatableFragmentGroup: Element): Element {
        for (const child of repeatableFragmentGroup.getChildren()) {
            if ((child as Element).name === RepeatableFragmentPlugin.MODEL_ENTITIES.content.model) {
                return child as Element;
            }
        }
    }

    private getRepeatableFragmentByPosition(repeatableFragmentGroup: Element, position: number): Element {
        for (const child of repeatableFragmentGroup.getChildren()) {
            const name = (child as Element).name;
            const positionAttribute = (child as Element).getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model);
            if (name === RepeatableFragmentPlugin.MODEL_ENTITIES.content.model && positionAttribute === position) {
                return child as Element;
            }
        }
    }

    private getRepeatableFragmentGroup(parentElement: Element): Element {
        for (const child of parentElement.getChildren()) {
            if ((child as Element).name === RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model) {
                return child as Element;
            }
        }
    }

    private getDeleteAction(repeatableFragmentGroup: Element): Element {
        for (const child of repeatableFragmentGroup.getChildren()) {
            const name = (child as Element).name;
            const dataTypeAttribute = (child as Element).getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model);
            if (name === RepeatableFragmentPlugin.MODEL_ENTITIES.action.model && dataTypeAttribute === GlobalConstant.DELETE) {
                return child as Element;
            }
        }
    }

    private handleClickEventOnUI(): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const repeatableFragment = this.pluginUtils.getSelectedContainerWithClass(this.editor, RepeatableFragmentPlugin.MODEL_ENTITIES.container.editionView);
        if (!repeatableFragment) {
            return;
        }
        const id = repeatableFragment.getAttribute(GlobalConstant.ATTRIBUTE_ID);

        if (!id) {
            return;
        }

        if (!this.pluginUtils.isViewElementInteractableInTargetEditor(repeatableFragment, RepeatableFragmentPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        this.showUI(id);
    }

    private showUI(id: string): void {
        this.balloonView = (this.balloonView && this.balloonView.id === id) ? this.balloonView : this.setBalloonView(id);

        this.userInterfaceService.addUI(this.editor, this.balloon, this.balloonView,
            RepeatableFragmentPlugin.MODEL_ENTITIES.container.editionView, RepeatableFragmentPlugin.visualSelectionMarker);
    }

    private setBalloonView(id: string): RepeatableFragmentBalloonView {
        const newBalloonView = this.createBalloonView(id);

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, newBalloonView,
            RepeatableFragmentPlugin.visualSelectionMarker, this);

        return newBalloonView;
    }

    private createBalloonView(id: string): RepeatableFragmentBalloonView {
        const editor = this.editor;
        const balloonView = new RepeatableFragmentBalloonView(editor.locale);
        balloonView.id = id;
        this.configureDeleteButton(editor, balloonView);

        return balloonView;
    }

    private configureDeleteButton(editor: Editor, balloonView: RepeatableFragmentBalloonView): void {
        balloonView.deleteButtonView.on('execute', () => {
            editor.execute(RepeatableFragmentPlugin.DELETE_COMMAND_NAME, balloonView.id);
            this.userInterfaceService.hideBalloonUI(this.editor, this.balloon, balloonView, RepeatableFragmentPlugin.visualSelectionMarker, this);
        });
    }

    private defineCustomListeners(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo(viewDocument, 'blur', this.validateContent.bind(this));
        this.listenTo(viewDocument, 'focus', this.validateContent.bind(this));
    }

    private validateContent(_event, data): void {
        const clickedViewElement = data.target;

        const isContentElementBlured = clickedViewElement?.hasClass(RepeatableFragmentPlugin.MODEL_ENTITIES.content.editionView);

        if (!isContentElementBlured) {
            return;
        }

        const contentElement = this.editor.editing.mapper.toModelElement(clickedViewElement);

        this.updateElementValidity(contentElement);
    }

    private updateElementValidity(contentElement: Element): void {
        const firstChild = contentElement.getChild(this.FIRST_POSITION_CHILD) as Element;
        const isParagraph = firstChild?.name === GlobalConstant.MODEL_PARAGRAPH;
        const isPlugin = firstChild?.name !== GlobalConstant.MODEL_PARAGRAPH;

        const isValid = (isParagraph && firstChild.childCount > 0) || isPlugin;

        this.editor.editing.model.change((writer: Writer) => {
            const grandParent = contentElement.parent.parent;
            const id = (grandParent as Element)?.getAttribute(GlobalConstant.ATTRIBUTE_ID)?.toString();

            this.updateElementValidation(writer, grandParent, id, isValid);
        });
    }

    private updateElementValidation(writer: Writer, grandParent, id: string, isValid: boolean) {
        writer.setAttribute(GlobalConstant.ATTRIBUTE_IS_VALID, isValid, grandParent as Element);
        this.editor.editing.view.change((writer: DowncastWriter) => {
            const elementView = this.pluginUtils.getElementView(this.editor, id);

            const classToRemove = isValid
                ? RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_NOT_VALID_CLASS_EDITION_VIEW
                : RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_VALID_CLASS_EDITION_VIEW;

            const classToAdd = isValid
                ? RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_VALID_CLASS_EDITION_VIEW
                : RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_NOT_VALID_CLASS_EDITION_VIEW;

            writer.removeClass(classToRemove, elementView);
            writer.addClass(classToAdd, elementView);
        });
    }

    private handleKeyDownEvent(event: EventInfo<'keydown'>, data: KeyEventData): void {
        const isAnyRemoveKeyPressed = data.keyCode === CheckInputToDeleteKeyCode.BACKSPACE || data.keyCode === CheckInputToDeleteKeyCode.DELETE;

        if (!isAnyRemoveKeyPressed) {
            return;
        }

        const selection = this.editor.model.document.selection;
        const firstPosition = selection.getFirstPosition();
        const blockElement = firstPosition!.parent;
        const parentBlockElement = blockElement.parent;

        const tryingDeleteBackspaceBegginingDescriptionOrContent = data.keyCode === CheckInputToDeleteKeyCode.BACKSPACE &&
            ((parentBlockElement?.name === RepeatableFragmentPlugin.MODEL_ENTITIES.description.model ||
                parentBlockElement?.name === RepeatableFragmentPlugin.MODEL_ENTITIES.content.model) &&
                firstPosition?.isAtStart);

        if (tryingDeleteBackspaceBegginingDescriptionOrContent) {
            data.preventDefault();
            data.stopPropagation();
            event.stop();
            event.return = true;
        }
    }
}
