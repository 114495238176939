import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { ClauseDto, UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { tap, takeUntil } from 'rxjs/operators';
import { ClauseService } from 'src/app/api/api/standard/clause.service';

@Component({
    selector: 'app-clauses-tree-text',
    templateUrl: './clauses-tree-text.component.html',
    styleUrls: ['./clauses-tree-text.component.scss']
})
export class ClausesTreeTextComponent implements OnInit, OnDestroy {

    public clausesListViewed: ClauseDto[] = [];
    public selectedClauses: ClauseDto[] = [];
    public isLoadingFirstTime = true;
    public isSelectedClauses: boolean;
    public clausesNodeActions: NodeTreeActionType[] = [NodeTreeActionType.Selected, NodeTreeActionType.Checked];
    public rootActions: NodeTreeActionType[] = [NodeTreeActionType.Selected];
    public userInfo: UserInfoDTO;
    public clause: ClauseDto = {
        id: '',
        name: '',
        description: ''
    };
    public currentNodeId: string;

    public actionBeforeCloseFinishHim = new BehaviorSubject<boolean>(false);
    public actionAfterCloseFinishHim = new Subject<boolean>();
    public isValidSubscription = new BehaviorSubject<boolean>(false);

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private loginService: AuthorizeService,
        private apiClauseService: ClauseService
    ) {
        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(userInfo => {
                this.userInfo = userInfo;
            });
    }

    public ngOnInit(): void {
        if (this.actionBeforeCloseFinishHim) {
            this.actionBeforeCloseFinishHim
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((doAction: boolean) => {
                    this.getClauseContent(doAction);
                });
        }

        this.isLoadingFirstTime = false;
        this.loginService.forceRefreshUserInfo();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public selectedNodeAction(node: FileFlatNode): void {
        if (!node || node.type !== NodeTreeNodeType.Clause) {
            return;
        }

        const clause = this.clausesListViewed.find(clauseInList => clauseInList.id === node.id);

        if (!!clause) {
            this.currentNodeId = node.id;
            this.clause = clause;
            return;
        }

        this.apiClauseService.get(node.id).subscribe((clauseGotten: ClauseDto) => {
            this.currentNodeId = node.id;
            this.clause = clauseGotten;
            this.clausesListViewed.push(clauseGotten);
        });

    }

    public checkedNodeAction(node: FileFlatNode): void {
        if (!node || node.type !== NodeTreeNodeType.Clause) {
            return;
        }

        node.isChecked ? this.addToChecked(node) : this.removeFromChecked(node);
    }

    private removeFromChecked(node: FileFlatNode): void {
        const index = this.selectedClauses.findIndex((clause: ClauseDto) => clause.id === node.id);
        const clauseUnchecked = this.selectedClauses.splice(index, 1);
        this.clause = clauseUnchecked[0];
        this.currentNodeId = node.id;
        this.updateButtonState();
    }

    private addToChecked(node: FileFlatNode): void {
        const clauseChecked = this.clausesListViewed.find(clause => clause.id === node.id);

        if (!!clauseChecked) {
            this.updateClauseSelected(clauseChecked, node);
            return;
        }

        this.apiClauseService.get(node.id).subscribe((clause: ClauseDto) => {
            this.clausesListViewed.push(clause);
            this.updateClauseSelected(clause, node);
        });
    }

    private updateClauseSelected(clauseSelected: ClauseDto, node: FileFlatNode): void {
        this.clause = clauseSelected;
        this.selectedClauses.push(clauseSelected);
        this.currentNodeId = node.id;
        this.updateButtonState();
    }

    private getClauseContent(doAction: boolean): void {
        if (!doAction) {
            return;
        }

        const observables = [];
        this.selectedClauses.forEach((clause: ClauseDto) => {
            if (!clause.description || clause.description.trim() === '') {
                observables.push(
                    this.apiClauseService.get(clause.id).pipe(
                        tap((clauseWithContent: ClauseDto) => {
                            clause.name = clauseWithContent.name;
                            clause.description = clauseWithContent.description;
                            clause.currentVersion = clauseWithContent.currentVersion;
                            clause.clauseVersions = clauseWithContent.clauseVersions;
                        })));
            }
        });

        if (observables.length === 0) {
            this.actionAfterCloseFinishHim.next(true);
            return;
        }

        forkJoin(observables).subscribe(() => {
            this.actionAfterCloseFinishHim.next(true);
        });
    }

    private updateButtonState() {
        this.isValidSubscription.next(this.selectedClauses.length > 0);
    }
}
