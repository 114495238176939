import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../configuration';
import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor.service';
import { IJsonPatchDocument } from '../../model/json-patch.model';
import { Observable } from 'rxjs';
import { ClauseDto } from '../../model/clause-dto.model';

@Injectable()
export class ClauseService {

    protected basePath = '/api/clause';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) { }

    public get(clauseId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public exists(clauseId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}/exists`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getFromFolder(folderId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/folder/${folderId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public create(clause: ClauseDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = clause;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public patch(clauseId: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public update(clause: ClauseDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.body = clause;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public delete(clauseId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public search(searchTerm: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/search/${searchTerm}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public saveAndReviewedLldPending(clause: ClauseDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = clause;
        apiRequest.endpointUrl = `${this.basePath}/save-and-reviewed-lld-pending`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public updateClauseFolder(clauseId: string, folderId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}/folder/${folderId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getErrorDescriptions(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/error-description`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
