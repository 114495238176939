import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AnnotationTypesService {

    private basePath = '/masterdata-management/annotation-type';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public getAll(): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
