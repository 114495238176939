import { Injectable } from "@angular/core";
import { ITransformCleanHtmlService } from "./transform-clean-html.service.interface";
import { HtmlService } from "../html.service";

@Injectable({
    providedIn: "root"
})
export class TransformCleanHtmlService extends HtmlService
    implements ITransformCleanHtmlService {
    public initializeHtmlEditor(): string {
        return `<div class="FO" id="tBody">
        <div id="cCn">
        <p></p>
        </div>
        </div>`;
    }

    public addFormatRegulationsTab(html: string): string {
        return html.includes('id="tBody"')
            ? html
            : `<div id="tBody">${html}</div>`;
    }

    public addIconsFormatRegulationsTab(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const daElementsChilds = doc.querySelectorAll(".da > a.link");

        for (var i = 0; i < daElementsChilds.length; ++i) {
            daElementsChilds[i].classList.add("iLE");
        }
        const serializer = new XMLSerializer();
        const serializedHtml = serializer.serializeToString(doc);

        return serializedHtml.includes('id="tBody"')
            ? html
            : `<div id="tBody">${serializedHtml}</div>`;
    }

    public areTrackChangesPending(domNode: Document): boolean {
        const arrayTrackChanges = domNode.querySelectorAll(
            'span[class^="ice-cts"],span[class*="ice-cts"]'
        );
        return arrayTrackChanges.length > 0;
    }

    public changeInputCurrentValueToValue(document: Document) {
        this.changeAllInputs(document);
        this.changeAllSelects(document);
    }

    public moveValuesTextService(
        document: Document,
        replaceFilledInput: boolean
    ): string {
        let domNode: HTMLElement = document.getElementById(
            "currentViewer"
        ) as HTMLElement;

        if (!domNode) {
            domNode = document.querySelector("html");
        }

        this.cleanInteractableAttributes(domNode);
        this.cleanTextInputs(document, domNode, replaceFilledInput);
        this.cleanSelectionInputs(document, domNode, replaceFilledInput);
        this.consolidateCheckInCheckBox(domNode);

        return domNode.innerHTML;
    }

    public uncheckedInputRadioService(document: Document): string {
        let domNode: HTMLElement = document.getElementById(
            "currentViewer"
        ) as HTMLElement;

        if (!domNode) {
            domNode = document.querySelector("html");
        }

        this.consolidateSelectedRadioButtons(domNode);
        this.cleanRadioSelectOptions(domNode);
        this.cleanEmptyStyleAttribute(domNode);

        return domNode.innerHTML;
    }

    public transformHtmlFillInputsToTextService(
        document: Document,
        replaceFilledInput: boolean,
        cleanHeader: boolean
    ): string {
        let domNode: HTMLElement = document.getElementById(
            "currentViewer"
        ) as HTMLElement;

        if (!domNode) {
            domNode = document.querySelector("html");
        }

        const replaceAllCheck = false;
        const deleteEventIfNotSelected = false;

        if (cleanHeader) {
            this.cleanHeaderNode(domNode);
        }

        this.cleanTextInputs(document, domNode, replaceFilledInput);
        this.cleanSelectionInputs(document, domNode, replaceFilledInput);
        this.cleanCheckBox(domNode, replaceAllCheck);
        this.cleanRadioButtons(domNode, deleteEventIfNotSelected);
        this.cleanRadioButtonsByEditor(domNode);
        this.cleanPlusMinus(domNode);
        this.tranformImage(domNode);

        return domNode.innerHTML;
    }

    public cleanHeader(htmlText: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(htmlText, "text/html");
        const htmlElement = document.querySelector("html");
        this.cleanTitleNode(htmlElement);
        this.cleanHeaderNode(htmlElement);
        this.replaceCssRoutes(htmlElement);
        this.replaceJsRoutes(htmlElement);

        return htmlElement.outerHTML;
    }

    public cleanJSScripts(htmlText: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(htmlText, "text/html");
        const htmlElement = document.querySelector("html");
        const jsScripts = htmlElement.querySelectorAll(
            'script[type="text/javascript"]'
        );

        jsScripts.forEach(n => this.deleteNode(n));

        return htmlElement.outerHTML;
    }

    public helpLabelAsPlaceholder(htmlText: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(htmlText, "text/html");
        const htmlElement = document.querySelector("html");
        this.insertPlaceHolder(htmlElement);

        return htmlElement.outerHTML;
    }

    public cleanLinkFootNotes(htmlElement: Element): Element {
        const personalizeAnchors = htmlElement.querySelectorAll('a[href^="#"]');
        personalizeAnchors.forEach((anchor: Element) => {
            this.setFootNote(anchor, htmlElement);
        });

        return htmlElement;
    }

    public extractCO(htmlText: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(htmlText, "text/html");
        const coElement = document.querySelector('div[class="ccn"]');
        if (!coElement) {
            return "";
        }

        return coElement.innerHTML;
    }

    public cleanCheckBox(domNode: HTMLElement, onlyChecked: boolean) {
        const allCheckBox = domNode.querySelectorAll("input[type=checkbox]");
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allCheckBox.length; i++) {
            const checkbox = allCheckBox[i] as HTMLInputElement;
            const checkboxParent = checkbox.parentElement;
            const todoCheckParentClassLabel = 'todo-list__label';
            const isCheckBoxOfTodoList =checkboxParent && checkboxParent.classList.contains(todoCheckParentClassLabel);
            if(isCheckBoxOfTodoList) {
                    continue;
            }
            const parent = !!(checkbox.closest(".opt") as HTMLElement)
                ? (checkbox.closest(".opt") as HTMLElement)
                : checkbox.parentElement;


            let checkboxText = parent.querySelector(":scope > .checkbox-text");
            let isCheckboxText = false;
            if (!checkboxText) {
                //TODO Mirar cuando pongamos la feature checkbox text en el editor
                isCheckboxText = parent.id !== checkbox.name;
                checkboxText = parent.querySelector(".checkbox-text");
            }

            let description = parent.querySelector(":scope > .hDesc");
            if (!description) {
                description = parent.querySelector(".hDesc");
            }

            parent.classList.remove("sel");
            if (parent.tagName.toLocaleLowerCase() === "span") {
                if (description) {
                    this.deleteNode(description);
                }
                if (checkbox.checked) {
                    this.deleteNode(checkbox);
                } else if (!onlyChecked) {
                    this.deleteNode(parent);
                }
                continue;
            }

            // All divs start wit idGroup_ where idGroup XXXX
            if (checkbox.checked) {
                if (checkboxText !== null) {
                    this.deleteNode(checkboxText);
                }

                if (description !== null) {
                    this.deleteNode(description);
                }

                this.moveChildsOfCheckBoxToParent(parent, checkbox);
                const isCheckboxTemplateLld = !(
                    checkboxText === null &&
                    description === null &&
                    checkbox.getAttribute("id") === null
                );

                if (isCheckboxTemplateLld) {
                    this.deleteNode(parent);
                } else {
                    this.deleteNode(checkbox);
                }
            } else if (!onlyChecked) {
                if (checkboxText !== null) {
                    this.deleteNode(checkboxText);
                }

                if(isCheckboxText) {
                    this.deleteNode(checkbox);
                }

                if (description !== null) {
                    this.deleteNode(description);
                }

                if(!isCheckboxText) {
                    this.deleteNode(parent);
                }
            }
        }
    }

    public cleanAllTextInputs(document: Document, domNode: HTMLElement): void {
        this.cleanAllInputsType(document, domNode, "text");
    }

    public cleanAllMailInputs(document: Document, domNode: HTMLElement): void {
        this.cleanAllInputsType(document, domNode, "email");
    }

    public cleanAllNumberInputs(
        document: Document,
        domNode: HTMLElement
    ): void {
        this.cleanAllInputsType(document, domNode, "number");
    }

    public cleanAllDateInputs(document: Document, domNode: HTMLElement): void {
        this.cleanAllInputsType(document, domNode, "date");
    }

    public cleanAllInputs(document: Document, domNode: HTMLElement): void {
        this.cleanAllTextInputs(document, domNode);
        this.cleanAllMailInputs(document, domNode);
        this.cleanAllNumberInputs(document, domNode);
        this.cleanAllDateInputs(document, domNode);
    }

    public cleanAllSelectionInputs(
        document: Document,
        domNode: HTMLElement
    ): void {
        const select = domNode.querySelectorAll("select");
        select.forEach(element => {
            this.replaceSelectToSpan(document, element as HTMLElement);
        });
    }

    public uncheckedRadiosWhenParentHasUncheckedRadios(
        domNode: HTMLElement
    ): void {
        const allRadioGroupsDivs = domNode.getElementsByClassName("rGroup");
        const radiosToUnchecked: Element[] = [];

        Array.from(allRadioGroupsDivs).forEach(radioGroup => {
            let currentNode: Element;
            currentNode = radioGroup;

            while (
                currentNode.parentNode &&
                (currentNode.parentNode as Element).tagName !== "BODY"
            ) {
                if ((currentNode.parentNode as Element).className === "rBox") {
                    radiosToUnchecked.push(radioGroup);
                    return;
                }
                currentNode = currentNode.parentNode as Element;
            }
        });

        radiosToUnchecked.forEach(radioToUnchecked => {
            const radio = radioToUnchecked.childNodes;

            radio.forEach(radioCheck => {
                (radioCheck as Element).className = "rBox";
            });
        });
    }

    public cleanRadioButtons(
        domNode: HTMLElement,
        deleteEventIfNotSelected: boolean
    ) {
        this.uncheckedRadiosWhenParentHasUncheckedRadios(domNode);

        const allRadioGroupsDivs = domNode.getElementsByClassName("rGroup");
        const toDeleteRadioButtons = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allRadioGroupsDivs.length; i++) {
            const radioGroup = allRadioGroupsDivs[i] as HTMLElement;
            if (!radioGroup.querySelector("input[type=radio]")) {
                continue;
            }

            const idGroup = (radioGroup.querySelector(
                "input[type=radio]"
            ) as HTMLInputElement).name;

            if (deleteEventIfNotSelected) {
                toDeleteRadioButtons.push({ radioGroup, idGroup });
                continue;
            }

            if (!this.radioHasOptionSelected(radioGroup, idGroup)) {
                continue;
            }

            toDeleteRadioButtons.push({ radioGroup, idGroup });
        }

        for (const radioButton of toDeleteRadioButtons) {
            this.cleanRadioOptionSelected(
                radioButton.radioGroup,
                radioButton.idGroup
            );
        }
    }

    public cleanRadioButtonsByEditor(domNode: HTMLElement) {
        const radios = domNode.querySelectorAll("input[type=radio]");

        if (radios.length <= 0) {
            return;
        }

        radios.forEach(radio => this.cleanRadioButtonByEditor(radio));
    }

    public checkCleanAndIsSameText(
        originalText: string,
        checkedText: string
    ): boolean {
        const originalAfterClean = this.clean(originalText);
        const checkedAfterClean = this.clean(checkedText);

        return (
            this.htmlDecode(originalAfterClean) ===
            this.htmlDecode(checkedAfterClean)
        );
    }

    htmlDecode(input: string) {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.querySelector("body").innerHTML;
    }

    clean(text: string): string {
        const DO_NOT_REPLACE_IN_RAW = false;
        const domParser = new DOMParser();
        const theDocument = domParser.parseFromString(text, "text/html");
        this.moveValuesTextService(theDocument, DO_NOT_REPLACE_IN_RAW);

        return theDocument.querySelector("body").innerHTML;
    }

    public cleanPlusMinus(domNode: HTMLElement) {
        const allPlusMinusSpans = Array.from(domNode.getElementsByClassName('plusminusgroup-text')) as HTMLElement[];

        allPlusMinusSpans.forEach(span => {
            const divParent = span.parentElement;

            if (divParent) {
                this.moveChildsOfPlusMinusToParent(divParent);
                this.deleteDivsWhichStartWitParentId(divParent);
                this.deleteNode(divParent);
            }
        });
    }

    public removeHelpNotes(domNode: HTMLElement): void {
        const helpNoteClass = 'nh';
        const helpNoteLiteralClass = 'nCl';
        const allHelpNotes = domNode.getElementsByClassName(helpNoteClass);
        // From last to first because the modification with delete the length
        // tslint:disable-next-line: prefer-for-of
        for (let i = allHelpNotes.length - 1; i >= 0; i--) {
            const helpNote = allHelpNotes[i];
            this.deleteNode(helpNote);
        }

        const allLiteralHelpNotes = domNode.getElementsByClassName(
            helpNoteLiteralClass
        );

        // From last to first because the modification with delete the length
        // tslint:disable-next-line: prefer-for-of
        for (let i = allLiteralHelpNotes.length - 1; i >= 0; i--) {
            const literal = allLiteralHelpNotes[i];
            this.deleteNode(literal);
        }
    }

    public cleanHelpNotes(htmlText: string): string {
        const domParser = new DOMParser();
        const domNode = domParser.parseFromString(htmlText, 'text/html');
        const htmlElement = domNode.querySelector('html');
        this.cleanHelpNotesAnchors(htmlElement);
        this.cleanHelpNotesText(htmlElement);
        return htmlElement.outerHTML;
    }

    private cleanHelpNotesAnchors(htmlElement: HTMLHtmlElement): void {
        const helpNoteClass = 'nh';
        const allHelpNotes = htmlElement?.getElementsByClassName(helpNoteClass);
        for (let i = allHelpNotes.length - 1; i >= 0; i--) {
            const helpNote = allHelpNotes[i];
            this.deleteNode(helpNote);
        }
    }

    private cleanHelpNotesText(htmlElement: HTMLHtmlElement): void {
        const helpNoteLiteralClass = 'nCl';
        const allHelpNotes = htmlElement?.getElementsByClassName(helpNoteLiteralClass);
        for (let i = allHelpNotes.length - 1; i >= 0; i--) {
            const helpNote = allHelpNotes[i];
            helpNote.innerHTML = helpNote.textContent;
        }
    }

    public noMandatoryElementsSelected(htmlText: string): boolean {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(htmlText, "text/html");
        const htmlElement = document.querySelector("html");
        const allCheckBox = htmlElement.querySelectorAll(
            "input[type=checkbox]"
        );
        if (allCheckBox.length < 1) {
            return false;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allCheckBox.length; i++) {
            const checkbox = allCheckBox[i] as HTMLInputElement;

            if (checkbox.checked) {
                return false;
            }
        }

        return true;
    }

    public tranformImage(domNode: HTMLElement) {
        const media = "https://medias.externalnaw.es/medias/ES/img/";
        // im0001864131
        let anchorImgage = domNode.querySelectorAll('img[onclick^="vImg"]');
        anchorImgage.forEach(imageIdd => {
            const idValues = imageIdd.getAttribute("id");
            const imageSrc =
                idValues.substring(8, 9) +
                "/" +
                idValues.substring(9, 10) +
                "/" +
                idValues.substring(10, 11) +
                "/" +
                idValues.substring(11, 12) +
                "/";
            imageIdd.setAttribute("id", idValues);
            imageIdd.setAttribute("src", media + imageSrc + idValues + ".jpg");
        });
    }

    public consolidateSelectedRadioButtons(domNode: HTMLElement): void {
        const allRadioGroupsDivs = domNode.getElementsByClassName("rGroup");
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allRadioGroupsDivs.length; i++) {
            const radioGroup = allRadioGroupsDivs[i] as HTMLElement;
            if (!radioGroup.querySelector("input[type=radio]")) {
                continue;
            }

            const idGroup = (radioGroup.querySelector(
                "input[type=radio]"
            ) as HTMLInputElement).name;
            const allRadioButtons = radioGroup.querySelectorAll(
                "input[name=" + idGroup + "]"
            );
            const allRBox = radioGroup.getElementsByClassName("rBox sel");

            let inputElement: HTMLInputElement;
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < allRadioButtons.length; j++) {
                inputElement = allRadioButtons[j] as HTMLInputElement;

                if (inputElement === undefined) {
                    continue;
                }

                (allRadioButtons[j] as HTMLInputElement).removeAttribute(
                    "checked"
                );
            }

            let rBoxElement: HTMLElement;

            // tslint:disable-next-line: prefer-for-of
            for (let k = 0; k < allRBox.length; k++) {
                rBoxElement = allRBox[k] as HTMLElement;
                rBoxElement.setAttribute("class", "rBox");
            }
        }
    }

    public cleanRadioSelectOptions(domNode: HTMLElement): void {
        const allRSelectSpans = domNode.getElementsByClassName("rSelect");
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allRSelectSpans.length; i++) {
            const rSelect = allRSelectSpans[i] as HTMLElement;
            if (!rSelect.querySelector("input[type=radio]")) {
                continue;
            }

            const allInputs = rSelect.querySelectorAll("input[type=radio]");
            const allOrSelect = rSelect.getElementsByClassName("orSelect sel");

            let inputElement: HTMLInputElement;

            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < allInputs.length; j++) {
                inputElement = allInputs[j] as HTMLInputElement;

                if (inputElement === undefined) {
                    continue;
                }

                inputElement.getAttribute("checked");
                inputElement.removeAttribute("checked");
            }

            let orSelectElement: HTMLElement;

            // tslint:disable-next-line: prefer-for-of
            for (let k = 0; k < allOrSelect.length; k++) {
                orSelectElement = allOrSelect[k] as HTMLElement;
                orSelectElement.setAttribute("class", "orSelect");
            }
        }
    }

    public changeAllInputs(document: Document): void {
        const allInputs = document.querySelectorAll(
            "input[type=text],input[type=date],input[type=number]"
        );
        allInputs.forEach((element: HTMLInputElement) => {
            const transform = element.getAttribute("data-transform");

            switch (transform) {
                case $localize`:@@EtiquetaMayusculas:Mayúsculas`:
                    element.value = (element as HTMLInputElement).value.toUpperCase();
                    break;

                case $localize`:@@EtiquetaMinusculas:Minúsculas`:
                    element.value = (element as HTMLInputElement).value.toLowerCase();
                    break;

                case $localize`:@@EtiquetaCapitales:Capitales`:
                    element.value = (element as HTMLInputElement).value
                        .toLowerCase()
                        .replace(/(^|\s)([a-zA-ZÀ-ÿ])/g, char =>
                            char.toUpperCase()
                        );
                    break;

                default:
                    element.value = (element as HTMLInputElement).value;
                    break;
            }
        });
    }

    public changeAllSelects(document: Document): void {
        const allSelects = document.getElementsByTagName("select");
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allSelects.length; i++) {
            const select = allSelects[i];
            const optionSelectedChild = select.options[select.selectedIndex];
            if (
                optionSelectedChild !== null &&
                optionSelectedChild !== undefined
            ) {
                optionSelectedChild.setAttribute(
                    "value",
                    optionSelectedChild.text
                );
            }
        }
    }

    public cleanHeaderNode(domNode: HTMLElement): void {
        const selectNode = domNode.querySelector("#dHead");
        if (selectNode === null || selectNode === undefined) {
            return;
        }
        this.deleteNode(selectNode);
    }

    public cleanTitleNode(domNode: HTMLElement): void {
        const selectNodes = domNode.querySelectorAll("title");
        if (selectNodes === null || selectNodes === undefined) {
            return;
        }

        selectNodes.forEach(selectedNode => {
            this.deleteNode(selectedNode);
        });
    }

    public insertPlaceHolder(domNode: HTMLElement): void {
        const selectNodes = domNode.querySelectorAll("a.nh");
        if (selectNodes === null || selectNodes === undefined) {
            return;
        }

        selectNodes.forEach(selectedNode => {
            const input = selectedNode.previousElementSibling;
            if (
                !(input === null || input === undefined) &&
                input.tagName.toLowerCase() === "input"
            ) {
                (input as HTMLInputElement).placeholder =
                    selectedNode.nextElementSibling.textContent;
            }
        });
    }

    public replaceCssRoutes(domNode: HTMLElement): void {
        const selectNodeDocLocalContractBoxCss = domNode.querySelector(
            'link[href="../../../../css/docs.css"]'
        );
        if (
            selectNodeDocLocalContractBoxCss === null ||
            selectNodeDocLocalContractBoxCss === undefined
        ) {
            return;
        }
        this.deleteNode(selectNodeDocLocalContractBoxCss);

        const selectNodeDocCss = domNode.querySelector(
            'link[href="../css/contractBox/Docs.css"]'
        );
        if (selectNodeDocCss === null || selectNodeDocCss === undefined) {
            return;
        }
        this.deleteNode(selectNodeDocCss);

        const selectNodeDocContractBoxCss = domNode.querySelector(
            'link[href="../css/contractBox.css"]'
        );
        if (
            selectNodeDocContractBoxCss === null ||
            selectNodeDocContractBoxCss === undefined
        ) {
            return;
        }
        this.deleteNode(selectNodeDocContractBoxCss);
    }

    public replaceJsRoutes(domNode: HTMLElement): void {
        const selectNodeDocJs = domNode.querySelector(
            'script[src="../../../../js/docs.js"]'
        );
        if (selectNodeDocJs === null || selectNodeDocJs === undefined) {
            return;
        }
        this.deleteNode(selectNodeDocJs);
    }

    public replaceCssSmartFormRoutes(domNode: HTMLElement): void {
        const selectNodeDocCss = domNode.querySelector(
            'link[href="../css/contractBox/Docs.css"]'
        );
        if (selectNodeDocCss === null || selectNodeDocCss === undefined) {
            return;
        }
        this.deleteNode(selectNodeDocCss);

        const selectNodeDocContractBoxCss = domNode.querySelector(
            'link[href="../css/contractBox.css"]'
        );
        if (
            selectNodeDocContractBoxCss === null ||
            selectNodeDocContractBoxCss === undefined
        ) {
            return;
        }

        this.deleteNode(selectNodeDocContractBoxCss);

        const selectNodeDocLocalContractBoxCss = domNode.querySelector(
            'link[href="../../../../css/docs.css"]'
        );
        if (
            selectNodeDocLocalContractBoxCss === null ||
            selectNodeDocLocalContractBoxCss === undefined
        ) {
            return;
        }

        this.deleteNode(selectNodeDocLocalContractBoxCss);
    }

    public replaceJsSmartFormRoutes(domNode: HTMLElement): void {
        const selectNodeDocJs = domNode.querySelector(
            'script[src="../../../../js/docs_SFFE.js"]'
        );
        if (selectNodeDocJs === null || selectNodeDocJs === undefined) {
            return;
        }

        this.deleteNode(selectNodeDocJs);
    }

    public consolidateCheckInCheckBox(domNode: HTMLElement): void {
        const allCheckBox = domNode.querySelectorAll("input[type=checkbox]");
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allCheckBox.length; i++) {
            const checkbox = allCheckBox[i] as HTMLInputElement;

            if (checkbox.checked) {
                checkbox.setAttribute("checked", "true");
            } else {
                checkbox.removeAttribute("checked");
            }
        }
    }

    public moveChildsOfCheckBoxToParent(
        parent: HTMLElement,
        checkbox: Element
    ): void {
        while (parent.children.length > 0) {
            const elementparagraph = parent.children[0];
            const elementInput = parent.querySelector("input[type=checkbox]");
            if (elementInput === checkbox) {
                this.deleteNode(elementparagraph);
                continue;
            }

            parent.parentNode.insertBefore(elementparagraph, parent);
        }
    }

    public mandatoryRadioField(idSelect: Element, radioToCheck: Element[], titleValue: string): void {
        const idGroup = idSelect.id.substring(0, idSelect.id.indexOf("-"));

        if (
            this.radioHasOptionSelected(
                idSelect.parentElement.parentElement,
                idGroup
            ) ||
            radioToCheck.find(element => element.id === idSelect.id) ===
                undefined
        ) {
            const mandatoryField = idSelect.parentElement.parentElement.querySelector(
                "#" + idGroup + "_m"
            );
            if (mandatoryField !== null) {
                mandatoryField.remove();
            }
        } else if (
            idSelect.parentElement.parentElement.querySelector(
                "#" + idGroup + "_m"
            ) === null
        ) {
            const isNotInline = false;
            const mandatoryField = this.getMandatoryFieldLiteral(
                idGroup,
                titleValue,
                isNotInline
            );

            idSelect.parentElement.parentElement.insertAdjacentElement(
                "afterbegin",
                mandatoryField
            );
        }
    }

    public mandatorySelectField(
        idSelect: HTMLSelectElement,
        selectToCheck: HTMLElement[],
        titleValue: string
    ): void {
        let mandatoryField = idSelect.parentNode.querySelector(
            "#" + idSelect.id + "_m"
        );
        if (
            idSelect.options[idSelect.selectedIndex].text === "" &&
            selectToCheck.find(element => element.id === idSelect.id) !==
                undefined
        ) {
            if (mandatoryField !== null) {
                return;
            }
            const isInline = true;
            mandatoryField = this.getMandatoryFieldLiteral(
                idSelect.id,
                titleValue,
                isInline
            );
            idSelect.insertAdjacentElement("afterend", mandatoryField);
        } else if (mandatoryField) {
            mandatoryField.remove();
        }
    }

    public getMandatoryFieldLiteral(
        id: string,
        titleValue: string,
        isInline: boolean
    ): Element {
        const mandatoryField = document.createElement("span");
        if (isInline) {
            mandatoryField.className =
                "validator-text--error--mandatory-inline validator-text validator-text--error ng-star-inserted";
        } else {
            mandatoryField.className =
                "validator-text--error--mandatory-option validator-text validator-text--error ng-star-inserted";
        }
        mandatoryField.id = id + "_m";
        mandatoryField.textContent = "*";
        mandatoryField.setAttribute("data-toggle", "tooltip");
        mandatoryField.setAttribute("title", titleValue);

        return mandatoryField;
    }

    public cleanRadioOptionSelected(
        radioGroupElement: HTMLElement,
        idGroup: string
    ): void {
        const allRadioButtons = radioGroupElement.querySelectorAll(
            "input[name=" + idGroup + "]"
        );
        let checkedRadioButton;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < allRadioButtons.length; i++) {
            const element = allRadioButtons[i];
            if ((element as HTMLInputElement).checked) {
                checkedRadioButton = element;
                break;
            }
        }

        const parentOfGroupNode = radioGroupElement.parentNode;
        let parentRadioButton = radioGroupElement.querySelector(
            ":scope .rBox.sel"
        );
        if (!parentRadioButton) {
            parentRadioButton = radioGroupElement.querySelector(".rBox.sel");
        }

        if (parentRadioButton) {
            let descriptionAlsoToDelete = parentRadioButton.querySelector(
                ":scope .rhDesc"
            );

            if (!descriptionAlsoToDelete) {
                descriptionAlsoToDelete = parentRadioButton.querySelector(
                    ".rhDesc"
                );
            }

            this.deleteNode(checkedRadioButton);
            if (descriptionAlsoToDelete) {
                this.deleteNode(descriptionAlsoToDelete);
            }

            // moving childs with content selected by radio button.
            const parentOfGroupNodeRadio = radioGroupElement.parentNode;

            while (parentRadioButton.children.length > 0) {
                parentOfGroupNodeRadio.insertBefore(
                    parentRadioButton.children[0],
                    radioGroupElement
                );
            }
        }

        // deleting groupbox node and AN sibling
        let groupBoxAN = parentOfGroupNode.querySelector(":scope .AN");
        if (!groupBoxAN) {
            groupBoxAN = parentOfGroupNode.querySelector(".AN");
        }
        if (groupBoxAN !== null) {
            this.deleteNode(groupBoxAN);
        }
        if (radioGroupElement !== null) {
            this.deleteNode(radioGroupElement);
        }
    }

    public deleteNode(node: Element): void {
        node.parentNode?.removeChild(node);
    }

    public deleteNodeParagraph(node: Element): void {
        node.parentNode?.removeChild(node);
    }

    public cleanTextInputs(
        document: Document,
        domNode: HTMLElement,
        replaceFilledInput: boolean
    ): void {
        const arrayInputs = domNode.querySelectorAll("input[type=text]");
        arrayInputs.forEach(element => {
            if (replaceFilledInput !== false) {
                if ((element as HTMLInputElement).value.trim() === "") {
                    return;
                }

                this.replaceElementToSpan(document, element as HTMLElement);
            } else {
                this.moveContentToValue(element as HTMLElement);
            }
        });
    }

    public moveContentToValue(element: HTMLElement): void {
        element.setAttribute("value", (element as HTMLInputElement).value);
    }

    public replaceElementToSpan(
        document: Document,
        element: HTMLElement
    ): void {
        const parent = element.parentNode as HTMLElement;
        const span = document.createElement("span");

        const textInSpan = (element as HTMLInputElement).value;

        span.textContent = textInSpan;

        this.setStyles(span, element);

        while (element.childNodes.length > 0) {
            span.appendChild(element.childNodes[0]);
            this.deleteNode(element.childNodes[0] as HTMLElement);
        }
        parent.replaceChild(span, element);
    }

    public replaceElementToSpanAndFiveDotsIfEmpty(
        document: Document,
        element: HTMLElement
    ): void {
        const parent = element.parentNode as HTMLElement;
        const span = document.createElement("span");
        const emptyReplace = ".....";

        this.setStyles(span, element);

        let textInSpan = (element as HTMLInputElement).value;

        if (textInSpan.trim() === "") {
            textInSpan = emptyReplace;
        }

        span.textContent = textInSpan;

        while (element.childNodes.length > 0) {
            span.appendChild(element.childNodes[0]);
            this.deleteNode(element.childNodes[0] as HTMLElement);
        }
        parent.replaceChild(span, element);
    }

    public cleanSelectionInputs(
        document: Document,
        domNode: HTMLElement,
        replaceFilledInput: boolean
    ): void {
        const select = domNode.querySelectorAll("select");
        select.forEach(element => {
            if (replaceFilledInput !== false) {
                if (element.value.trim() === "") {
                    return;
                }
                this.replaceSelectToSpan(document, element as HTMLElement);
            } else {
                this.moveContentToValue(element as HTMLElement);
            }
        });
    }

    public addMandatorySelectInputs(
        document: Document,
        selectToCheck: HTMLSelectElement[],
        titleValue: string
    ): void {
        let currentViewerNode: HTMLElement = document.getElementById(
            "currentViewer"
        ) as HTMLElement;

        if (!currentViewerNode) {
            currentViewerNode = document.querySelector("html");
        }

        const select = currentViewerNode.querySelectorAll("select");
        select.forEach(element => {
            this.mandatorySelectField(element, selectToCheck, titleValue);
        });
    }

    public addMandatoryRadioInputs(
        document: Document,
        radioToCheck: Element[],
        titleValue: string
    ): void {
        let currentViewerNode: HTMLElement = document.getElementById(
            "currentViewer"
        ) as HTMLElement;

        if (!currentViewerNode) {
            currentViewerNode = document.querySelector("html");
        }

        const select = currentViewerNode.querySelectorAll("input[type=radio]");
        select.forEach(element => {
            this.mandatoryRadioField(element, radioToCheck, titleValue);
        });
    }

    public replaceSelectToSpan(document: Document, select: HTMLElement): void {
        const parent = select.parentNode as HTMLElement;
        const span = document.createElement("span");
        const emptyReplace = ".....";
        span.textContent = (select as HTMLSelectElement).options[
            (select as HTMLSelectElement).selectedIndex
        ]?.text;

        if (
            span.textContent.trim() === undefined ||
            span.textContent.trim() === ""
        ) {
            span.textContent = emptyReplace;
        }
        parent.replaceChild(span, select);
    }

    public transformLinks(htmlElement: Element, functionToClick: (anchorElement: Element) => void) {
        const linkElements = htmlElement.querySelectorAll("a.link");
        linkElements.forEach(link => {
            link.addEventListener(
                "mousedown",
                eventMouse => {
                    eventMouse.stopPropagation();
                },
                true
            );
            link.addEventListener("click", eventMouse => {
                functionToClick(link);
                eventMouse.stopPropagation();
            });
            link.addEventListener("mouseup", eventMouse => {
                eventMouse.stopPropagation();
            });
        });
    }

    public rBoxHideContent(htmlElement: Element): void {
        const radioElements = htmlElement.querySelectorAll('[class="rBox"]');
        radioElements.forEach(
            radio => (radio.className = radio.className + " wHelp")
        );
    }

    private cleanRadioButtonByEditor(radio): void {
        if ((radio as HTMLInputElement).checked) {
            this.deleteNode(radio);
        } else {
            const regex = new RegExp(
                /(?<textToRemain>[\w\s\\]*?)(\<input)+[\w\s\"\=]+(type="radio")+[\w\s\"\=\&\;]*(>)[\w\s\"\=\&\;]*/
            );
            const result = regex.exec(radio.parentElement.innerHTML);

            if (result) {
                const remainedText = result.groups?.textToRemain;
                radio.parentElement.innerHTML = radio.parentElement.innerHTML.replace(
                    result[0],
                    remainedText
                );
            }
        }
    }

    private setFootNote(anchor: Element, rootElement: Element): void {
        const noteFoodClass = "nf";
        const hrefAttribute = "href";

        const targetId = anchor.attributes[hrefAttribute].value;

        anchor.removeAttribute(hrefAttribute);
        anchor.setAttribute("routerLink", "[]");
        if (!anchor.classList.contains(noteFoodClass)) {
            anchor.classList.add(noteFoodClass);
        }
        anchor.addEventListener("click", () => {
            const targetElement = rootElement.querySelector(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ block: "center" });
            }
        });
    }

    private cleanAllInputsType(
        document: Document,
        domNode: HTMLElement,
        inputType: string
    ): void {
        const arrayInputs = domNode.querySelectorAll(
            "input[type=" + inputType + "]"
        );
        arrayInputs.forEach(element => {
            this.replaceElementToSpanAndFiveDotsIfEmpty(
                document,
                element as HTMLElement
            );
        });
    }

    private cleanInteractableAttributes(domNode: HTMLElement): void {
        const allElementsReadOnly = domNode.querySelectorAll("[readonly]");
        allElementsReadOnly.forEach((element: HTMLElement) => {
            element.removeAttribute("readonly");
        });
        const allOptionDisabled = document.querySelectorAll("option[disabled]");
        allOptionDisabled.forEach((element: HTMLElement) => {
            element.removeAttribute("disabled");
        });
    }

    private cleanEmptyStyleAttribute(domNode: HTMLElement): void {
        const allEmptyStyleAttributeElements = domNode.querySelectorAll(
            '[style=";"]'
        );
        allEmptyStyleAttributeElements.forEach((element: HTMLElement) => {
            element.removeAttribute("style");
        });
    }

    private setStyles(span: HTMLSpanElement, element: HTMLElement): void {
        const styles = this.getStyle(element);
        if (!!styles) {
            span.setAttribute("style", styles);
        }
    }

    private getStyle(element: HTMLElement): string {
        if (!!element.previousElementSibling && element.previousElementSibling?.getAttribute("style")) {
            return element.previousElementSibling?.getAttribute("style");
        }

        if (!!element.nextElementSibling && element.nextElementSibling?.getAttribute("style")) {
            return element.nextElementSibling?.getAttribute("style");
        }
        return "";
    }

    private moveChildsOfPlusMinusToParent(divParent: HTMLElement): void {
        const idGroup = divParent.id;
        const allChildsDivWhichStartWithSameID = divParent.querySelectorAll('div[id^=' + idGroup + '_]');

        for (let i = 0; i < allChildsDivWhichStartWithSameID.length; i++) {
            const divWithId = allChildsDivWhichStartWithSameID[i];
            if (divWithId.id === idGroup + '_0') {
                continue;
            }

            const childrens = divWithId.children;
            while (childrens.length > 0) {
                divParent.parentElement.insertBefore(childrens[0], divParent);
            }
            this.deleteNode(divWithId);
        }
    }

    private deleteDivsWhichStartWitParentId(divParent: HTMLElement): void {
        const idGroup = divParent.id;
        const allDivWhichStartWithSameID = divParent.querySelectorAll('div[id^=' + idGroup + '_]');

        for (let j = 0; j < allDivWhichStartWithSameID.length; j++) {
            this.deleteNode(allDivWhichStartWithSameID[j]);
        }
    }
}
