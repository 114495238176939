import { Injectable } from '@angular/core';
import { TemplateDTO, UserDTO, UserClausePermissionsDto, UserPermissionsOnDocumentDTO, WarningChangesDTO } from 'src/app/api/model/models';
import { ITemplateListService } from './template-list.service.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplateListService extends ITemplateListService {

    constructor() {
        super();
    }

    public getAuthorSurnameToOrder(user: UserDTO): string {
        if (user === null) {
            return ''.toLocaleLowerCase();
        }
        return user.surname.trim().toLocaleLowerCase();
    }

    public getAlertsToOrder(template: TemplateDTO): number {
        let totalAlerts = 0;
        if (template?.warningChanges?.hasDocumentsPendingRevision && template?.userPermissions?.canSeeTemplateChanges) {
            totalAlerts++;
        }
        if (template?.warningChanges?.hasTemplatePendingRevision && template?.userPermissions?.canSeeTemplateChanges) {
            totalAlerts++;
        }
        if (template?.warningChanges?.hasTemplateLLDPendingRevision && template?.userPermissions?.canSeeTemplateLldChanges) {
            totalAlerts++;
        }
        if (template?.warningChanges?.hasClausesPendingRevision && template?.userPermissions?.canSeeClauseChanges) {
            totalAlerts++;
        }
        return totalAlerts;
    }

    public getActionToOrder(userPermissions: UserClausePermissionsDto): number {
        let actionsValue = 0;

        if (userPermissions === null) {
            return actionsValue;
        }
        if (userPermissions.canEdit) {
            actionsValue += 2;
        }
        if (userPermissions.canDelete) {
            actionsValue += 11;
        }
        return actionsValue;
    }
}
