import { Injectable } from '@angular/core';
import { Editor, Model, Position, Range, Element, ViewContainerElement, ViewDocumentFragment, ViewElement, ViewNode, Writer, Node, Text, ViewText, ViewDocumentSelection, TreeWalkerValue, Marker, DocumentSelection, TextProxy } from 'ckeditor5';
import type { PositionOptions } from 'ckeditor5/src/utils.js';
import { GlobalConstant } from '../custom-plugins/models/base/global-constant';
import { PluginElementType } from '../models/plugin-element-type.enum';
import { PluginGlobalConstant } from '../models/plugin-global-constant';

@Injectable({
    providedIn: 'root'
})
export class PluginUtilsService {

    public getSelectedElementWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
        const selection = editor.editing.view.document.selection;
        const selectedElement = this.getSelectedElement(selection);
        return !!selectedElement
            ? this.findElementAncestorWithClass((selectedElement as ViewNode), classNameToIdentify)
            : null;
    }

    public getSelectedContainerWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
        const selection = editor.editing.view.document.selection;
        const selectedElement = this.getSelectedElement(selection);
        return !!selectedElement
            ? this.findElementAncestorContainerWithClass((selectedElement as ViewNode), classNameToIdentify, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW)
            : null;
    }

    public getElementView(editor: Editor, id: string): ViewElement | undefined {
        const viewDocument = editor.editing.view.document;
        let foundElement: ViewElement | undefined;

        editor.editing.view.change(writer => {
            const range = writer.createRangeIn(viewDocument.getRoot()!);

            for (const value of range.getWalker()) {
                if (value.item.is(GlobalConstant.ELEMENT) && value.item.hasAttribute(GlobalConstant.ATTRIBUTE_ID) && value.item.getAttribute(GlobalConstant.ATTRIBUTE_ID) === id) {
                    foundElement = value.item as ViewElement;
                    break;
                }
            }
        });

        return foundElement;
    }

    public removeMarkersInElement(writer: Writer, element: Element): void {
        const markersInRange = this.getMarkersInElement(writer, element);
        for (const marker of markersInRange) {
            writer.removeMarker(marker.name);
        }
    }

    public getMarkersInElement(writer: Writer, element: Element): Iterable<Marker> {
        const range = writer.createRangeIn(element!);
        return writer.model.markers.getMarkersIntersectingRange(range);
    }

    public getElementModelWithId(editor: Editor, id: string): TreeWalkerValue | null {
        const modelView = editor.model;
        let model: TreeWalkerValue | null = null;
        modelView.change((writer: Writer) => {
            model = this.getWalkerByWithId(editor, writer, id);
        });
        return model;
    }

    public getWalkerByWithId(editor: Editor, writer: Writer, id: string): TreeWalkerValue | null {
        const range = writer.createRangeIn(editor.editing.model.document.getRoot()!);
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute(GlobalConstant.ATTRIBUTE_ID) && value.item.getAttribute(GlobalConstant.ATTRIBUTE_ID) === id) {
                return value;
            }
        }
        return null;
    }

    public findElementAncestorWithClass(position: ViewElement | ViewNode | null, classNameToIdentify: string): ViewContainerElement | null {
        return position?.getAncestors({ includeSelf: true }).reverse().find((ancestor): ancestor is ViewContainerElement => this.isElementWithClass(ancestor, classNameToIdentify)) || null;
    }

    public showFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        model.change((writer: Writer) => {
            const range = model.document.selection.getFirstRange()!;

            model.markers.has(markerName) ?
                writer.updateMarker(markerName, { range }) :
                this.createMarker(writer, model, range, markerName);
        });
    }

    public hideFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        if (model.markers.has(markerName)) {
            model.change((writer: Writer) => {
                writer.removeMarker(markerName);
            });
        }
    }

    public getBalloonPositionData(editor: Editor, classNameInElementToPosition: string, markerName: string): Partial<PositionOptions> {
        const target: PositionOptions['target'] = this.getTarget(editor, markerName, classNameInElementToPosition);
        return { target };
    }

    public getTarget(editor: Editor, markerName: string, classNameInElementToPosition: string) {
        const view = editor.editing.view;
        const model = editor.model;
        const viewDocument = view.document;
        let target: PositionOptions['target'];

        if (model.markers?.has(markerName) && !!editor.editing.mapper.markerNameToElements(markerName)) {
            const markerViewElements = Array.from(editor.editing.mapper.markerNameToElements(markerName)!);
            const newRange = view.createRange(
                view.createPositionBefore(markerViewElements[0]),
                view.createPositionAfter(markerViewElements[markerViewElements.length - 1])
            );

            target = view.domConverter.viewRangeToDom(newRange);
        } else {
            target = () => {
                const targetElement = this.getSelectedElementWithClass(editor, classNameInElementToPosition);
                const targeto = targetElement ?
                    view.domConverter.mapViewToDom(targetElement)! :
                    view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange()!);
                return targeto;
            };
        }
        return target;
    }

    public generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public generateId(prefix: string): string {
        return `${prefix}${this.generateUUID()}`;
    }

    public getTextContent(child: Node): string {
        return child?.is(GlobalConstant.MODEL_TEXT) ? (child as Text).data : '';
    }

    public isViewElementInteractableInTargetEditor(element: ViewContainerElement, currentEditorContext: string, targetEditorContext: string): boolean {
        return !(element.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) === targetEditorContext && currentEditorContext !== targetEditorContext);
    }

    public isElementInteractableInTargetEditor(elementProperty: string, currentEditorContext: string, targetEditorContext: string): boolean {
        return !(elementProperty === targetEditorContext && currentEditorContext !== targetEditorContext);
    }

    public getViewTextContent(viewElement: ViewElement) {
        const firstChild = viewElement.getChild(0);
        const text = firstChild?.is('view:$text') ? (firstChild as ViewText).data : '';
        return text;
    }

    public getSelectedElement(selection: ViewDocumentSelection): ViewNode | ViewDocumentFragment | null {
        const selectedElement = selection.getSelectedElement() || selection.getFirstPosition()?.parent;
        if (!selectedElement || selectedElement?.is(GlobalConstant.DOCUMENT_FRAGMENT)) {
            return null;
        }
        return selectedElement;
    }

    private createMarker(writer: Writer, model: Model, range: Range, markerName: string) {
        const startPosition = this.getStartPosition(range, model);

        writer.addMarker(markerName, {
            usingOperation: false,
            affectsData: false,
            range: writer.createRange(startPosition, range.end)
        });
    }

    public areSameAttributes(newAttri: [string, unknown][], oldAttri: [string, unknown][]): boolean {
        if (newAttri.length !== oldAttri.length) {
            return false;
        }

        return newAttri.every(a => oldAttri[0][0] === newAttri[0][0] && oldAttri[0][1] === newAttri[0][1]);
    }

    public getStyleAttributesFromPreviousElement(previousElement: Element): [string, unknown][] {
        const elementPreviousWithAttributes  = this.getElementWithAttributesAppliedInPreviousElement(previousElement);
        if(!elementPreviousWithAttributes) {
            return [];
        }
        const attributeKeys = elementPreviousWithAttributes?.getAttributeKeys();
        const stylesAttributeKeys = PluginGlobalConstant.STYLE_ATTRIBUTES.map(sa => sa.model);

        const keyStyles = Array.from(attributeKeys).filter(ak => stylesAttributeKeys.includes(ak));
        const attributes = Array.from(elementPreviousWithAttributes.getAttributes()).filter(a => keyStyles.includes(a[0]));

        return attributes;
    }

    public findPreviousElement(selection: DocumentSelection): Element {
        const position = selection.getFirstPosition();
        let previousElement = position.nodeBefore;

        if (!this.isParagraphElement(previousElement as Element)) {
            previousElement = this.findElementAtPosition(position.parent as Element, position.offset);

            if (position.parent.childCount < 1) {
                previousElement = position.parent as Element;
            }
        }

        return previousElement as Element;
    }

    public findStyledElementBefore(element: Element, writer: Writer, elementType: PluginElementType): Element {
        const range = writer.createRangeOn(element);
        const position = range?.start;
        let previousElement = position?.nodeBefore;

        if (!this.isParagraphElement(previousElement as Element)) {
            return previousElement as Element;
        }

        if (elementType === PluginElementType.INLINE) {
            previousElement = this.findElementSiblingAtOffset(previousElement as Element, position.offset);
        } else {
            previousElement = this.findElementSibling(previousElement as Element);
        }

        return previousElement as Element;
    }

    public setAttributes(attributes: [string, unknown][], elementToSet: Element, writer: Writer) {
        for (const [key, value] of attributes) {
            if (key.startsWith(PluginGlobalConstant.START_WITH_SELECTION_IN_ATTRIBUTE)) {
                writer.setAttribute(
                    key.substring(PluginGlobalConstant.START_WITH_SELECTION_IN_ATTRIBUTE.length),
                    value,
                    elementToSet
                );
            } else {
                writer.setAttribute(key, value, elementToSet);
            }
        }
    }

    private findElementAtPosition(parent: Element, offset: number): Node {
        let charCount = 0;

        for (const child of parent.getChildren()) {
            charCount += this.getChildTextLength(child);

            if (charCount >= offset) {
                return child;
            }
        }

        return parent;
    }

    private isParagraphElement(node: Element | null): boolean {
        return !!node && node.is(GlobalConstant.ELEMENT, GlobalConstant.MODEL_PARAGRAPH);
    }

    private getChildTextLength(child: Node): number {
        if (child.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_SPAN)) {
            return this.getSpanTextLength(child);
        }

        if (child.is(GlobalConstant.MODEL_TEXT)) {
            return child.data.length;
        }

        return 0;
    }

    private getSpanTextLength(span: Element): number {
        let textLength = 0;

        for (const textNode of span.getChildren()) {
            if (textNode.is(GlobalConstant.MODEL_TEXT) || textNode.is(GlobalConstant.MODEL_TEXT_PROXY)) {
                textLength += (textNode as TextProxy).data.length;
            }
        }

        return textLength;
    }

    private containsTextNodes(element: Element): boolean {
        for (const textNode of element.getChildren()) {
            if (textNode.is(GlobalConstant.MODEL_TEXT) || textNode.is(GlobalConstant.MODEL_TEXT_PROXY)) {
                return true;
            }
        }

        return false;
    }

    private findElementSiblingAtOffset(element: Element, offset: number): Node {
        let charCount = 0;

        for (const child of element.getChildren()) {
            charCount += this.getChildTextLength(child);

            if (charCount >= offset) {
                return child;
            }
        }

        return element;
    }

    private findElementSibling(element: Element): Node {
        const childrenInReverseOrder = Array.from(element.getChildren()).reverse();

        for (const child of childrenInReverseOrder) {
            if (child.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_SPAN) && this.containsTextNodes(child)) {
                return child;
            } else if (child.is(GlobalConstant.MODEL_TEXT) || child.is(GlobalConstant.MODEL_TEXT_PROXY)) {
                return child;
            }
        }

        return element;
    }

    private getElementWithAttributesAppliedInPreviousElement(previousElement: Element): Element {
        let elementPreviousWithAttributes = previousElement;
        const attributesKeys = previousElement?.getAttributeKeys();
        if (Array.from(attributesKeys).length < 1 && previousElement.parent.name ===  GlobalConstant.MODEL_PARAGRAPH) {
            elementPreviousWithAttributes = previousElement.parent as Element;
        }

        return elementPreviousWithAttributes;
    }

    private getStartPosition(range: Range, model: Model): Position {
        return range.start.isAtEnd ?
            range.start.getLastMatchingPosition(({ item }) => !model.schema.isContent(item), { boundaries: range }) :
            range.start;
    }

    private isElementWithClass(node: ViewNode | ViewDocumentFragment, classNameToIdentify: string): boolean {
        return node.is(GlobalConstant.CONTAINER_ELEMENT) && !!node.hasClass(classNameToIdentify);
    }

    private findElementAncestorContainerWithClass(position: ViewElement | ViewNode | null, classNameToIdentify: string, generalContainer: string): ViewContainerElement | null {
        const firstContainerFound = position?.getAncestors({ includeSelf: true }).reverse().find((ancestor): ancestor is ViewContainerElement => this.isElementWithClass(ancestor, generalContainer)) || null;
        if (!firstContainerFound?.hasClass(classNameToIdentify)) {
            return null;
        }
        return firstContainerFound;
    }

}
