import { ClauseFolderDto } from './clause-folder-dto.model';
import { ClauseVersionDTO } from './clauseVersionDTO';
import { FileStatusApplicationDto } from './fileStatusApplicationDto';
import { FileUpdateInformationDto } from './fileUpdateInformationDto';
import { MasterData } from './masterData';
import { UserDTO } from './userDTO';
import { UserClausePermissionsDto } from './user-clause-permissions-dto.model';
import { WarningChangesClauseDto } from './warning-changes-clause-dto.model';

export interface ClauseDto {
    id?: string;
    name?: string;
    description?: string;
    creationTimeStamp?: Date;
    modificationTimeStamp?: Date;
    creationUser?: UserDTO;
    lastModificationUser?: UserDTO;
    clauseFolder?: ClauseFolderDto;
    buyer?: UserDTO;
    clauseFolderId?: string;
    iddOrigin?: string;
    userPermissions?: UserClausePermissionsDto;
    warningChanges?: WarningChangesClauseDto;
    html?: string;
    clauseStatusId?: string;
    clauseStatus?: MasterData;
    clauseStatusApplication?: FileStatusApplicationDto;
    clauseVersions?: ClauseVersionDTO[];
    currentVersion?: number;
    fileUpdateInformation?: FileUpdateInformationDto[];
    hasAppliedRegulations?: boolean;
    isEmbedded?: boolean;
}
