import { Injectable } from "@angular/core";
import { Editor, DecoupledEditor, Writer, Element, TreeWalkerValue, Item, TextProxy } from 'ckeditor5';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { phoneStringValidator } from 'src/app/core/shared/validators/formValidators/phoneValidator';
import { DNIValidator } from "src/app/core/shared/validators/DNIValidator";
import { NIFValidator } from "src/app/core/shared/validators/NIFValidator";
import { NIEValidator } from "src/app/core/shared/validators/NIEValidator";
import { IBANValidator } from "src/app/core/shared/validators/IBANValidator";
import { maxLengthValidator } from "src/app/core/shared/validators/maxLengthValidator";
import { GlobalConstant } from "../../models/base/global-constant";
import { emailValidator } from "src/app/core/shared/validators/emailValidator";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { PluginElementType } from "../../../models/plugin-element-type.enum";

@Injectable({
    providedIn: "root",
})
export class InputUtilsService {

    private MAX_LENGTH_INPUT_VALUE = 100;
    private utilsService: PluginUtilsService;

    constructor() {
        this.utilsService = new PluginUtilsService();
    }

    public isTypeValid(inputType: string): boolean {
        return InputPlugin.ALLOWED_TYPES.includes(inputType.trim().toLocaleLowerCase());
    }

    public isDataValid(value: string, type: string): boolean {
        if (value === '') {
            return true;
        }

        const validators: { [key: string]: (value: string) => boolean } = {
            [GlobalConstant.UNDEFINED]: (value) => maxLengthValidator(value, this.MAX_LENGTH_INPUT_VALUE),
            [GlobalConstant.PHONE]: (value) => phoneStringValidator(value),
            [GlobalConstant.EMAIL]: (value) => emailValidator(value),
            [GlobalConstant.DNI]: (value) => DNIValidator(value),
            [GlobalConstant.NIE]: (value) => NIEValidator(value),
            [GlobalConstant.NIF]: (value) => NIFValidator(value),
            [GlobalConstant.IBAN]: (value) => IBANValidator(value)
        };

        const validator = validators[type];
        if (validator) {
            return validator(value);
        }
        return false;
    }

    public hasAliasInDocument(editor: Editor, alias: string, inputId?: string): boolean {
        const data = (editor as DecoupledEditor).sourceElement;

        const queryFindRoleDifferentFromCurrent = inputId
            ? `[${InputPlugin.ATTRIBUTE_ALIAS_IS_STORED}="${alias}"]:not([id="${inputId}"]):not([data-is-linked="true"])`
            : `[${InputPlugin.ATTRIBUTE_ALIAS_IS_STORED}="${alias}"]:not([data-is-linked="true"])`;

        return data?.querySelector(queryFindRoleDifferentFromCurrent) !== null;
    }

    public getInputValue(inputModel: Item): string{
        for (const child of (inputModel as Element).getChildren()) {
            if (child.is('$text')) {
                return child.data;
            } else {
                const text = this.getInputValue(child as Element);
                if (text) {
                    return text;
                }
            }
        }
        return null;
    }

    public transformInputValue(writer: Writer,  inputModel: TreeWalkerValue, transform: string): void {
        const previousValue = this.getInputValue(inputModel.item as Element);
        if (!previousValue) {
            return;
        }

        const inputcontentModel = (inputModel.item as Element).getChild(0) as Element;
        const range = writer.createRangeIn(inputcontentModel);
        writer.remove(range);
        writer.insertText(this.transformText(previousValue, transform), inputcontentModel, 0);
    }

    public editInputValue(writer: Writer, inputModel: TreeWalkerValue, value: string): void {
        const inputcontentModel = (inputModel.item as Element).getChild(0) as Element;
        const range = writer.createRangeIn(inputcontentModel);
        writer.remove(range);
        writer.insertText(value, inputcontentModel, 0);

        if (!inputcontentModel.getChild(0)) {
            return;
        }
        const previousElement = this.utilsService.findStyledElementBefore(inputcontentModel.parent as Element, writer, PluginElementType.INLINE);
        if(!previousElement) {
            return;
        }
        const attributes = this.utilsService.getStyleAttributesFromPreviousElement(previousElement as Element);
        writer.setAttributes(attributes, inputcontentModel.getChild(0));
    }

    public getInputParentElementModelWithAlias(editor: Editor, alias: string): TreeWalkerValue  {
        const modelView = editor.model;
        let inputModel: TreeWalkerValue;
        modelView.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('alias') && value.item.getAttribute('alias') === alias && value.item.getAttribute('is-linked') === false) {
                    inputModel = value;
                    break;
                }
            }
        });

        return inputModel;
    }

    public getLinkedFieldsElementModelWithAlias(editor: Editor, alias: string): TreeWalkerValue[] {
        const modelView = editor.model;
        const inputModels: TreeWalkerValue[] = [];

        modelView.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('alias') && value.item.getAttribute('alias') === alias && value.item.getAttribute('is-linked') === true) {
                    inputModels.push(value);
                }
            }
        });

        return inputModels;
    }

    public getInputAndLinkedFieldsElementModelWithAlias(editor: Editor, alias: string): TreeWalkerValue[] {
        const modelView = editor.model;
        const inputModels: TreeWalkerValue[] = [];

        modelView.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('alias') && value.item.getAttribute('alias') === alias) {
                    inputModels.push(value);
                }
            }
        });

        return inputModels;
    }

    public hasLinkedFields(alias: string, document: Document): boolean {
        if (!alias) {
            return false;
        }

        const containerClass = InputPlugin.MODEL_ENTITIES.container.editionView;
        const aliasAttribute = InputPlugin.MODEL_ENTITIES.alias.editionView;
        const linkedAttribute = InputPlugin.MODEL_ENTITIES.isLinked.editionView;
        const linkedFields = document.querySelectorAll(`span.${containerClass}[${aliasAttribute}="${alias}"][${linkedAttribute}="true"]:not(.ck-pagination-view .input-plugin)`);

        if (linkedFields.length > 0) {
            return true;
        }

        return false;
    }

    private transformText(text: string, transform: string): string {
        if (text.trim() === '') {
            return '';
        }

        const transformations: { [key: string]: () => string } = {
            [GlobalConstant.UPPERCASE]: () => text.toUpperCase(),
            [GlobalConstant.LOWERCASE]: () => text.toLowerCase(),
            [GlobalConstant.CAMEL]: () =>
                text.toLowerCase().replace(/(^|\s)([a-zA-ZÀ-ÿ])/g, char => char.toUpperCase())
        };

        return transformations[transform]?.() || text;
    }
}
