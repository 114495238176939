import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DocumentClauseDTO } from 'src/app/api';

@Injectable({
    providedIn: 'root'
})
export abstract class ITemplatesWithClauseUpdateRevisionService {
    abstract getTemplatesRevisionStatusSubscription(): BehaviorSubject<Map<string, string>>;
    abstract getTemplatesRevisionStatus(): Map<string, string>;
    abstract setTemplatesRevisionStatus(templateRevisionStatus: Map<string, string>);
    abstract getTotalPendingTemplates(): number;
    abstract getIndex(documentClause: DocumentClauseDTO): number;
    abstract getTemplateRevisionStatus(documentClause: DocumentClauseDTO): string;
    abstract isPending(documentClause: DocumentClauseDTO): boolean;
    abstract isApplied(documentClause: DocumentClauseDTO): boolean;
    abstract setPending(documentClause: DocumentClauseDTO): void;
    abstract setApplied(documentClause: DocumentClauseDTO): void;
    abstract getNextDocumentClauseId(currentId: number): string;
    abstract getPreviousDocumentClauseId(currentId: number): string;
    abstract getFirstDocumentClauseId(): string;
    abstract getLastDocumentClauseId(): string;
}