import { Injectable } from '@angular/core';
import { ApiProcessorService } from '../api-processor.service';
import { LockedFilesService } from '../base/locked-file.service';

@Injectable()
export class ManagerLockedClausesService extends LockedFilesService {

    protected override basePath = '/api/manager-management/clauses/{entityId}';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) {
        super(apiProcessorService);
    }
}
