<div class="header {{customizeclassProduct}}" [ngClass]="currentScreenSize">
    <div class="header-container {{customizeclassProduct}}" [ngClass]="currentScreenSize">
        <div class="header-actions {{currentScreenSize}} {{customizeclassProduct}}" [ngClass]="{'brand': isLandingPage, 'ctbox' : !isLandingPage}">
            <div class="header-logo" [ngClass]="{'brand': isLandingPage, 'ctbox' : !isLandingPage}">
                <ctbox-logo [customClass]="'contractBox'" [currentScreenSize]="currentScreenSize" [isLandingPage]="isLandingPage" (click)="goToLink()"></ctbox-logo>
            </div>
            <div class="header-actions-menu" [ngClass]="currentScreenSize">
                <ng-container *ngIf="isLandingPage">
                    <div class="header-actions-item">
                        <a [href]="publicLinks.telPhone" class="link phone" [title]="publicLinks.brandPhone">
                            <mat-icon class="link-phone ">phone_in_talk</mat-icon>
                            <span class="link-phone-text">{{publicLinks.brandPhone}}</span>
                        </a>
                    </div>
                    <div class="header-logo brand">
                        <a [href]="publicLinks.brandUrl" target="_blank" rel="noopener" class="logo-branding" [ngClass]="{'isotipo' : currentScreenSize === 'XSmall'}"></a>
                    </div>
                </ng-container>
                <div class="header-actions-item" *ngIf="!isLandingPage && showButtons">
                    <a [href]="publicLinks.brandUrl" target="_blank" rel="noopener" class="logo logo-branding" [ngClass]="{'isotipo' : currentScreenSize === 'XSmall'}" *ngIf="hasBrand"></a>
                </div>
                <div class="header-actions-item" *ngIf="!isLandingPage && showButtons">
                    <ctbox-button buttonType="text" buttonIconSvg="comment" buttonText="Chat" i18n-buttonText="@@Chat" customClass="text-default" *ngIf="hasChatButton" (click)="openChat()"></ctbox-button>
                </div>
                <div class="header-actions-item" *ngIf="!isLandingPage && showButtons">
                    <ctbox-button [customClass]="'mat-header'" buttonType="text" buttonText="Formación" i18n-buttonText="@@CabeceraFormacion" customClass="text-default" [routerLink]="publicLinks.routeTraining" *ngIf="hasTrainingButton"></ctbox-button>
                </div>
                <div class="header-actions-item login-status" *ngIf="showLoginButton">
                    <app-login-status [showAccessButton]="showAccessButton" class="login-status"></app-login-status>
                </div>
                <div *ngIf="!isLandingPage && hasProductButton" class="header-actions-item products">
                    <ctbox-button buttonIcon="apps" buttonColor="secondary" [matMenuTriggerFor]="menuLink"></ctbox-button>
                    <mat-menu #menuLink="matMenu">
                        <a class="link-app" [href]="publicLinks.LLDUrl" target="_blank" title="laleydigital">
                            <span class="link-img logo-LLD"></span>
                            <span class="link-text">laleydigital</span>
                        </a>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="header-menu {{customizeclassProduct}}" [ngClass]="currentScreenSize">
            <ctbox-header-sections [menuItems]="menuItems" class="ctbox-nav" *ngIf="!isLandingPage" [customize]="customizeclassProduct" [size]="currentScreenSize"></ctbox-header-sections>
            <ctbox-tabs *ngIf="isLandingPage" [classTabs]="'landing-tabs ' + customizeclassProduct" [size]="currentScreenSize"></ctbox-tabs>
        </div>
    </div>
    <div class="header-submenu {{customizeclassProduct}}" [ngClass]="currentScreenSize">
        <div class="header-submenu-logo">
            <ctbox-logo [currentScreenSize]="currentScreenSize" [isLandingPage]="isLandingPage" [isPositiveLogo]="false" (click)="goToLink()"></ctbox-logo>
        </div>
    </div>
</div>
