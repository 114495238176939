import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserInfoDTO } from 'src/app/api';
import { IItem } from 'src/app/shared/components/ctbox-generic-list/models';

@Component({
    selector: 'ctbox-header-sections',
    templateUrl: './ctbox-header-sections.component.html'
})

export class CtboxHeaderSectionsComponent implements AfterViewInit, OnInit {

    @Input() size: string;
    @Input() customize: string;
    @Input() menuItems: IItem[];

    public userInfo: BehaviorSubject<UserInfoDTO>;

    public hasSharedDocument = new BehaviorSubject<boolean>(false);
    public userInfoSubscription: any;
    public isViewInitialized = false;
    public navLinks = [];
    public activeLink = this.navLinks[0];

    constructor(
        private changeDetector: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.navLinks = this.menuItems;
    }

    public ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.changeDetector.detectChanges();
    }

    public isLinkActive(rla: RouterLinkActive): boolean {
        return rla.isActive;
    }

    public goSelectedLink(link: any): void {
        this.activeLink = link;
    }

    public getShared(item: any): boolean {
        if (item.shared) {
            return item.shared.getValue() === true;
        }

        return false;
    }

    public getPursachedModule(item: any): boolean {
        if (item.purchasedModule) {
            return item.purchasedModule.getValue() === true;
        }
        return false;
    }

}
