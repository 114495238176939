import { Observable } from 'rxjs';
import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor.service';
import { HttpHeaders } from '@angular/common/http';
import { Configuration } from 'src/config/configuration';

export abstract class LockedFilesService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    
    protected abstract basePath: string;

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) { }

    public lock(entityId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/lock`.replace('{entityId}', entityId);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public unlock(entityId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/unlock`.replace('{entityId}', entityId);

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
