import { Directive, EventEmitter, OnDestroy } from "@angular/core";
import {
    Plugin, Editor, ContextualBalloon, ButtonView, Locale, viewToModelPositionOutsideModelElement, Schema, Conversion,
    ClickObserver, ViewElement, ClipboardInputTransformationData, EventInfo, DataTransfer as CKEditorDataTransfer, Node,
    ViewDocumentFragment, Element, DomEventData, ClipboardContentInsertionData, TextProxy, Writer, Model, Text
} from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import DoubleClickObserver from "../../../utils/double-click-observer";
import { CommentsOnly } from "ckeditor5-premium-features";
import { GlobalConstant } from "../../models/base/global-constant";
import { PluginElementType } from "../../../models/plugin-element-type.enum";

type ObserverType = typeof ClickObserver | typeof DoubleClickObserver;

@Directive()
export abstract class BasePlugin extends Plugin implements OnDestroy {
    public static contextEditor: string;

    protected balloon!: ContextualBalloon;
    protected pluginUtils: PluginUtilsService;
    protected schema: Schema;
    protected conversion: Conversion;
    protected button: ButtonView;

    protected abstract commands: { [key: string]: any; };
    protected abstract mappers: string[];
    protected abstract toolbarButton: ToolbarButtonModel;

    private onDestroy$ = new EventEmitter<void>();

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();
        this.schema = editor.model.schema;
        this.conversion = editor.conversion;
    }

    protected abstract defineSchema(): void;
    protected abstract defineConverters(): void;
    protected abstract editorInteractions(): void;

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
        this.defineDraggableConfiguration();
        this.setupModelPostFixing();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    protected toolbarExecuteOperation() { };

    protected defineCommands(): void {
        Object.entries(this.commands).forEach(([commandName, CommandClass]) => {
            this.editor.commands.add(commandName, new CommandClass(this.editor));
        });
    }

    protected defineMapper(): void {
        this.mappers.forEach(mapperClass => {
            this.editor.editing.mapper.on(
                'viewToModelPosition',
                viewToModelPositionOutsideModelElement(this.editor.model, (viewElement: ViewElement) => !!viewElement && viewElement.hasClass(mapperClass))
            );
        });
    }

    protected defineUI(): void {
        this.editorInteractions();
        this.toolbarInteractions(this.toolbarButton);
    }

    protected setupEditorObserver(observer: ObserverType): void {
        this.editor.editing.view.addObserver(observer);
        this.balloon = this.editor.plugins.get(ContextualBalloon);
    }

    protected toolbarInteractions(toolbarButton: ToolbarButtonModel): void {
        this.editor.ui.componentFactory.add(toolbarButton.pluginToolbarElementName, (locale: Locale) => {
            return this.button = this.createButtonToolbar(locale, toolbarButton);
        });
    }

    // Hacerla para todos aquellos que lleven Handler de pegar y hacerla abstract cuando lo tengan todos los hijos
    protected processElementsPaste(elements: Node[]): void { }

    // Hacerla para todos aquellos que lleven Handler de pegar con override y hacerla abstract cuando lo tengan todos los hijos
    protected getPluginDataViewClass(): string { return ''; }

    protected getPluginModelName(): string { return ''; }

    protected setupModelPostFixing(): void {
        return;
    }

    protected getSelectionParent(): ViewElement | undefined {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        return viewDocument.selection.focus!.getAncestors()
            .reverse()
            .find((node: ViewElement): node is ViewElement => node.is(GlobalConstant.ELEMENT));
    }

    protected finishEventPropagation(data: any, event: any) {
        data.preventDefault();
        data.stopPropagation();
        event.stop();
        event.return = true;
    }

    protected handlePasteInsertionEvent(event: EventInfo, _data: ClipboardInputTransformationData): void {
        if (this.editor.plugins.has('RestrictedEditingMode') && !!this.getPluginModelName() &&
            (this.editor?.model?.document?.selection?.getFirstPosition()?.nodeAfter as Element)?.name === this.getPluginModelName()) {
            event.stop();
            event.return = false;
        }
    }

    protected handlePasteEvent(_event: EventInfo, data: ClipboardContentInsertionData): void {
        const clipboardData: CKEditorDataTransfer = data.dataTransfer;

        if (this.isPasteFromWord(clipboardData) || this.isImageInClipboard(clipboardData)) {
            return;
        }
        const containerEditionViewClass = this.getPluginDataViewClass();

        this.processPastedContent(data.content, containerEditionViewClass);
    }

    protected simulatePasteClipboard(fragment: Element): ViewDocumentFragment | null {
        const clipboardPipeline = this.editor.plugins.get('ClipboardPipeline');
        const fragmentHtml = this.editor.data.stringify(fragment);
        const dataTransfer = this.createDataTransfer(fragmentHtml);
        const parsedContent = this.editor.data.toView(fragment);

        if (!parsedContent) {
            return null;
        }

        const eventData = this.createPasteEventData(parsedContent, dataTransfer);
        return this.processClipboardTransformation(eventData, clipboardPipeline);
    }

    protected createDataTransfer(fragmentHtml: string): DataTransfer {
        const dataTransfer = new DataTransfer();
        dataTransfer.setData('text/html', fragmentHtml);
        return dataTransfer;
    }

    protected createPasteEventData(parsedContent: ViewDocumentFragment, dataTransfer: DataTransfer): any {
        return {
            content: parsedContent,
            dataTransfer,
            method: 'paste'
        };
    }

    protected processClipboardTransformation(eventData: any, clipboardPipeline: any): ViewDocumentFragment | null {
        let transformedContent: ViewDocumentFragment | null = null;

        const customListener = (evt: EventInfo, data: any) => {
            evt.stop();
            transformedContent = data.content as ViewDocumentFragment;
        };

        try {
            this.registerClipboardListener(clipboardPipeline, customListener);
            clipboardPipeline.fire('inputTransformation', eventData);
        } catch (error) {
            console.error('Error durante la transformación del contenido:', error);
        } finally {
            this.deregisterClipboardListener(clipboardPipeline, customListener);
        }

        return transformedContent;
    }

    protected registerClipboardListener(clipboardPipeline: any, callback: (evt: EventInfo, data: any) => void): void {
        clipboardPipeline.on('inputTransformation', callback, { priority: 'high' });
    }

    protected deregisterClipboardListener(clipboardPipeline: any, callback: (evt: EventInfo, data: any) => void): void {
        clipboardPipeline.off('inputTransformation', callback);
    }

    protected disableToolbarButton(commandName: string) {
        this.editor.commands.get(commandName).on('change:isEnabled', (_event, _propName, newValue, _oldValue) => {
            if (this.button) {
                this.button.isEnabled = newValue;
            }
        });
    }

    protected handleDoubleBlankInRestrictedMode(editor: Editor) {
        // TODO Resolve in ckEditor v 44.0. Take out when updating to version 44.0 or higher
        // Remove existing restricted editing markers when setting new data to prevent marker resurrection.
        // Without this, markers from removed content would be incorrectly restored due to the resurrection mechanism.
        // See more: https://github.com/ckeditor/ckeditor5/issues/9646#issuecomment-843064995
        editor.data.on('set', () => {
            editor.model.change(writer => {
                for (const marker of editor.model.markers.getMarkersGroup('restrictedEditingException')) {
                    writer.removeMarker(marker.name);
                }
            });
        }, { priority: 'high' });
    }

    protected isCommentsOnlyMode(): boolean {
        return this.editor?.plugins.has('CommentsOnly') && (this.editor?.plugins.get('CommentsOnly') as CommentsOnly).isEnabled;
    }

    protected isRestrictedMode(): boolean {
        return this.editor?.plugins.has('RestrictedEditingMode') && this.editor?.plugins.get('RestrictedEditingMode').isEnabled;
    }

    protected isReadOnlyOrRestrictedMode(): boolean {
        return this.editor?.isReadOnly || this.isRestrictedMode();
    }

    protected isReadOnlyOrCommentsOnly(): boolean {
        return this.editor?.isReadOnly || this.isCommentsOnlyMode();
    }

    protected getStyleAttributesFromPreviousElement(element: Element, writer: Writer): [string, unknown][] {
        const previousElement = this.pluginUtils.findStyledElementBefore(element as Element, writer, PluginElementType.BLOCK);
        if(!previousElement) {
            return [];
        }
        return this.pluginUtils.getStyleAttributesFromPreviousElement(previousElement);
    }

    protected contentFirstTypeShouldInheritStylesPostFixer(model: Model, writer: Writer, containerName: string, isGrandparentOuterContent: boolean = false): boolean {
        const changes = model.document.differ.getChanges();
        let wasFixed = false;
        for (const entry of changes) {
            if (entry.type == 'insert' && entry.name == GlobalConstant.MODEL_TEXT) {
                const content = entry.position?.parent?.parent;
                const isInsideContent = content?.name === containerName;
                if (!isInsideContent || entry.position.parent.parent.childCount !== 1) {
                    return;
                }

                const textNode = (entry.position.parent.parent.getChild(0) as Element).getChild(0)?.is(GlobalConstant.MODEL_TEXT) ? (entry.position.parent.parent.getChild(0) as Element).getChild(0) as Text : null;
                if (textNode?.data.length !== 1) {
                    return wasFixed;
                }

                let outerContainer = content.parent;
                if (isGrandparentOuterContent) {
                    outerContainer = content.parent.parent;
                }

                let elementToPutAttributes = entry.position.parent;
                if(!!elementToPutAttributes.getChild(0) &&  entry.position.parent.getChild(0).is(GlobalConstant.MODEL_TEXT)) {
                    elementToPutAttributes = entry.position.parent.getChild(0) as Element;
                }

                const attributes = this.getStyleAttributesFromPreviousElement(outerContainer as Element, writer);
                const previousAttributes = Array.from((elementToPutAttributes as Element).getAttributes());
                wasFixed = !this.pluginUtils.areSameAttributes(attributes, previousAttributes);

                writer.setAttributes(attributes, elementToPutAttributes as Element);
            }
        }
        return wasFixed;
    }

    private defineDraggableConfiguration(): void {
        const viewDocument = this.editor.editing.view.document;
        viewDocument.on('dragstart', this.handleDragEvents.bind(this));
        viewDocument.on('dragover', this.handleDragEvents.bind(this));
    }

    private handleDragEvents(evt: any, data: DomEventData): void {
        evt.stop();
        data.preventDefault();
    }

    private createButtonToolbar(locale: Locale, toolbarButton: ToolbarButtonModel): ButtonView {
        const button = new ButtonView(locale);

        button.set({
            label: toolbarButton.tooltip,
            tooltip: toolbarButton.hasTooltip,
            withText: toolbarButton.hasText
        });

        if (!!toolbarButton.icon) {
            button.icon = toolbarButton.icon;
        }

        button.labelView.text = toolbarButton.buttonText;
        button.on("execute", () => { this.toolbarExecuteOperation(); });
        return button;
    }

    private findPluginElements(element: any, classEditionView: string): any[] {
        let elements = [];

        if (element.hasClass && element.hasClass(classEditionView)) {
            elements.push(element);
        }

        if (element.getChildren) {
            const children = Array.from(element.getChildren());
            children.forEach(child => {
                elements = elements.concat(this.findPluginElements(child, classEditionView));
            });
        }

        return elements;
    }

    private isPasteFromWord(clipboardData: CKEditorDataTransfer) {
        return clipboardData.types.includes('text/rtf');
    }

    private isImageInClipboard(clipboardData: CKEditorDataTransfer): boolean {
        const anyImageTypeSubstring = 'image';
        return clipboardData.files.length > 0 && clipboardData.files[0].type.includes(anyImageTypeSubstring);
    }

    private processPastedContent(content: any, containerEditionViewClass: string): void {
        let pluginElements: Node[] = [];
        const children = Array.from(content.getChildren());
        children.forEach(child => {
            pluginElements = pluginElements.concat(this.findPluginElements(child, containerEditionViewClass));
        });

        this.processElementsPaste(pluginElements);
    }
}
