import { Injectable } from '@angular/core';
import { ClauseDto, FileStatusesCodes, MasterData, TemplateDTO } from 'src/app/api';
import { IFileStateService } from './file-state.interface';
import { UpdateFileActionCodes } from 'src/app/api/model/UpdateFileActionCodes';
import { FileUpdateInformationDto } from 'src/app/api/model/fileUpdateInformationDto';
import { FileStatusApplicationCodes, FileStatusApplicationDto } from 'src/app/api/model/fileStatusApplicationDto';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';

@Injectable({
    providedIn: 'root'
})
export class FileStateService extends IFileStateService {

    private fileStatusApplicationMapper = new Map<FileStatusApplicationCodes, FileStatusCodes>([
        [FileStatusApplicationCodes.CREATION, FileStatusCodes.CREATION],
        [FileStatusApplicationCodes.ERROR, FileStatusCodes.ERROR],
        [FileStatusApplicationCodes.INFORCE, FileStatusCodes.INFORCE],
        [FileStatusApplicationCodes.EXPIRED, FileStatusCodes.EXPIRED],
        [FileStatusApplicationCodes.PENDING, FileStatusCodes.PENDING],
        [FileStatusApplicationCodes.DRAFT, FileStatusCodes.DRAFT],
        [FileStatusApplicationCodes.AVAILABLE, FileStatusCodes.AVAILABLE]
    ]);

    public getFileStatusCodeInTemplate(template: TemplateDTO): FileStatusCodes {
        if (!template || !template.templateStatus || !template.templateStatusApplication) {
            return FileStatusCodes.ERROR;
        }
        const updateFileActionCode = this.getUploadActionsCodeByPreference(template.fileUpdateInformation);
        return this.mapToFileStatusCodes(template.templateStatus, template.templateStatusApplication, updateFileActionCode);
    }

    public getFileStatusCodeInClause(clause: ClauseDto): FileStatusCodes {
        if (!clause || !clause.clauseStatus) {
            return FileStatusCodes.ERROR;
        }

        return this.mapToFileStatusCodes(clause.clauseStatus, clause.clauseStatusApplication);
    }

    public mapToFileStatusCodes(fileState: MasterData, fileStatusApplication: FileStatusApplicationDto,
                                updateFileAction?: MasterData): FileStatusCodes {
        if (!fileState) {
            return FileStatusCodes.ERROR;
        }

        const code = fileState.code as unknown as FileStatusesCodes;
        const updateCode = !!updateFileAction ? (updateFileAction.code as unknown as UpdateFileActionCodes) : null;
        const fileStatusApplicationCode = fileStatusApplication.code;

        if (code === FileStatusesCodes.UPDATE) {
            return this.getUpdateFileStatusCode(updateCode, fileStatusApplicationCode);
        }

        return this.fileStatusApplicationMapper.get(fileStatusApplicationCode);
    }

    public getFileStatusMessage(fileStatus: FileStatusCodes): string {
        switch (fileStatus) {
            case FileStatusCodes.CREATION:
                return $localize`:@@FicheroEstadoCreacion:En creación`;
            case FileStatusCodes.DRAFT:
            case FileStatusCodes.UPLOADED_DRAFT:
            case FileStatusCodes.DOWNLOADED_DRAFT:
                return $localize`:@@FicheroEstadoBorrador:Borrador`;
            case FileStatusCodes.PENDING:
            case FileStatusCodes.UPLOADED_PENDING:
            case FileStatusCodes.DOWNLOADED_PENDING:
                return $localize`:@@EstadoArchivoPendienteDeImplantacion:Pte. de implantación`;
            case FileStatusCodes.INFORCE:
            case FileStatusCodes.UPLOADED_INFORCE:
            case FileStatusCodes.DOWNLOADED_INFORCE:
                return $localize`:@@FicheroEstadoVigente:Vigente`;
            case FileStatusCodes.EXPIRED:
            case FileStatusCodes.UPLOADED_EXPIRED:
            case FileStatusCodes.DOWNLOADED_EXPIRED:
                return $localize`:@@FicheroEstadoNoVigente:No vigente`;
            case FileStatusCodes.AVAILABLE:
                return $localize`:@@FicheroEstadoDisponible:Disponible`;
            default:
                return $localize`:@@FicheroEstadoError:Error`;
        }
    }

    public getClauseStatusMessage(fileStatus: FileStatusCodes): string {
        switch (fileStatus) {
            case FileStatusCodes.CREATION:
                return $localize`:@@EstadoArchivoCreacion:En creación`;
            case FileStatusCodes.ERROR:
                return $localize`:@@EstadoArchivoError:Error`;
            case FileStatusCodes.UPLOADED_PENDING:
            case FileStatusCodes.UPLOADED_INFORCE:
            case FileStatusCodes.UPLOADED_EXPIRED:
            case FileStatusCodes.DOWNLOADED_PENDING:
            case FileStatusCodes.DOWNLOADED_INFORCE:
            case FileStatusCodes.DOWNLOADED_EXPIRED:
            case FileStatusCodes.AVAILABLE:
                return $localize`:@@EstadoArchivoDisponible:Disponible`;
        }
    }

    public getFileStatusWithUpdateMessage(fileStatus: FileStatusCodes): string {
        let message = this.getFileStatusMessage(fileStatus);

        message = this.getUpdateMessage(fileStatus, message);

        return message;
    }

    public getClauseStatusWithUpdateMessage(fileStatus: FileStatusCodes): string {
        let message = this.getClauseStatusMessage(fileStatus);

        message = this.getUpdateMessage(fileStatus, message);

        return message;
    }

    private getUpdateMessage(fileStatus: FileStatusCodes, message: string) {
        const updateStates = [FileStatusCodes.UPLOADED_DRAFT, FileStatusCodes.DOWNLOADED_DRAFT, FileStatusCodes.UPLOADED_PENDING,
        FileStatusCodes.DOWNLOADED_PENDING, FileStatusCodes.UPLOADED_INFORCE, FileStatusCodes.DOWNLOADED_INFORCE,
        FileStatusCodes.UPLOADED_EXPIRED, FileStatusCodes.DOWNLOADED_EXPIRED];

        if (updateStates.includes(fileStatus)) {
            message += ' - ' + $localize`:@@FicheroEstadoActualizacion:En actualización`;
        }
        return message;
    }

    public getUploadActionsCodeByPreference(actions: FileUpdateInformationDto[]): FileUpdateInformationDto {
        if (!actions) {
            return null;
        }

        if (actions.some((action) => action.updateFileAction?.code === UpdateFileActionCodes.UPLOADED)) {
            return actions.find((item) => item.updateFileAction?.code === UpdateFileActionCodes.UPLOADED);
        } else if (actions.some((item) => item.updateFileAction?.code === UpdateFileActionCodes.DOWNLOADED)) {
            return actions.find((item) => item.updateFileAction?.code === UpdateFileActionCodes.DOWNLOADED);
        }

        return null;
    }

    private getUpdateFileStatusCode(updateFileActionCode: UpdateFileActionCodes,
                                    fileStatusApplicationCode: FileStatusApplicationCodes): FileStatusCodes {

        if (updateFileActionCode === UpdateFileActionCodes.DOWNLOADED) {
            switch (fileStatusApplicationCode) {
                case FileStatusApplicationCodes.DRAFT:
                    return FileStatusCodes.DOWNLOADED_DRAFT;
                case FileStatusApplicationCodes.PENDING:
                    return FileStatusCodes.DOWNLOADED_PENDING;
                case FileStatusApplicationCodes.INFORCE:
                    return FileStatusCodes.DOWNLOADED_INFORCE;
                case FileStatusApplicationCodes.EXPIRED:
                    return FileStatusCodes.DOWNLOADED_EXPIRED;
            }
        } else {
            switch (fileStatusApplicationCode) {
                case FileStatusApplicationCodes.DRAFT:
                    return FileStatusCodes.UPLOADED_DRAFT;
                case FileStatusApplicationCodes.AVAILABLE:
                case FileStatusApplicationCodes.PENDING:
                    return FileStatusCodes.UPLOADED_PENDING;
                case FileStatusApplicationCodes.INFORCE:
                    return FileStatusCodes.UPLOADED_INFORCE;
                case FileStatusApplicationCodes.EXPIRED:
                    return FileStatusCodes.UPLOADED_EXPIRED;

            }
        }
    }
}
