import { GlobalConstant } from './../../models/base/global-constant';
import { Editor, Element, Writer} from 'ckeditor5';
import { RadioElements } from '../../models/radio/radio-model';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioBaseCommand } from './radio-base-command';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

export default class AddRadioCommand extends RadioBaseCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    private readonly pluginUtils: PluginUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();
    }

    public override execute(value: number): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const guid = this.pluginUtils.generateUUID();
            const radio = this.createRadio(writer, value, guid);
            this.setEachRadioConfig(writer, value, guid, radio);

            editor.model.insertObject(radio, null, null, { setSelection: 'after' });
        });
    }

    private setEachRadioConfig(writer: Writer, value: number, guid: string, radio: Element): void {
        for (let index = 0; index < value; index++) {

            const description = this.createDescription(writer);
            const content = this.createContent(writer);
            const option = this.createOption(writer, index);
            const check = this.createCheck(writer, guid);
            const radioElements: RadioElements = { description, content, option, check }

            this.addRadioText(this.editor, writer, index, radioElements);
            this.addRadioToWriter(writer, radio, radioElements);
        }
    }

    private createRadio(writer: Writer, value: number, guid: string): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.container.model, {
            [RadioPlugin.MODEL_ENTITIES.optionCount.model]: value.toString(),
            [GlobalConstant.ATTRIBUTE_ID]: `${RadioPlugin.ID_RADIO_PREFIX}${guid}`,
            [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: undefined,
        });
    }

    private createCheck(writer: Writer, guid: string): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
            [RadioPlugin.MODEL_ENTITIES.groupName.model]: `${RadioPlugin.ID_RADIO_PREFIX}${guid}`
        });
    }

    private createOption(writer: Writer, index: number): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.option.model, {
            [RadioPlugin.MODEL_ENTITIES.optionPosition.model]: index.toString()
        });
    }

    private createContent(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.content.model, {});
    }

    private createDescription(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.description.model, {});
    }

    private addRadioText(editor: Editor, writer: Writer, index: number, radioElements: RadioElements) {
        const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${index + 1})`);
        const descriptionDefaultTextElement = writer.createElement(GlobalConstant.MODEL_PARAGRAPH);
        const contentDefaultTextElement = writer.createElement(GlobalConstant.MODEL_PARAGRAPH);
        const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${index + 1}`);

        this.usePreviousElementStyleAttributes(editor, writer, contentDefaultText);

        writer.append(descriptionDefaultText, descriptionDefaultTextElement);
        writer.append(descriptionDefaultTextElement, radioElements.description);
        writer.append(contentDefaultText, contentDefaultTextElement);
        writer.append(contentDefaultTextElement, radioElements.content);
    }

    private usePreviousElementStyleAttributes(editor: Editor, writer: Writer, contentDefaultText) {
        const selection = editor.model.document.selection;
        const previousElement = this.pluginUtils.findPreviousElement(selection);
       if (previousElement) {
            const attributes = previousElement.getAttributes();
            this.pluginUtils.setAttributes(Array.from(attributes), contentDefaultText, writer);
        }
    }

    private addRadioToWriter(writer: Writer, radio: Element, radioElements: RadioElements) {
        writer.append(radioElements.check, radioElements.option);
        writer.append(radioElements.description, radioElements.option);
        writer.append(radioElements.content, radioElements.option);
        writer.append(radioElements.option, radio);
    }
}
