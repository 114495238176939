import { ApiOperation, ApiProcessorService, ApiRequest, ApiResponse } from '../api-processor.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateManualLegalAlertDto } from '../../model/standard/template-manual-legal-alert-dto.model';

@Injectable()
export class TemplateManualLegalAlertsService {

    private basePath = '/api/template-legal-alerts/manual-alerts';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public get(templateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${templateId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public add(templateManualLegalALert: TemplateManualLegalAlertDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = templateManualLegalALert;
        apiRequest.endpointUrl = `${ this.basePath }`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public delete(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
