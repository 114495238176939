import { Injectable } from '@angular/core';
import {
    TemplateDTO, UserDTO, UserClausePermissionsDto, UserPermissionsOnDocumentDTO, WarningChangesDTO
} from 'src/app/api/model/models';

@Injectable({
    providedIn: 'root'
})
export abstract class ITemplateListService {
    abstract getAuthorSurnameToOrder(surname: UserDTO): string;
    abstract getActionToOrder(userPermissions: UserClausePermissionsDto): number;
    abstract getAlertsToOrder(template: TemplateDTO): number;
}
