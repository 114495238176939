import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClauseFolderDto, UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { IClausesFolderTreeService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-tree.service.interface';
import { IClausesFolderService } from 'src/app/core/shared/services/clauses/clauses-folder/clauses-folder.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeAction } from 'src/app/shared/components/ctbox-tree/models/node-tree-action.model';
import { NodeTreeUserAction } from 'src/app/shared/components/ctbox-tree/enums/node-tree-user-action.enum';
import { NIL as NIL_UUID } from 'uuid';
import { IJsonPatchDocument, Op } from 'src/app/api/model/json-patch.model';
import { ClauseFolderService } from 'src/app/api/api/standard/clause-folder.service';
import { FolderPostOperationActions } from 'src/app/core/shared/enums/folder-post-operation-actions.enum';
import { FolderResultErrorCode } from 'src/app/core/shared/enums/folder-result-error-code.enum';
import { IClausesFolderHierarchyTreeActionsService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-with-content/clauses-folder-with-content.actions.service.interface';
import { ClauseService } from 'src/app/api/api/standard/clause.service';

@Component({
    selector: 'app-clause-folder',
    templateUrl: './clause-folder.component.html',
    styleUrls: ['./clause-folder.component.scss']
})
export class ClauseFolderComponent implements OnInit, OnDestroy {

    @Input() rootActions: NodeTreeActionType[] = [NodeTreeActionType.Create, NodeTreeActionType.Selected];
    @Input() nodeActions: NodeTreeActionType[] = [NodeTreeActionType.Create, NodeTreeActionType.Selected, NodeTreeActionType.Rename];

    @Output() selectedNodeIdTree = new EventEmitter<string>();

    public clauseFolderTree: FileNode[];
    public clauseTreeSelectionModel: SelectionModel<string>;
    public isTreeLocked: boolean;
    public actions: NodeTreeUserAction[] = [NodeTreeUserAction.Menu];
    public initialNodes: string[] = [];
    public currentFolderId: string;

    private isValidSubscription = new BehaviorSubject<boolean>(false);
    private userInfo: UserInfoDTO;
    private userInfoSubscription: Subscription;
    private clauseTreeSelectionModelSubscription: Subscription;
    private currentFolderIdSubscription: Subscription;
    private emptyClauseMessage =
        $localize`:@@NuevaCarpetaErrorNombreVacioMensaje:Introduce un nombre para la carpeta, por favor.`;

    constructor(
        private loginService: AuthorizeService,
        private genericDialogService: GenericDialogService,
        private apiClauseFolder: ClauseFolderService,
        private clausesFolderService: IClausesFolderService,
        @Inject('IClausesFolderTreeService') private readonly clausesFolderTreeService: IClausesFolderTreeService,
        @Inject('IClausesFolderHierarchyTreeActionsService')
        private clauseFolderHierarchyTreeActionsService: IClausesFolderHierarchyTreeActionsService,
        private apiClauseService: ClauseService) { }

    public ngOnInit(): void {
        this.clauseTreeSelectionModelSubscription = this.clausesFolderTreeService.getCurrentExpansionModel()
            .subscribe(selectionModel => this.clauseTreeSelectionModel = selectionModel);

        this.userInfoSubscription = this.loginService.getUserInfo().subscribe(userInfo => {
            this.userInfo = userInfo;
            this.loadFolderTree();
        });

        this.currentFolderIdSubscription = this.clausesFolderService.getCurrentFolderId().subscribe((currentFolderId: string) => {
            this.currentFolderId = currentFolderId;
        });
    }

    public ngOnDestroy(): void {
        if (this.currentFolderIdSubscription) {
            this.currentFolderIdSubscription.unsubscribe();
        }

        if (this.clauseTreeSelectionModelSubscription) {
            this.clauseTreeSelectionModelSubscription.unsubscribe();
        }

        if (this.userInfoSubscription) {
            this.userInfoSubscription.unsubscribe();
        }
    }

    public doActionFromNodeEvent(nodeEvent: NodeTreeAction) {
        switch (nodeEvent.typeEvent) {
            case NodeTreeActionType.Create:
                this.createFolder(nodeEvent);
                break;
            case NodeTreeActionType.Delete:
                this.deleteFolder(nodeEvent);
                break;
            case NodeTreeActionType.Selected:
                this.folderSelected(nodeEvent);
                break;
            case NodeTreeActionType.Rename:
                this.renameFolder(nodeEvent);
                break;
            case NodeTreeActionType.Move:
                this.moveFolder(nodeEvent);
                break;
            case NodeTreeActionType.MoveFromOutside:
                this.moveClauseToFolder(nodeEvent);
                break;
        }
    }

    private moveClauseToFolder(nodeAction: NodeTreeAction) {
        const node = nodeAction.node;
        const templateIdToMove = nodeAction.additionalParam.droppedObjectId;

        this.apiClauseService.updateClauseFolder(templateIdToMove, node.id).subscribe((data) => {
            const message = $localize`:@@MoverClausulaACarpetaMensaje:La cláusula se movió a la carpeta: ` + node.value;
            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                this.doActionAfter(FolderPostOperationActions.NO_ACTION, nodeAction);
                this.selectNode(node.id, node.value);
            });
        }, (error: any) => {
            const message = $localize`:@@MoverClausulaACarpetaErrorMensaje:Se ha producido un error al mover la cláusula a la carpeta.`;
            this.genericDialogService.showMessage(message);
        });
    }

    private createFolder(nodeAction: NodeTreeAction): void {
        if (nodeAction.node.value.trim() === '') {
            this.genericDialogService.showMessage(this.emptyClauseMessage).afterClosed();
            return;
        }

        const folder: ClauseFolderDto = {
            id: crypto.randomUUID(),
            name: nodeAction.node.value,
            clauseFolderParentId: nodeAction.node.parentId === this.clausesFolderTreeService.getRootNodeId() ?
            NIL_UUID : nodeAction.node.parentId,
        };

        this.apiClauseFolder.create(folder).subscribe((folderCreated: ClauseFolderDto) => {
            nodeAction.node.id = folderCreated.id;
            this.loadFolderTree();
            this.sendTreeOperationCallbackSuccessful(nodeAction);
        }, (error: any) => {
            this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private deleteFolder(nodeAction: NodeTreeAction) {
        const folderName = nodeAction.node.value;
        const message = $localize`:@@EliminaCarpetaClausulaConContenidoMensaje:Se eliminará la carpeta «${folderName}» y todo su contenido ¿deseas continuar?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.apiClauseFolder.delete(nodeAction.node.id).subscribe(() => {
                this.loadFolderTree();
                this.sendTreeOperationCallbackSuccessful(nodeAction);
                const parentId = nodeAction.node.parentId;
                const parentName = this.clauseFolderTree.find(f => f.id === parentId)?.value;
                this.selectNode(parentId, parentName);

            }, error => {
                this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                    this.doActionAfter(actionAfter, nodeAction);
                });
            });
        });
    }

    private renameFolder(nodeAction: NodeTreeAction): void {
        const node = nodeAction.node;
        const nodeNewName = node.value;

        const jsonPatchDocument: IJsonPatchDocument = {
            op: Op.REPLACE,
            path: '/name',
            value: nodeNewName
        };
        this.apiClauseFolder.patch(node.id, [jsonPatchDocument]).subscribe(() => {
            nodeAction.node.value = nodeNewName;
            this.sendTreeOperationCallbackSuccessful(nodeAction);
        }, (error: any) => {
            this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private moveFolder(nodeAction: NodeTreeAction): void {
        const node = nodeAction.node;
        const nodeDestination = nodeAction.additionalParam.destinationNode;

        if (node.parentId === node.id || nodeDestination.id === node.id || node.parentId === nodeDestination.id ||
            nodeDestination.parentId === node.id) {
            return;
        }

        const newParentId = nodeDestination.id !== this.clausesFolderTreeService.getRootNodeId() ? nodeDestination.id : null;

        const jsonPatchDocument: IJsonPatchDocument = {
            op: Op.REPLACE,
            path: '/clauseFolderParentId',
            value: newParentId
        };

        this.apiClauseFolder.patch(node.id, [jsonPatchDocument]).subscribe(() => {
            this.sendTreeOperationCallbackSuccessful(nodeAction);
            nodeAction.node.parentId = newParentId;
            this.loadFolderTree();
        }, (error: any) => {
            this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private loadFolderTree(): void {
        if (!this.userInfo) {
            return;
        }

        this.apiClauseFolder.getAll().subscribe((folders: ClauseFolderDto[]) => {
            this.clauseFolderTree = this.clauseFolderHierarchyTreeActionsService.create(folders, this.rootActions, this.nodeActions, null);
            this.initialNodes.push(this.clausesFolderTreeService.getRootNodeId());
        });
    }

    private sendTreeOperationCallbackFail(response: NodeTreeAction) {
        if (!response.failAction) {
            return;
        }

        response.failAction(response.node);
    }

    private sendTreeOperationCallbackSuccessful(response: NodeTreeAction) {
        if (!response.successfulAction) {
            return;
        }

        response.successfulAction(response.node);
    }

    private manageError(error: any): Promise<FolderPostOperationActions> {
        const promiseError = new Promise<FolderPostOperationActions>((resolve) => {
            let actionAfter = FolderPostOperationActions.NO_ACTION;
            const genericErrorMessage = $localize`:@@MoverClausulaACarpetaErrorMensajeErrorOperacion:Se ha producido un error en la operación.`;

            let message = $localize`:@@ErrorGenericoArbolClausulas:Se ha producido un error al realizar la operación`;

            const messageOutdated = $localize`:@@ClausulasArbolDeCarpetasNoSincronizadoMensaje:El árbol de carpetas no está sincronizado. Se refrescará automáticamente.`;
            if (error.status === 404) {
                message = messageOutdated;
                actionAfter = FolderPostOperationActions.REFRESH;
            }

            const { errorCode } = error.error;
            switch (errorCode as FolderResultErrorCode) {
                case FolderResultErrorCode.TREE_LOCKED:
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.TREE_OUTDATED:
                    message = messageOutdated;
                    actionAfter = FolderPostOperationActions.REFRESH;
                    break;

                case FolderResultErrorCode.NAME_ALREADY_USED:
                    message = $localize`:@@ClausulasNuevaCarpetaErrorMismoNombreAlGuardarMensaje:Ya existe otra carpeta con el mismo nombre. Introduce otro nombre, por favor.`;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.HAS_NOT_MODULE_PERMISSION:
                    message = $localize`:@@MoverCarpetaACarpetaErrorFaltaPermisos:No tienes permisos para modificar esta carpeta.`;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.HAS_NOT_PERMISSION_TO_MOVE:
                case FolderResultErrorCode.MOVING_INTO_SELF:
                    message = genericErrorMessage;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;
            }

            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => resolve(actionAfter));
        });
        return promiseError;
    }

    private doActionAfter(actionAfter: FolderPostOperationActions, nodeAction: NodeTreeAction) {
        switch (actionAfter) {
            case FolderPostOperationActions.NO_ACTION:
                this.sendTreeOperationCallbackFail(nodeAction);
                break;
            case FolderPostOperationActions.CANCEL:
            case FolderPostOperationActions.REFRESH:
                this.loadFolderTree();
                break;
        }
    }

    private folderSelected(nodeAction: NodeTreeAction) {
        const node = nodeAction.node;
        this.selectNode(node.id, node.value);
    }

    private selectNode(nodeId: string, nodeValue: string) {
        this.clausesFolderService.setCurrentFolderId(nodeId);
        this.clausesFolderService.setCurrentFolderName(nodeValue);
        this.sendIsValidState(nodeId);

        this.selectedNodeIdTree.emit(nodeId);
    }

    private sendIsValidState(nodeId: string) {
        this.isValidSubscription.next(this.clausesFolderService.isCurrentFolderValid(nodeId));
    }
}
