import { Editor, Writer, Element, Node } from "ckeditor5";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { LinkedFieldUtilsService } from "../../utils/linked-field/linked-field-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { BaseAddEditLinkedFieldCommand } from "./base-add-edit-linked-field-command";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { PluginElementType } from "../../../models/plugin-element-type.enum";

export default class AddLinkedFieldCommand extends BaseAddEditLinkedFieldCommand {

    private inputUtilsService: InputUtilsService;
    private pluginUtilsService: PluginUtilsService;
    private linkedFieldUtilsService: LinkedFieldUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.inputUtilsService = new InputUtilsService();
        this.pluginUtilsService = new PluginUtilsService();
        this.linkedFieldUtilsService = new LinkedFieldUtilsService();
    }

    public override execute(alias: string): void {
        const editor = this.editor;
        let id;

        editor.model.change((writer: Writer) => {
            const inputParent = this.inputUtilsService.getInputParentElementModelWithAlias(editor, alias);
            const inputParentType = inputParent.item.getAttribute('input-type').toString();

            if (!this.inputUtilsService.isTypeValid(inputParentType)) {
                return null;
            }

            const inputParentModel = this.linkedFieldUtilsService.getInputModelWithElement(inputParent.item);
            inputParentModel.id = '';
            const inputLinkedFieldModel = this.linkedFieldUtilsService.createLinkedFieldStructureModel(writer, inputParentType, inputParentModel);
            id = inputLinkedFieldModel.getAttribute(GlobalConstant.ATTRIBUTE_ID);
            editor.model.insertObject(inputLinkedFieldModel, null, null, {setSelection: 'after'});

            const previousElement = this.pluginUtilsService.findStyledElementBefore(inputLinkedFieldModel as Element, writer, PluginElementType.INLINE);
            const attributes = this.pluginUtilsService.getStyleAttributesFromPreviousElement(previousElement as Element);

            const childElement = inputLinkedFieldModel.childCount > 0 && (inputLinkedFieldModel.getChild(0) as Element).childCount > 0
                            ? ((inputLinkedFieldModel as Element).getChild(0) as Element).getChild(0)
                            : null;

            this.utilsService.setAttributes(attributes, inputLinkedFieldModel, writer);
            if(childElement) {
                this.utilsService.setAttributes(attributes, childElement as Element, writer);
            }
        } );

        if (id) {
            this.validateLinkedField(id);
        }
    }
}
