import { Observable } from 'rxjs';
import { ApiProcessorService, ApiRequest, ApiOperation } from '../api-processor.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TemplateDocumentRevisionService {

    public basePath = '/api/template-document-revision';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService) {
    }

    public markAsReviewed(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public templateDocumentCompareIdGet(documentId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/compare/${documentId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);

    }
}
