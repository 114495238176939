import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserInfoService, UserInfoDTO } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { TypeUserPermission } from 'src/app/core/shared/enums/type-user-permission.enum';

@Injectable({
    providedIn: 'root'
})

export class AuthorizeService {

    private userInfo: BehaviorSubject<UserInfoDTO>;
    private userInfoRefreshedOnce = new BehaviorSubject<boolean>(false);
    private userInfoRefreshing = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly userService: UserInfoService,
        private readonly permissionService: NgxPermissionsService
    ) {
        this.userInfo = new BehaviorSubject<UserInfoDTO>(undefined);
    }

    public HasPermission(permission: any, permissionType: TypeUserPermission): boolean {

        if (!permission) {
            return true;
        }

        if (!this.userInfo.getValue()) {
            return false;
        }

        const user = this.userInfo.getValue();
        let permissionsUserType = TypeUserPermission.ANY; // Default if none
        if (permissionType) {
            permissionsUserType = permissionType;
        }

        let permissionsToCheck = null;
        if (permission instanceof Array) {
            permissionsToCheck = permission;
        } else {
            permissionsToCheck = new Array(permission);
        }

        switch (permissionsUserType) {
            case TypeUserPermission.ANY:
                return user.permissions.some(per => permissionsToCheck.some(p => p === per));
            case TypeUserPermission.NONE:
                return !(user.permissions.some(per => permissionsToCheck.some(p => p === per)));
            case TypeUserPermission.ALL:
                return permissionsToCheck.every(p => user.permissions.some(per => p === per));
        }
    }


    public getUserInfo(): BehaviorSubject<UserInfoDTO> {
        return this.userInfo;
    }

    public getUserInfoRefreshedOnce(): BehaviorSubject<boolean> {
        return this.userInfoRefreshedOnce;
    }

    public setUserInfoRefreshing(value: boolean): void {
        return this.userInfoRefreshing.next(value);
    }

    public getUserInfoRefreshingValue(): boolean {
        return this.userInfoRefreshing.getValue();
    }

    public clearUserInfo() {
        if (this.userInfo.getValue() == undefined || this.userInfo.getValue()) {
            this.userInfo.next(null);
        }
    }

    public isAuthenticated(): boolean {
        return this.userInfo.value !== null && this.userInfo.value !== undefined;
    }

    public refreshUserInfo() {
        if (!this.isAuthenticated()) {
            this.forceRefreshUserInfo();
        }
    }

    public forceRefreshUserInfo() {
        this.userService.userInfoGet().subscribe((data) => {
            if (!environment.production) {
                console.log('Permisos del usuario:' + data.permissions);
            }

            this.permissionService.loadPermissions(data.permissions);
            this.userInfo.next(data);
            if (!this.userInfoRefreshedOnce.getValue()) {
                this.userInfoRefreshedOnce.next(true);
            }
        }, () => {
            this.clearUserInfo();
            if (!this.userInfoRefreshedOnce.getValue()) {
                this.userInfoRefreshedOnce.next(true);
            }
        });
    }
}
