/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CBDocumentDTO } from './cBDocumentDTO';
import { ClauseDto } from './clause-dto.model';
import { MasterData } from './masterData';

export interface DocumentClauseDTO { 
    id?: string;
    document?: CBDocumentDTO;
    documentStatuses?: MasterData;
    clause?: ClauseDto;
    reviewTimeStamp?: Date;
    lastVersionReview?: number;
    isTheDocumentATemplate?: boolean;
    isTheDocumentPendingSendToSign?: boolean;
}