import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LegalAlertsSuggestService } from 'src/app/api/api/standard/legal-alerts-suggested.service';
import { debounceTime, catchError, switchMap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuggestedLegalAlertDto } from 'src/app/api/model/standard/suggested-legal-alert-dto.model';

@Component({
  selector: 'app-ctbox-suggest',
  templateUrl: './ctbox-suggest-component.html',
  styleUrls: ['./ctbox-suggest.component.scss']
})
export class CtboxSuggestComponent {

    @Input() public parentGroup: UntypedFormGroup;

    @Output() hasSuggestions: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() suggestedLegalAlertSelected: EventEmitter<SuggestedLegalAlertDto> = new EventEmitter<SuggestedLegalAlertDto>();

    public suggestions: SuggestedLegalAlertDto[] = [];

    private readonly formControlName = 'inputSuggest';
    private controlAdded: boolean = false;

    private keyupSubject = new Subject<KeyboardEvent>();

    constructor(private suggestService: LegalAlertsSuggestService) {}

    public ngOnInit(): void {
        this.keyupSubject.pipe(
          debounceTime(environment.suggestRequestDebounceTime),
          switchMap((event: KeyboardEvent) => {
            const inputValue = (event.target as HTMLInputElement).value;
            return this.suggestService.getSuggestions(inputValue).pipe(
              catchError(_error => {
                this.updateSuggestions([]);
                return of([]);
              })
            );
          })
        ).subscribe(
          (data: SuggestedLegalAlertDto[]) => {
            this.updateSuggestions(data || []);
          }
        );
      }

    public ngAfterViewChecked(): void {
        if (!this.controlAdded && this.parentGroup && !this.parentGroup.get(this.formControlName)) {
            const inputSuggest = new UntypedFormControl('', [Validators.maxLength(100)]);
            this.parentGroup.addControl(this.formControlName, inputSuggest);
            this.controlAdded = true;
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        this.keyupSubject.next(event);
    }

    public onSuggestedLegalAlertClick(suggestedLegalAlertSelected: SuggestedLegalAlertDto): void {
        this.suggestedLegalAlertSelected.emit(suggestedLegalAlertSelected);
        this.suggestions = [];
        const formControl = this.parentGroup.get(this.formControlName);
        if (formControl) {
            formControl.setValue('');
        }
        this.hasSuggestions.emit(false);
    }

    public anySuggestion(): boolean {
        return this.suggestions.length > 0;
    }

    private updateSuggestions(newSuggestions: SuggestedLegalAlertDto[]): void {
        this.suggestions = newSuggestions;
        this.hasSuggestions.emit(this.suggestions.length > 0);
    }
}
