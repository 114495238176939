import { Editor, Writer } from "ckeditor5";
import { BaseInputModel } from "../../models/input/base-input-model";
import { InputDataViewUtilsService } from "../../utils/input/input-data-view-utils.service";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { InputBaseCommand } from "./input-base-command";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";

export default class AddInputCommand extends InputBaseCommand {

    private utilsService: PluginUtilsService;
    private inputUtilsService: InputUtilsService;
    private dataUtilsService: InputDataViewUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.inputUtilsService = new InputUtilsService();
        this.utilsService = new PluginUtilsService();
        this.dataUtilsService = new InputDataViewUtilsService();
    }

    public override execute(model: BaseInputModel): void {
        const editor = this.editor;

        editor.model.change((writer: Writer)  => {
            const inputType = model.type;

            if (!this.inputUtilsService.isTypeValid(inputType)) {
                return null;
            }

            const elementModel = this.dataUtilsService.createInputStructureModel(writer, inputType, model);

            const selection = editor.model.document.selection;

            const previousElement = this.utilsService.findPreviousElement(selection);

            if (previousElement) {
                const attributes = previousElement.getAttributes();
                this.utilsService.setAttributes(Array.from(attributes), elementModel, writer);
            }

            editor.model.insertObject(elementModel, null, null, {setSelection: 'after'});
        } );
    }
}
