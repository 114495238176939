import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ClauseDto } from 'src/app/api';

@Injectable({
  providedIn: 'root'
})

export abstract class IClauseCreationService {

    abstract saveOpenFolder(newClause: ClauseDto): MatDialogRef<any>;
    abstract afterClosed(result: any, newClause: ClauseDto): Promise<ClauseDto>;
}
