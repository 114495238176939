import { Injectable } from '@angular/core';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { DocumentClauseDTO, UserDTO } from 'src/app/api';
import { IClausesPreviewService } from './clauses-preview.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ClausesPreviewService implements IClausesPreviewService {

    public orderBy(data: DocumentClauseDTO, sortHeaderId: string): any {
        switch (sortHeaderId) {
            case 'icon':
                return this.getIconToOrder(data);
            case 'lastModificationUser':
                return this.getAuthorSurnameToOrder(data.document[sortHeaderId]);
            case 'name':
            case 'modificationTimeStamp':
                return data.document[sortHeaderId];
        }

        return data[sortHeaderId];
    }

    public orderListBy(data: DocumentClauseDTO[], sortHeaderId: string): DocumentClauseDTO[] {
        return data.sort((a, b) => this.orderBy(a, sortHeaderId) - this.orderBy(b, sortHeaderId));
    }

    private getIconToOrder(documentClause: DocumentClauseDTO): number {
        let iconValue = 0;

        if (documentClause === null) {
            return iconValue;
        }

        if (documentClause.document?.documentTypes?.code === DocumentTypeCodes.Template) {
            iconValue += 2;
        }

        if (documentClause.document?.documentTypes?.code !== DocumentTypeCodes.Template) {
            iconValue += 3;
        }

        return iconValue;
    }

    private getAuthorSurnameToOrder(user: UserDTO): string {
        if (user === null) {
            return ''.toLocaleLowerCase();
        }

        return user.surname.trim().toLocaleLowerCase();
    }
}
