import { Observable } from 'rxjs';
import { ApiProcessorService, ApiRequest, ApiOperation } from '../api-processor.service';
import { Injectable } from '@angular/core';
import { TemplateClauseRevisionDto } from '../../model/standard/template-clause-revision-dto.model';

@Injectable()
export class TemplateClauseRevisionService {

    public basePath = '/api/template-clause-revision';
    
    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) { }

    public applyChanges(templateClauseRevisionDto: TemplateClauseRevisionDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.body = templateClauseRevisionDto;
        apiRequest.endpointUrl = `${this.basePath}/apply-changes`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
