import { Injectable } from "@angular/core";
import { DecoupledEditor, DowncastWriter, Editor, ViewContainerElement, ViewElement, Element } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { SignaturePlugin } from "../../plugins/signature/signature-plugin";

@Injectable({
    providedIn: "root",
})

export class SignatureUtilsService {

    private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public getSelectedSignatureElement(editor: Editor): ViewContainerElement | null {
        return this.pluginUtils.getSelectedContainerWithClass(editor, SignaturePlugin.MODEL_ENTITIES.class.editionView);
    }

    public getMaxRoleNumber(editor: Editor, role: string): number {
        const data = (editor as DecoupledEditor).sourceElement;
        if (!data) {
            return 0;
        }

        const signatureElements = data.querySelectorAll(`.${SignaturePlugin.MODEL_ENTITIES.class.model}`);

        let maxNum = 0;

        Array.from(signatureElements).forEach(element => {
            const signatureTitle = element.getAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model);
            if (signatureTitle) {
                const parts = signatureTitle.split(' ');
                const roleValue = parts[0];
                const number = parts[1];
                const numValue = parseInt(number, 10);
                if (role.includes(roleValue) && numValue > maxNum) {
                    maxNum = numValue;
                }
            }
        });

        return maxNum;
    }

    public hasRoleInDocument(editor: Editor, role: string, excludeSignatureId?: string): boolean {
        const data = (editor as DecoupledEditor).sourceElement;
        const queryFindRoleDifferentFromCurrent = excludeSignatureId
            ? `[${SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView}="${role}"]:not([id="${excludeSignatureId}"])`
            : `[${SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView}="${role}"]`;


        return data?.querySelector(queryFindRoleDifferentFromCurrent) !== null;
    }

    public getRole(element: ViewContainerElement | null): string {
        return element?.getAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView) || "";
    }

    public getSignatureId(element: ViewContainerElement | null): string {
        return element?.getAttribute("id") ?? "";
    }

    public createSignatureView(editor: Editor, modelItem: Element, viewWriter: DowncastWriter): ViewContainerElement {
        const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID)?.toString();
        const role = modelItem.getAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView)?.toString();

        const signatureId = id?.trim() || `${SignaturePlugin.SIGNATURE_PREFIX}${this.getMaxRoleNumber(editor, role.trim()) + 1}`;
        const signatureRole = role?.trim() || `${SignaturePlugin.MODEL_ENTITIES.role.editionView}${this.getMaxRoleNumber(editor, role.trim()) + 1}`;

        const element = viewWriter.createContainerElement(
            "div",
            {
                id: signatureId,
                [SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView]: signatureRole,
                [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: GlobalConstant.UNDEFINED,
            },
            [
                this.addSignatureTemplateContent(signatureRole, viewWriter),
            ]
        );

        viewWriter.addClass([SignaturePlugin.MODEL_ENTITIES.class.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW],element);
        return element;
    }

    public isSignature(viewItem: ViewElement): boolean {
        return viewItem.hasClass(SignaturePlugin.MODEL_ENTITIES.class.model);
    }

    private addSignatureTemplateContent(signatureRole: string, viewWriter: DowncastWriter): ViewContainerElement {
        const signatureRoleText = "[" + signatureRole + "]";
        const tdElement = viewWriter.createContainerElement(
            "td",
            { class: SignaturePlugin.MODEL_ENTITIES.signatureSignPlacement.editionView },

            [viewWriter.createText(signatureRoleText)]
        );

        const tdFooterElement = viewWriter.createContainerElement(
            "td",
            { class: SignaturePlugin.MODEL_ENTITIES.signatureSignRol.editionView },

            [viewWriter.createText(signatureRole)]
        );

        const contentElement = viewWriter.createContainerElement(
            "div",
            { class:SignaturePlugin.MODEL_ENTITIES.signatureContent.editionView },
            [
                viewWriter.createContainerElement("table", null, [
                    viewWriter.createContainerElement("tbody", null, [
                        viewWriter.createContainerElement("tr", null, [
                            tdElement,
                        ]),
                    ]),
                    viewWriter.createContainerElement("trfoot", null, [
                        tdFooterElement,
                    ]),
                ]),
            ]
        );

        return contentElement;
    }
}
