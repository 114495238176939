import { Directive, OnDestroy } from "@angular/core";
import { ContextualBalloon, Editor, ViewDocumentClickEvent, ViewElement, View, EventInfo, DowncastWriter, ViewDocumentKeyDownEvent, Node } from "ckeditor5";
import { RadioUtilsService } from '../../utils/radio/radio-utils.service';
import AddRadioCommand from '../../commands/radio/add-radio-command';
import DeleteRadioCommand from '../../commands/radio/delete-radio-command';
import { AddRadioFormValidatorCallback } from "../../ui/radio/add-radio-dialog-form-view.directive";
import AddRadioDialogFormView from "../../ui/radio/add-radio-dialog-form-view.directive";
import EditRadioDialogFormView, { EditRadioFormValidatorCallback } from "../../ui/radio/edit-radio-dialog-form-view.directive";
import { RadioModel } from "../../models/radio/radio-model";
import AddOptionsCommand from "../../commands/radio/add-options-command";
import RemoveOptionsCommand from "../../commands/radio/remove-options-command";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import RadioBalloonView from "../../ui/radio/radio-balloon-view.directive";
import { RadioModelToEditorViewConverterService } from "../../converters/radio/radio-model-to-editor-view-converter.service";
import { RadioModelToDataViewConverterService } from "../../converters/radio/radio-model-to-data-view-converter.service";
import { RadioDataViewToModelConverterService } from "../../converters/radio/radio-data-view-to-model-converter.service";
import { BasePlugin } from "../base/base-plugin";
import { RadioSchemaService } from "../../schema/radio/radio-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { CheckInputToDeleteKeyCode } from "../../models/base/keycode";
import { RadioDataViewUtilsService } from "../../utils/radio/radio-data-view-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { ContextEditorTypes } from "../../models/base/context-editor-types";
import ContainerElement from "@ckeditor/ckeditor5-engine/src/view/containerelement";

@Directive({
    selector: 'radio-plugin',
})
export class RadioPlugin extends BasePlugin implements OnDestroy {

    public static readonly MAX_RADIOS = 20;
    public static readonly PLUGIN_NAME = 'Radio';
    public static readonly ADD_RADIO_COMMAND_NAME = 'add-radio-pg';
    public static readonly DELETE_RADIO_COMMAND_NAME = 'delete-radio-pg';
    public static readonly ADD_RADIO_OPTION_COMMAND_NAME = 'add-options-radio-pg';
    public static readonly REMOVE_RADIO_OPTION_COMMAND_NAME = 'remove-options-radio-pg';
    public static readonly ID_RADIO_PREFIX = 'ck-radio-id-';

    public static readonly MODEL_ENTITIES = {
        'container':        {   model: 'radio-pg',                      dataView: 'rGroup',                 editionView: 'radio-pg'                     },
        'optionCount':      {   model: 'optionsCount',                  dataView: 'data-options-count',     editionView: 'data-options-count'           },
        'option':           {   model: 'radio-pg-option',               dataView: 'rBox',                   editionView: 'rBox'                         },
        'optionPosition':   {   model: 'position',                      dataView: 'data-position',          editionView: 'data-position'                },
        'input':            {   model: 'radio-pg-check',                dataView: 'irBox',                  editionView: 'radio-pg-check'               },
        'groupName':        {   model: 'dataName',                      dataView: 'name',                   editionView: 'name'                         },
        'description':      {   model: 'radio-pg-option-description',   dataView: 'rhDesc',                 editionView: 'radio-pg-option-description'  },
        'content':          {   model: 'radio-pg-option-content',       dataView: '',                       editionView: 'radio-pg-option-content'      },
        'checked':          {   model: 'checked',                       dataView: 'checked',                editionView: 'checked'                      },
    };

    public static readonly CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW = 'AN';
    public static readonly OPTION_SELECTED_ADDITIONAL_CLASS_DATA_VIEW = 'sel';
    public static readonly VISUAL_SELECTION_MARKER_NAME = 'radio-pg';

    protected mappers = [
        RadioPlugin.MODEL_ENTITIES.container.editionView,
        RadioPlugin.MODEL_ENTITIES.option.editionView,
        RadioPlugin.MODEL_ENTITIES.input.editionView,
        RadioPlugin.MODEL_ENTITIES.description.editionView,
        RadioPlugin.MODEL_ENTITIES.content.editionView
    ];

    protected commands = {
        [RadioPlugin.ADD_RADIO_COMMAND_NAME]: AddRadioCommand,
        [RadioPlugin.DELETE_RADIO_COMMAND_NAME]: DeleteRadioCommand,
        [RadioPlugin.ADD_RADIO_OPTION_COMMAND_NAME]: AddOptionsCommand,
        [RadioPlugin.REMOVE_RADIO_OPTION_COMMAND_NAME]: RemoveOptionsCommand
    };

    protected readonly insertOptionsMessage = $localize`:@@InsertarGrupoOpcionesRadioPlugin:Insertar grupo de opciones`;

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.RADIO_TOOLBAR_ICON_SVG,
        pluginToolbarElementName: RadioPlugin.pluginToolbarElementName,
        tooltip: this.insertOptionsMessage,
        hasTooltip: true,
        hasText: true
    };

    private addRadioDialogFormView: AddRadioDialogFormView;
    private radioBalloonFormView: RadioBalloonView;
    private editRadioBalloonFormView: EditRadioDialogFormView;

    private utilsService: RadioUtilsService;
    private radioSchemaService: RadioSchemaService;
    private userInterfaceService: UserInterfaceService;
    private dataViewToModelConverter: RadioDataViewToModelConverterService;
    private modelToDataViewConverter: RadioModelToDataViewConverterService;
    private modelToEditorViewConverter: RadioModelToEditorViewConverterService;
    private dataUtilsService: RadioDataViewUtilsService;

    private readonly optionsGroupMessage = $localize`:@@GrupoOpcionesRadioPlugin:Grupo de opciones`;
    private readonly minOptionsMessage = $localize`:@@MinimasOpcionesRadioPlugin:Debe tener al menos 2 opciones.`;
    private readonly maxOptionsMessage = $localize`:@@MaximasOpcionesRadioPlugin:Debe tener 10 opciones como máximo.`;

    constructor(editor: Editor) {
        super(editor);

        this.utilsService = new RadioUtilsService();
        this.dataViewToModelConverter = new RadioDataViewToModelConverterService();
        this.modelToDataViewConverter = new RadioModelToDataViewConverterService();
        this.modelToEditorViewConverter = new RadioModelToEditorViewConverterService();
        this.radioSchemaService = new RadioSchemaService();
        this.userInterfaceService = new UserInterfaceService();
        this.dataUtilsService = new RadioDataViewUtilsService();
    }

    public static get pluginName() {
        return RadioPlugin.PLUGIN_NAME;
    }

    public static get pluginToolbarElementName() {
        return RadioPlugin.MODEL_ENTITIES.container.model;
    }

    public static get toolbarButtonName() {
        return RadioPlugin.MODEL_ENTITIES.container.model;
    }

    protected defineSchema(): void {
        const schema = this.editor.model.schema;
        this.radioSchemaService.defineSchema(schema);
    }

    protected defineConverters(): void {
        this.modelToEditorViewConverter.configureConverters(this.conversion);
        this.dataViewToModelConverter.configureConverters(this.conversion);
        this.modelToDataViewConverter.configureConverters(this.conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(DoubleClickObserver);
        this.disableRemoveOptionWhenDeletingContent();
        this.disableCopyContent();
        this.enableBalloonActivators();
        this.setupInteractions();
        this.disableToolbarButton(RadioPlugin.ADD_RADIO_COMMAND_NAME);
        this.editor.plugins.get('ClipboardPipeline').on('inputTransformation', this.handlePasteEvent.bind(this), { priority: 'high' });
    }

    protected override toolbarExecuteOperation(): void {
        const dialog = this.editor.plugins.get("Dialog");

        if (this.button.isOn) {
            dialog.hide();
            return;
        }
        const context = this;

        this.button.isOn = true;

        this.addRadioDialogFormView = this.createAddRadioFormView();

        const saveButtonView = this.addRadioDialogFormView.saveButtonView;
        const cancelButtonView = this.addRadioDialogFormView.cancelButtonView;

        dialog.show({
            isModal: true,
            content: this.addRadioDialogFormView,
            title: this.optionsGroupMessage,
            onHide() {
                context.button.isOn = false;
            },
            id: "",
            actionButtons: [
                {
                    label: cancelButtonView.label,
                    class: cancelButtonView.class,
                    withText: cancelButtonView.withText,
                    onExecute: () => this.cancelHandlerFunction()
                },
                {
                    label: saveButtonView.label,
                    class: saveButtonView.class,
                    withText: saveButtonView.withText,
                    onExecute: () => this.submitAddHandlerFunction()
                }
            ]
        });
    }

    protected enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', this.handleDobleClickEvent.bind(this));
    }

    protected override getPluginDataViewClass(): string {
        return RadioPlugin.MODEL_ENTITIES.container.dataView;
    }

    protected override getPluginModelName(): string {
        return RadioPlugin.MODEL_ENTITIES.container.model;
    }

    protected override processElementsPaste(elements: Node[]): void {
        const view = this.editor.editing.view;

        view.change(writer => {
            elements.forEach(element => {
                if ((element instanceof ViewElement) && this.dataUtilsService.isRadio(element)) {
                    const newId = this.pluginUtils.generateId(RadioPlugin.ID_RADIO_PREFIX);
                    writer.setAttribute(GlobalConstant.ATTRIBUTE_ID, newId, element);
                }
            });
        });
    }

    protected override setupModelPostFixing(): void {
        const model = this.editor.model;
        const useGrandparent = true;
        model.document.registerPostFixer(writer => this.contentFirstTypeShouldInheritStylesPostFixer(model, writer, RadioPlugin.MODEL_ENTITIES.content.model, useGrandparent));
    }

    private setupInteractions(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleRadioInputClickEvent.bind(this), { priority: "high" });
        this.listenTo<ViewDocumentKeyDownEvent>(viewDocument, 'keydown', this.handleKeyDownEvent.bind(this), { priority: 'highest' });
    }

    private handleKeyDownEvent(evt, data): void {
        const element = evt?.source?.selection?.getSelectedElement();
        if (!this.isSelectedOnlyRadioCheckElement(element)) {
            return;
        }

        this.finishEventPropagation(data, evt);
    }

    private isSelectedOnlyRadioCheckElement(element: ViewElement): boolean {
        return element && element.name === 'img' && (element as ContainerElement)?.hasClass(RadioPlugin.MODEL_ENTITIES.input.editionView);
    }

    private handleDobleClickEvent(evt, data): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const parentRadius = this.utilsService.getSelectedRadiusElement(this.editor);

        if (!parentRadius) {
            return;
        }

        if (!this.pluginUtils.isViewElementInteractableInTargetEditor(parentRadius, RadioPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        const idRadius = this.utilsService.getId(parentRadius);
        const currentOptionCount = parseInt(parentRadius?.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.editionView)!);

        this.showEditRadioBalloon(idRadius, currentOptionCount);
        this.finishEventPropagation(data, evt);
    }

    private handleClickEvent(): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const parentRadius = this.utilsService.getSelectedRadiusElement(this.editor);

        if (!parentRadius) {
            return;
        }

        if (!this.pluginUtils.isViewElementInteractableInTargetEditor(parentRadius, RadioPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        const idRadius = this.utilsService.getId(parentRadius);
        const currentOptionCount = parseInt(parentRadius?.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.editionView)!);

        this.showRadioBalloon(idRadius, currentOptionCount);
    }

    private handleRadioInputClickEvent(evt: EventInfo, data: any): void {
        if (this.isReadOnlyOrCommentsOnly()) {
            return;
        }

        const model = this.editor.model;
        const selectedElement = model.document.selection.getSelectedElement();

        if (!selectedElement || selectedElement.name !== RadioPlugin.MODEL_ENTITIES.input.model) {
            return;
        }

        const editingView = this.editor.editing.view;

        const selectedElementEditView = this.pluginUtils.getSelectedElementWithClass(this.editor, RadioPlugin.MODEL_ENTITIES.input.editionView);

        editingView.change((writer: DowncastWriter) => {
            const isChecked = selectedElementEditView?.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.editionView);

            if (isChecked) {
                return;
            }

            writer.setAttribute('checked', true, selectedElementEditView!);
            writer.setAttribute('src', "assets/images/radio-button-checked.png", selectedElementEditView!);

            const parentRadio = selectedElementEditView!.parent?.parent;

            if (parentRadio) {
                const allInputTypeRadio = Array.from(parentRadio.getChildren());

                allInputTypeRadio.forEach(child => {
                    if (child.is('view:element')) {
                        if (child.is('view:element') && (child as ViewElement).hasClass(RadioPlugin.MODEL_ENTITIES.option.editionView)) {
                            const radio = (child as ViewElement).getChild(0);

                            if (radio && radio !== selectedElementEditView) {
                                writer.removeAttribute(RadioPlugin.MODEL_ENTITIES.checked.editionView, radio as ViewElement);
                                writer.setAttribute('src', "assets/images/radio-button.png", radio as ViewElement);
                            }
                        }
                    }
                });
            }

        });

        model.change(writer => {
            writer.setAttribute('checked', true, selectedElement);
            const parentRadio = selectedElement.parent?.parent;

            if (parentRadio) {
                const allInputTypeRadio = Array.from(parentRadio.getChildren());

                allInputTypeRadio.forEach(child => {
                    if (child.is('element') && child.name === RadioPlugin.MODEL_ENTITIES.option.model) {
                        const radio = child.getChild(0);

                        if (radio && radio !== selectedElement) {
                            writer.setAttribute('checked', false, radio);
                        }
                    }
                });
            }
        });

    }

    private submitAddHandlerFunction(): void {
        const dialog = this.editor.plugins.get("Dialog");
        const optionsCount = this.addRadioDialogFormView.optionsCountInputView;

        if (!this.addRadioDialogFormView.isValid()) {
            return;
        }

        optionsCount.errorText = '';

        this.editor.execute(RadioPlugin.ADD_RADIO_COMMAND_NAME, this.addRadioDialogFormView.optionsCount);
        dialog.hide();
    }

    private submitEditHandlerFunction(): void {
        const dialog = this.editor.plugins.get("Dialog");

        const optionCountFieldView = this.editRadioBalloonFormView.optionsCountInputView;
        const optionCountInForm = this.editRadioBalloonFormView.optionsCount;
        const initialOptionsCount = this.editRadioBalloonFormView.initialOptionsCount;
        const id = this.editRadioBalloonFormView.id;

        if (optionCountInForm !== 0 && (!this.editRadioBalloonFormView.isValid() || !optionCountInForm)) {
            return;
        }

        if (initialOptionsCount === optionCountInForm) {
            this.hideUI();
            dialog.hide();
            return;
        }

        optionCountFieldView.errorText = '';

        const radioModel: RadioModel = {
            id,
            optionsCount: initialOptionsCount!,
        };

        if (initialOptionsCount! > optionCountInForm) {
            this.editor.execute(RadioPlugin.REMOVE_RADIO_OPTION_COMMAND_NAME, radioModel, optionCountInForm);
        } else {
            this.editor.execute(RadioPlugin.ADD_RADIO_OPTION_COMMAND_NAME, radioModel, optionCountInForm);
        }

        this.hideUI();
        dialog.hide();
    }

    private cancelHandlerFunction(): void {
        const dialog = this.editor.plugins.get("Dialog");
        this.hideUI();
        dialog.hide();
    }

    private disableRemoveOptionWhenDeletingContent(): void {
        const viewDocument = this.editor.editing.view.document;

        viewDocument.on('keydown', (event, data) => {
            const isAnyRemoveKeyPressed = data.keyCode === CheckInputToDeleteKeyCode.BACKSPACE || data.keyCode === CheckInputToDeleteKeyCode.DELETE;

            if (!isAnyRemoveKeyPressed) {
                return;
            }

            const selection = this.editor.model.document.selection;
            const firstPosition = selection.getFirstPosition();

            const blockElement = firstPosition!.parent;

            const parentBlockElement = blockElement.parent;

            const tryingDeleteBackspaceBegginingDescriptionOrContent = data.keyCode === CheckInputToDeleteKeyCode.BACKSPACE &&
                !!parentBlockElement &&
                ((!!parentBlockElement &&
                    (parentBlockElement!.name === RadioPlugin.MODEL_ENTITIES.description.editionView ||
                        parentBlockElement!.name === RadioPlugin.MODEL_ENTITIES.content.editionView) &&
                    firstPosition!.isAtStart));

            const tryingRemoveKeysWhenOneOptionSelected = blockElement.name === RadioPlugin.MODEL_ENTITIES.option.model;
            if (tryingDeleteBackspaceBegginingDescriptionOrContent || tryingRemoveKeysWhenOneOptionSelected) {
                this.finishEventPropagation(data, event);
            }
        }, {
            priority: 'high'
        });
    }

    private disableCopyContent(): void {
        const viewDocument = this.editor.editing.view.document;

        viewDocument.on('copy', (event, data) => {
            const selection = data.domTarget;

            const optionTryToCopy = selection?.classList?.contains(RadioPlugin.MODEL_ENTITIES.input.editionView);

            if (!optionTryToCopy) {
                return;
            }

            this.finishEventPropagation(data, event);
        });
    }

    private createAddRadioFormView(defaultValue?: number): AddRadioDialogFormView {
        const editor = this.editor;
        const validators = this.getAddFormValidators();
        const formView = new AddRadioDialogFormView(validators, editor.locale, defaultValue);

        return formView;
    }

    private getAddFormValidators(): Array<AddRadioFormValidatorCallback> {
        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber < 2) {
                    return this.minOptionsMessage;
                }

                if (optionCountNumber > RadioPlugin.MAX_RADIOS) {
                    return this.maxOptionsMessage;
                }

                return '';
            }
        ];
    }

    private addRadioBallonFormView(radioId?: string, optionsCount?: number): void {
        if (!this.radioBalloonFormView) {
            this.createRadioBalloonView(radioId, optionsCount);
        }

        if (this.isViewInPanel(this.radioBalloonFormView, this.balloon)) {
            const isSameRadiusEditing = this.radioBalloonFormView.id === radioId;
            if (!isSameRadiusEditing) {
                this.radioBalloonFormView.id = radioId!;
                this.radioBalloonFormView.totalOptions = optionsCount!;
            }
            return;
        }

        this.radioBalloonFormView.id = radioId!;
        this.radioBalloonFormView.totalOptions = optionsCount!;

        this.balloon.add({
            view: this.radioBalloonFormView!,
            position: this.getBalloonPositionData()
        });
    }

    private createRadioBalloonView(radioId?: string, optionsCount?: number): void {
        const editor = this.editor;
        this.radioBalloonFormView = new RadioBalloonView(editor.locale, radioId, optionsCount);

        this.radioBalloonFormView.editOptionsButtonView.on('execute', () => {
            this.showEditRadioBalloon(this.radioBalloonFormView.id, this.radioBalloonFormView.totalOptions);
        });

        this.radioBalloonFormView.deleteButtonView.on('execute', () => {
            editor.execute(RadioPlugin.DELETE_RADIO_COMMAND_NAME, this.radioBalloonFormView.id, this.radioBalloonFormView.totalOptions);
            this.hideUI();
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.radioBalloonFormView, RadioPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private addEditRadioBalloonFormView(radioId?: string, optionsCount?: number): void {
        if (!this.editRadioBalloonFormView) {
            this.createEditRadioBalloonView(radioId, optionsCount);
        }

        if (this.isViewInPanel(this.editRadioBalloonFormView, this.balloon)) {
            const isSameRadiusEditing = this.editRadioBalloonFormView.id !== radioId;
            if (isSameRadiusEditing) {
                this.editRadioBalloonFormView!.resetFormStatus();
                this.editRadioBalloonFormView.id = radioId!;
                this.editRadioBalloonFormView.optionsCount = optionsCount!;
            }
            return;
        }

        this.editRadioBalloonFormView!.resetFormStatus();
        this.editRadioBalloonFormView.id = radioId!;
        this.editRadioBalloonFormView.optionsCount = optionsCount!;

        const saveButtonView = this.editRadioBalloonFormView.saveButtonView;
        const cancelButtonView = this.editRadioBalloonFormView.cancelButtonView;

        const dialog = this.editor.plugins.get("Dialog");

        dialog.show({
            isModal: true,
            content: this.editRadioBalloonFormView,
            title: this.optionsGroupMessage,
            onHide() { },
            id: "",
            actionButtons: [
                {
                    label: cancelButtonView.label,
                    class: cancelButtonView.class,
                    withText: cancelButtonView.withText,
                    onExecute: () => this.cancelHandlerFunction()
                },
                {
                    label: saveButtonView.label,
                    class: saveButtonView.class,
                    withText: saveButtonView.withText,
                    onExecute: () => this.submitEditHandlerFunction()
                }
            ]
        });
    }

    private createEditRadioBalloonView(radioId?: string, optionsCount?: number): void {
        this.editRadioBalloonFormView = this.getEditRadioBalloonFormView(radioId, optionsCount);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.editRadioBalloonFormView, RadioPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private getEditRadioBalloonFormView(radioId?: string, optionsCount?: number): EditRadioDialogFormView {
        const editor = this.editor;
        const validators = this.getEditRadioBalloonFormValidators();
        const formView = new EditRadioDialogFormView(validators, editor.locale, radioId, optionsCount);
        return formView;
    }

    private getEditRadioBalloonFormValidators(): Array<EditRadioFormValidatorCallback> {
        return [
            form => {
                const optionCountNumber = Number(form.optionsCount);
                if (optionCountNumber == 1) {
                    return this.minOptionsMessage;
                }

                if (optionCountNumber > RadioPlugin.MAX_RADIOS) {
                    return this.maxOptionsMessage;
                }

                return '';
            }
        ];
    }

    private isUIInPanel(): boolean {
        return this.isViewInPanel(this.editRadioBalloonFormView, this.balloon) ||
            this.isViewInPanel(this.addRadioDialogFormView, this.balloon) ||
            this.isViewInPanel(this.radioBalloonFormView, this.balloon) ||
            this.areActionsVisible();
    }

    private showRadioBalloon(currentId?: string, currentOptionsCount?: number, forceVisible: boolean = false): void {
        if (!this.radioBalloonFormView) {
            this.addRadioBallonFormView(currentId, currentOptionsCount);
        }

        const radiusElement = this.utilsService.getSelectedRadiusElement(this.editor);
        if (!radiusElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, RadioPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addRadioBallonFormView(currentId, currentOptionsCount);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private showEditRadioBalloon(currentId?: string, currentOptionsCount?: number, forceVisible: boolean = false): void {
        this.removeBalloonView(this.radioBalloonFormView);

        if (!this.editRadioBalloonFormView) {
            this.addEditRadioBalloonFormView(currentId, currentOptionsCount);
        }

        const radiusElement = this.utilsService.getSelectedRadiusElement(this.editor);
        if (!radiusElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, RadioPlugin.VISUAL_SELECTION_MARKER_NAME);
        }
        this.addEditRadioBalloonFormView(currentId, currentOptionsCount);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedRadio = this.utilsService.getSelectedRadiusElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedRadio = this.utilsService.getSelectedRadiusElement(this.editor);
            const selectionParent = getSelectionParent();

            if ((prevSelectedRadio && !selectedRadio) ||
                (!prevSelectedRadio && selectionParent !== prevSelectionParent)) {
                this.hideUI();
            }

            else if (this.areActionsVisible()) {
                this.balloon.updatePosition(this.getBalloonPositionData());
            }

            prevSelectedRadio = selectedRadio;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find((node): node is ViewElement => node.is('element'));
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private hideUI(): void {
        if (!this.isUIInPanel) {
            return;
        }

        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        this.editor.editing.view.focus();

        this.removeDialogView(this.addRadioDialogFormView);
        this.removeBalloonView(this.radioBalloonFormView);
        this.removeDialogView(this.editRadioBalloonFormView);

        this.hideFakeVisualSelection();
    }

    private removeDialogView(formView: AddRadioDialogFormView | EditRadioDialogFormView): void {
        if (!this.isViewInPanel(formView, this.balloon)) {
            return;
        }

        formView!.resetFormStatus();
        this.balloon.remove(formView!);
        this.editor.editing.view.focus();
        this.hideFakeVisualSelection();
    }

    private removeBalloonView(formView: RadioBalloonView): void {
        if (!this.isViewInPanel(formView, this.balloon)) {
            return;
        }

        this.balloon.remove(formView!);
        this.editor.editing.view.focus();
        this.hideFakeVisualSelection();
    }

    private hideFakeVisualSelection(): void {
        this.pluginUtils.hideFakeVisualSelection(this.editor, RadioPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private getBalloonPositionData() {
        return this.pluginUtils.getBalloonPositionData(this.editor, RadioPlugin.MODEL_ENTITIES.container.editionView, RadioPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private areActionsVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.editRadioBalloonFormView) ||
            this.userInterfaceService.areActionsVisible(this.balloon, this.radioBalloonFormView);
    }

    private isViewInPanel(view: View, balloon: ContextualBalloon): boolean {
        return this.userInterfaceService.isBalloonInPanel(balloon, view);
    }
}
