import { ITreeStandardService } from 'src/app/shared/components/ctbox-tree/service/tree-standard-service.interface';
import { NodeTreeAction } from './models/node-tree-action.model';
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { FileFlatNode } from './models/file-flat-node.model';
import { FileNode } from './models/file-node.model';
import { NodeTreeActionType } from './enums/node-tree-action-type.enum';
import { MenuItem } from '../ctbox-menu/models/menu-item.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CtboxValidateNameComponent } from '../ctbox-validate-name/ctbox-validate-name.component';
import { NodeTreeNodeType } from './enums/node-tree-node-type.enum';
import { NodeTreeUserAction } from './enums/node-tree-user-action.enum';
import { ForceSynchronize } from './models/forceSynchronize.model';

@Component({
    selector: 'ctbox-tree',
    templateUrl: 'ctbox-tree.component.html'
})

export class CtboxTreeComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() dataTree: FileNode[];
    @Input() expansionModel: SelectionModel<string> = new SelectionModel<string>();
    @Input() actions: NodeTreeUserAction[];
    @Input() isTreeLocked: boolean;
    @Input() currentNodeId: string;
    @Input() expandByDefault: boolean;
    @Input() checkAllDescendants = false;
    @Input() customClass: string;
    @Input() initialNodes: string[] = [];
    @Input() set forceSynchoniceExpansionModel(value: ForceSynchronize) {
        if (!value?.force) {
            return;
        }

        this.synchroniceExpandModel(value.keepOldExpanded);
    }

    @Output() onNodeAction = new EventEmitter<NodeTreeAction>(null);

    @ViewChild('nodeName') nodeName: CtboxValidateNameComponent;
    @HostBinding('className') componentClass: string;

    public treeForm: UntypedFormGroup;
    public canDropFromOutside: boolean;
    public labelToMarkIsDroppable: string;
    public attributeToReadInDropElement: string;

    public treeControl: FlatTreeControl<FileFlatNode>;
    public dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;
    public eventEdit: Subject<string> = new Subject<string>();
    public nodeActionRefresh$: Subject<void> = new Subject<void>();

    private treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;
    private flatNodeMap = new Map<FileFlatNode, FileNode>();
    private nestedNodeMap = new Map<FileNode, FileFlatNode>();
    private currentNodeSelected: FileFlatNode;
    private creationLock = false;
    private editingName = false;
    private loadFirstTime = true;

    constructor(private treeService: ITreeStandardService) {
        this.componentClass = 'ctbox-tree';
        this.labelToMarkIsDroppable = 'candragintofolders';
        this.attributeToReadInDropElement = 'id';
        this.canDropFromOutside = true;

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<FileFlatNode>(this.getLevel, this.isExpandable);

        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    public ngOnInit() {
        this.initFormModel();
    }

    public ngAfterViewInit() {
        const nodeToFocus = document.querySelector(`mat-tree-node[data-nodeid="${this.currentNodeId}"]`);
        if (nodeToFocus !== null) {
            nodeToFocus.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataTree) {
            this.rebuildTreeForData(this.dataTree);
            this.rechargeCurrentNode();
            this.updateNodeActions();
        }

        if (changes.currentNodeId){
            this.changeCurrentNode(this.currentNodeId);
        }
    }
    private updateNodeActions(): void {
        this.nodeActionRefresh$?.next();
    }

    public hasChild = (_: number, nodeData: FileFlatNode) => nodeData.expandable;
    public hasNoContent = (_: number, nodeData: FileFlatNode) => nodeData.value === '';

    /* Handle the drop - here we rearrange the data based on the drop event, then rebuild the tree. */
    public drop(event: CdkDragDrop<string[]>) {
        // ignore drops outside of the tree
        if (!event.isPointerOverContainer) {
            return;
        }

        let indexOriginalDestinyNode = event.currentIndex < event.previousIndex ? event.currentIndex - 1 : event.currentIndex;
        indexOriginalDestinyNode = indexOriginalDestinyNode < 0 ? 0 : indexOriginalDestinyNode;
        const destinationFlatNode  =  event.container.getSortedItems()[indexOriginalDestinyNode].data as FileFlatNode;
        const nodeAtDest = this.flatNodeMap.get(destinationFlatNode);

        if (!destinationFlatNode || !this.hasPermission(destinationFlatNode, NodeTreeActionType.MoveTo)) {
            return;
        }

        // remove the node from its old place
        const nodeMoved = event.item.data;
        const nodeMovedTo = nodeAtDest;

        const nodeMovedFileNode = this.flatNodeMap.get(nodeMoved);

        if (this.isNodeADescendantOfAnotherNode(nodeAtDest, nodeMovedFileNode)) {
            return;
        }

        const additionalParams = this.treeService.createAdditionalParamWithDestinationNode(nodeMovedTo);
        const nodeAction = this.treeService.createNodeTreeActionWithAdditionalParam(nodeMoved, NodeTreeActionType.Move, additionalParams);

        this.onNodeAction.emit(nodeAction);
    }

    public isDragDisabled(node: FileFlatNode) {
        return !this.hasPermission(node, NodeTreeActionType.Move) || this.editingName || this.isTreeLocked;
    }

    public expandFolder(node: FileFlatNode) {
        if (!node.expandable) {
            return;
        }

        this.treeControl.toggle(node);
        this.expansionModel.toggle(node.id);
    }

    public doNodeAction(action: NodeTreeAction) {
        if (action.typeEvent === NodeTreeActionType.RenameStart) {
            this.editingName = true;
            return;
        } else {
            this.editingName = false;
        }

        if (action.typeEvent === NodeTreeActionType.Selected) {
            if (this.currentNodeSelected) {
                this.currentNodeSelected.highlightSelected = false;
            }
            action = this.treeService.createNodeTreeActionWithCallbacks(action.node,
                NodeTreeActionType.Selected, null, this.doWhenSelectSuccessful, null);
        }

        if (action.typeEvent === NodeTreeActionType.Checked) {
            this.afterCheckUncheckNodes(action);
        }

        this.onNodeAction.emit(action);
    }

    public addNewEmptyNode(node: FileFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        const emptyNode: FileNode = {
            id: '',
            value: '',
            parentId: parentNode.id,
            children: [] = [],
            type: NodeTreeNodeType.Folder,
            isChecked: true,
            actions: parentNode.actions,
            additionalData: parentNode.additionalData
        };
        this.treeForm.controls.name.setValue(emptyNode.value);
        this.treeForm.controls.name.markAsTouched();
        parentNode.children.push(emptyNode);
        this.rebuildTreeForData(this.dataTree);
        this.treeControl.expand(node);
        setTimeout(() => this.nodeName.focusInput()); // Esto es para que se ponga el foco en el input una vez ha renderizado el nodo
    }

    public updateNode(node: FileFlatNode) {
        if (this.creationLock || this.treeForm.invalid) {
            return; // Para evitar rebotes que solo ocurren en Firefox al desfocalizar y dar intro
        }

        this.creationLock = true;
        const additionalParam = null;
        node.value = this.treeForm.controls.name.value;
        const nodeTreeAction = this.treeService.createNodeTreeActionWithCallbacks(node,
            NodeTreeActionType.Create, additionalParam, this.doWhenCreateSuccessful, this.doWhenCreateFail);
        this.onNodeAction.emit(nodeTreeAction);
    }

    public deleteNewEmptyNode(node: FileFlatNode) {
        this.deleteNodeFromTree(node, '');
        this.treeForm.controls.name.setValue('');
        this.rebuildTreeForData(this.dataTree);
    }

    public hasActions(node: FileFlatNode): boolean {
        return node.permissions.length > 0;
    }

    public defineMenuList(node: FileFlatNode): MenuItem[] {
        const menuList: MenuItem[] = [];

        if (this.hasNodePermission(node, NodeTreeActionType.Create)) {
            const itemAdd: MenuItem = {
                name: $localize`:@@NuevaCarpetaMenuArbol:Nueva Carpeta`,
                action: () => this.addNewEmptyNode(node)
            };
            menuList.push(itemAdd);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.Rename)) {
            const itemRename: MenuItem = {
                name: $localize`:@@RenombrarCarpetaMenuArbol:Renombrar Carpeta`,
                action: () => this.renameNode(node)
            };

            menuList.push(itemRename);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.Download)) {
            const itemDownloadFolder: MenuItem = {
                name: $localize`:@@ExportarCarpeta:Exportar Carpeta`,
                action: () => this.createNodeAction(node, NodeTreeActionType.Download)
            };
            menuList.push(itemDownloadFolder);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.DownloadSelected)) {
            const itemDownloadSelectedItems: MenuItem = {
                name: $localize`:@@ExportarSeleccionados:Exportar Seleccionados`,
                action: () => this.createNodeAction(node, NodeTreeActionType.DownloadSelected)
            };
            menuList.push(itemDownloadSelectedItems);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.DownloadSelectedUpdate)) {
            const itemDownloadSelectedUpdateItems: MenuItem = {
                name: $localize`:@@DescargarActualizacionSeleccionados:Descargar Actualización Seleccionados`,
                action: () => this.createNodeAction(node, NodeTreeActionType.DownloadSelectedUpdate)
            };
            menuList.push(itemDownloadSelectedUpdateItems);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.Delete)) {
            const itemDelete: MenuItem = {
                name: $localize`:@@EliminarCarpetaMenuArbol:Eliminar Carpeta`,
                action: () => this.createNodeAction(node, NodeTreeActionType.Delete)
            };

            menuList.push(itemDelete);
        }

        if (this.hasNodePermission(node, NodeTreeActionType.DeleteSelected)) {
            const items: MenuItem = {
                name: $localize`:@@EliminarSeleccionados:Eliminar Seleccionados`,
                action: () => this.createNodeAction(node, NodeTreeActionType.DeleteSelected)
            };
            menuList.push(items);
        }

        return menuList;
    }

    private synchroniceExpandModel(keepOldExpanded: boolean) {
        if (!keepOldExpanded) {
            this.treeControl?.collapseAll();
        }

        this.manageExpandNodes();
    }

    private initFormModel() {
        this.treeForm = new UntypedFormGroup(
            {
                name: new UntypedFormControl(null, [])
            }
        );
    }

    private transformer = (node: FileNode, level: number) => {
        const nodeToFind =  Array.from(this.nestedNodeMap.keys())?.find(fileFlatNode => fileFlatNode.id === node.id);
        const existingNode = this.nestedNodeMap.get(nodeToFind);
        const flatNode = existingNode && existingNode.id === node.id ? existingNode : new FileFlatNode();

        this.setFileNodePropsIntoFileFlatNode(flatNode, node, level);
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        return flatNode;
    }

    private getLevel = (node: FileFlatNode) => node.level;
    private isExpandable = (node: FileFlatNode) => node.expandable;
    private getChildren = (node: FileNode): Observable<FileNode[]> => observableOf(node.children);

    private setFileNodePropsIntoFileFlatNode(flatNode: FileFlatNode, node: FileNode, level: number) {
        flatNode.expandable = !!node.children && node.children.length > 0;
        flatNode.level = level;
        flatNode.id = node.id;
        flatNode.value = node.value;
        flatNode.type = node.type;
        flatNode.parentId = node.parentId;
        flatNode.valueHighlighted = node.valueHighlighted;
        flatNode.isChecked = node.isChecked;
        flatNode.permissions = node.actions;
        flatNode.root = !!node.parentId;
        flatNode.highlightSelected = false;
        flatNode.additionalData = node.additionalData;
    }

    private addExpandedChildren(node: FileNode, expanded: string[], result: FileNode[]) {
        result.push(node);
        if (expanded.includes(node.id)) {
            node.children.map((child) => this.addExpandedChildren(child, expanded, result));
        }
    }

    /*The following methods are for persisting the tree expand state after being rebuilt */
    private rebuildTreeForData(data: FileNode[]): void {
        if (!data || data.length < 1) {
            this.dataTree = [];
            this.dataSource.data = [];
            return;
        }

        this.dataSource.data = data;
        if (this.loadFirstTime){
            this.setInitialNode();
            this.loadFirstTime = false;
        }
        this.manageExpandNodes();
    }

    private manageExpandNodes() {
        if (this.expandByDefault) {
            this.treeControl.expandAll();
            return;
        }

        this.expansionModel.selected.forEach((id) => {
            const node = this.treeControl.dataNodes.find((n) => n.id === id);
            if (node && this.treeControl.isExpandable(node)) {
                this.treeControl.expand(node);
            }

            if (node) {
                this.expandUntilNodeExpandedOrRoot(node);
            }
        });
    }

    private expandUntilNodeExpandedOrRoot(node: FileFlatNode) {
        const parentNode = this.treeControl.dataNodes.find((n) => n.id === node.parentId);
        if (!parentNode || parentNode.id === '0' || this.treeControl.isExpanded(parentNode)){
            return;
        }
        this.treeControl.expand(parentNode);
        this.expandUntilNodeExpandedOrRoot(parentNode);
    }

    private getParentNode(node: FileFlatNode): FileFlatNode | null {
        const currentLevel = node.level;
        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (currentNode.level < currentLevel) {
                return currentNode;
            }
        }

        return null;
    }

    private deleteNodeFromTree(node: FileFlatNode, name: string) {
        const parentNode = this.getParentNode(node);
        const nestedNode = this.flatNodeMap.get(parentNode);
        nestedNode.children = nestedNode.children.filter(n => n.value !== name);
    }

    private doWhenCreateSuccessful = (node: FileFlatNode) => {
        this.creationLock = false;
        if (!this.expansionModel.isSelected(node.parentId)) {
            this.expansionModel.toggle(node.parentId);
        }
    }

    private doWhenCreateFail = (node: FileFlatNode) => {
        this.creationLock = false;
        this.nodeName.focusInput();
    }

    private doWhenSelectSuccessful = (node: FileFlatNode) => {
        this.changeCurrentNode(node.id);
    }

    private renameNode(node: FileFlatNode) {
        this.eventEdit.next(node.id);
    }

    private createNodeAction(node: FileFlatNode, action: NodeTreeActionType): void {
        const nodeTreeAction = this.treeService.createNodeTreeAction(node, action);
        this.onNodeAction.emit(nodeTreeAction);
    }

    private hasNodePermission(node: FileFlatNode, permission: NodeTreeActionType) {
        return node.permissions.includes(permission);
    }

    private changeCurrentNode(currentNodeId: string){
        if (currentNodeId === undefined || currentNodeId === null) {
            return;
        }
        if (this.currentNodeSelected) {
            this.currentNodeSelected.highlightSelected = false;
        }
        this.currentNodeSelected = this.treeControl.dataNodes.find(node => node.id === currentNodeId);
        if (this.currentNodeSelected === null || this.currentNodeSelected === undefined) {
            return;
        }
        this.expandTreeToCurrentNodeSelected();
        this.currentNodeSelected.highlightSelected = true;
    }

    private rechargeCurrentNode() {
        this.changeCurrentNode(this.currentNodeId);

        if (!this.currentNodeSelected) {
            return;
        }
    }

    private setInitialNode() {
        if (this.dataTree.length <= 0) {
            return;
        }

        if (this.initialNodes.length > 0) {
            this.initialNodes.map((node) => {
                this.addNodeToExpand(node);
            });
        } else if (this.expansionModel.isEmpty()) {
            this.dataTree.map((node) => {
                this.addExpandedNode(node);
            });
        }

        this.expandTreeToCurrentNodeSelected();
    }

    private expandTreeToCurrentNodeSelected() {
        const currentNode = this.treeControl.dataNodes.find(node => node.id === this.currentNodeId);

        if (!currentNode) {
            return;
        }

        let nodeToAdd = currentNode;
        let lastNodeId = '';
        while (nodeToAdd !== undefined) {
            lastNodeId = nodeToAdd.id;
            this.addNodeToExpand(lastNodeId);

            nodeToAdd = this.treeControl.dataNodes.find(node => node.id === nodeToAdd.parentId);
        }

        this.expandRootOfNode(lastNodeId);
    }

    private expandRootOfNode(lastNodeId: string) {
        if (!lastNodeId || lastNodeId === '') {
            return;
        }

        const rootNodeToExpand = this.dataTree.find(rootNode => rootNode.children
                .find(childNode => childNode.id === lastNodeId) !== undefined);

        if (!rootNodeToExpand) {
            return;
        }

        this.addNodeToExpand(rootNodeToExpand.id);
    }

    private addNodeToExpand(nodeId: string) {
        if (this.expansionModel.selected.includes(nodeId)) {
            return;
        }

        this.expansionModel.toggle(nodeId);
    }

    private isNodeADescendantOfAnotherNode(node: FileNode, anotherNode: FileNode): boolean {
        if (node.id === anotherNode.id) {
            return true;
        }

        if (!anotherNode.children) {
            return false;
        }

        let isNodeDescendant = false;
        anotherNode.children.forEach((child: FileNode) => {
            isNodeDescendant = isNodeDescendant || this.isNodeADescendantOfAnotherNode(node, child);
        });

        return isNodeDescendant;
    }

    private addExpandedNode(node: FileNode) {
        const flatNode = this.nestedNodeMap.get(node);
        if (flatNode.expandable) {
            this.expansionModel.toggle(flatNode.id);
        }
    }

    private hasPermission(node: FileFlatNode, actionType: NodeTreeActionType): boolean {
        return node.permissions?.includes(actionType);
    }

    private afterCheckUncheckNodes(nodeAction: NodeTreeAction): void {
        if (!this.checkAllDescendants) {
            this.createAdditionalParamWithCheckedNode(nodeAction);
            return;
        }

        const node = nodeAction.node;

        const fileNode = this.flatNodeMap.get(node);
        const previousCheckedState = !node.isChecked;
        fileNode.isChecked = node.isChecked;
        this.changeAllNodeDescendants(fileNode);

        if (previousCheckedState) {
            this.uncheckNodeAscendants(node);
        }

        this.createAdditionalParamWithCheckedNode(nodeAction);
    }

    private createAdditionalParamWithCheckedNode(nodeAction: NodeTreeAction) {
        const node = nodeAction.node;
        const checkedNodes = Array.from(this.flatNodeMap.keys()).filter(flatNode => flatNode.isChecked)
            .map(flatNode => flatNode.id);

        if (checkedNodes.includes(node.id) && !node.isChecked) {
            this.flatNodeMap.get(node).isChecked = false;
            checkedNodes.splice(checkedNodes.indexOf(node.id), 1);
        }

        nodeAction.additionalParam = this.treeService.createAdditionalParamWithCheckedNode(node.isChecked, checkedNodes);
    }

    private changeAllNodeDescendants(node: FileNode): void {
        const fileflatNode = this.nestedNodeMap.get(node);
        fileflatNode.isChecked = node.isChecked;

        node.children.forEach((child: FileNode) => {
            child.isChecked = node.isChecked;
            this.changeAllNodeDescendants(child);
        });
    }

    private uncheckNodeAscendants(node: FileFlatNode): void {
        if (!node.parentId) {
            return;
        }

        const parentNode =  Array.from(this.flatNodeMap.keys()).find(n => n.id === node.parentId);

        parentNode.isChecked = false;
        const parentFileNode = this.flatNodeMap.get(parentNode);
        parentFileNode.isChecked = false;
        this.uncheckNodeAscendants(parentNode);
    }
}
