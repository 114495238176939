import { BehaviorSubject } from 'rxjs';
import { DocumentClauseDTO } from 'src/app/api';

export abstract class IClausesUpdatesInTemplateRevisionService {
    abstract getClausesUpdatesRevisionStatusSubscription(): BehaviorSubject<Map<string, string>>;
    abstract getClausesUpdatesRevisionStatus(): Map<string, string>;
    abstract setClausesUpdatesRevisionStatus(stateDocument: Map<string, string>);
    abstract getTotalPendingClausesUpdates(): number;
    abstract getPendingClauseUpdateIndex(documentClause: DocumentClauseDTO): number;
    abstract getClauseUpdateStatus(documentClause: DocumentClauseDTO): string;
    abstract isPending(documentClause: DocumentClauseDTO): boolean;
    abstract isApplied(documentClause: DocumentClauseDTO): boolean;
    abstract setPending(documentClause: DocumentClauseDTO): void;
    abstract setApplied(documentClause: DocumentClauseDTO): void;
    abstract getNextDocumentClauseId(currentId: number): string;
    abstract getPreviousDocumentClauseId(currentId: number): string;
    abstract getFirstDocumentClauseId(): string;
    abstract getLastDocumentClauseId(): string;
}
