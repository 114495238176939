import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DocumentClauseDTO } from 'src/app/api/model/documentClauseDTO';
import { IClausesUpdatesInTemplateRevisionService } from './clauses-updates-in-template-revision.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ClausesUpdatesInTemplateRevisionService extends IClausesUpdatesInTemplateRevisionService {

    public stateDocuments = new BehaviorSubject<Map<string, string>>(new Map<string, string>());

    private applied = 'Applied';
    private pending = 'Pending';
    private indexMap = new Map<string, number>();

    public getClausesUpdatesRevisionStatusSubscription(): BehaviorSubject<Map<string, string>> {
        return this.stateDocuments;
    }

    public setClausesUpdatesRevisionStatus(stateDocument: Map<string, string>) {
        this.stateDocuments.next(stateDocument);
        this.updateIndexMap();
    }

    public getClausesUpdatesRevisionStatus(): Map<string, string> {
        return this.stateDocuments.getValue();
    }

    public getClauseUpdateStatus(documentClause: DocumentClauseDTO): string {
        return this.stateDocuments.getValue().get(documentClause.id);
    }

    public getTotalPendingClausesUpdates(): number {
        const states = Array.from(this.stateDocuments.getValue().values());
        return states.filter(state => state === this.pending).length;
    }

    public getPendingClauseUpdateIndex(documentClause: DocumentClauseDTO): number {
        if (!documentClause) {
            return -1;
        }

        return this.indexMap.get(documentClause.id);
    }

    public isPending(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.getClausesUpdatesRevisionStatus().has(documentClause.id) &&
            this.getClausesUpdatesRevisionStatus().get(documentClause.id) === this.pending;
    }

    public isApplied(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.getClausesUpdatesRevisionStatus().has(documentClause.id) &&
            this.getClausesUpdatesRevisionStatus().get(documentClause.id) === this.applied;
    }

    public setPending(documentClause: DocumentClauseDTO): void {
        const current = this.getClausesUpdatesRevisionStatus();
        current.set(documentClause.id, this.pending);
        this.setClausesUpdatesRevisionStatus(current);
    }

    public setApplied(documentClause: DocumentClauseDTO): void {
        const current = this.getClausesUpdatesRevisionStatus();
        current.set(documentClause.id, this.applied);
        this.setClausesUpdatesRevisionStatus(current);
    }

    public getNextDocumentClauseId(currentIndex: number): string {
        let nextIndex = currentIndex + 1;
        if (this.indexMap.size < 2) {
            nextIndex = 0;
        }

        if (nextIndex < 0) {
            nextIndex = 0;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;
        }

        return this.getIdByIndex(nextIndex);
    }

    public getPreviousDocumentClauseId(currentIndex: number): string {
        let nextIndex = currentIndex - 1;

        if (nextIndex < 0) {
            nextIndex = this.indexMap.size - 1;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;
        }

        return this.getIdByIndex(nextIndex);
    }

    public getFirstDocumentClauseId(): string {
        return this.getIdByIndex(0);
    }

    public getLastDocumentClauseId(): string {
        return this.getIdByIndex(this.indexMap.size - 1);
    }

    private updateMapByIds(ids: string[]) {
        for (let i = 0; i < ids.length; i++) {
            this.indexMap.set(ids[i], i);
        }
    }

    private updateIndexMap() {
        this.indexMap.clear();
        const ids = Array.from(this.stateDocuments.getValue().keys());
        this.updateMapByIds(ids);
    }

    private getIdByIndex(index: number): string {
        const foundValue = [...this.indexMap].find(([key, val]) => val === index);
        if (!foundValue) {
            return undefined;
        }

        return foundValue[0];
    }

}
