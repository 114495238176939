import { StyleAttributeModel } from "../custom-plugins/models/schema/style-attribute-model";

export class PluginGlobalConstant {

    public static readonly START_WITH_SELECTION_IN_ATTRIBUTE = 'selection:';

    public static readonly STYLE_ATTRIBUTES: StyleAttributeModel[] = [
        { model: 'bold',                dataView:'font-weight: bold; ',             editionView: 'strong'},
        { model: 'italic',              dataView:'font-style: italic; ',            editionView: 'i'},
        { model: 'underline',           dataView:'text-decoration: underline; ',    editionView: 'u'},
        { model: 'strikethrough',       dataView:'text-decoration: line-through; ', editionView: 's'},
        { model: 'code',                dataView:'',                                editionView: 'code'},
        { model: 'subscript',           dataView:'',                                editionView: 'sub'},
        { model: 'superscript',         dataView:'',                                editionView: 'sup'},
        { model: 'fontSize',            dataView:'font-size: {0}; ',                editionView: 'font-size: {0};',         useValue: true},
        { model: 'fontFamily',          dataView:'font-family: {0}; ',              editionView: 'font-family: {0}; ',      useValue: true},
        { model: 'fontColor',           dataView:'color: {0}; ',                    editionView: 'color: {0}; ',            useValue: true},
        { model: 'fontBackgroundColor', dataView:'background-color: {0}; ',         editionView: 'background-color: {0}; ', useValue: true},
    ];
}
