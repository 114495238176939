import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteStateService {

    private pathParamOpenPopupState = new BehaviorSubject<string>('');
    private pathParamReturnUrlState = new BehaviorSubject<string>('');
    private pathParamEmailState = new BehaviorSubject<string>('');

    public pathParamOpenPopup: Observable<string>;
    public pathParamReturnUrl: Observable<string>;
    public pathParamEmail: Observable<string>;

    constructor() {
        this.pathParamOpenPopup = this.pathParamOpenPopupState.asObservable();
        this.pathParamReturnUrl = this.pathParamReturnUrlState.asObservable();
        this.pathParamEmail = this.pathParamEmailState.asObservable();
    }

    public updateParamOpenPopupState(newOpenPopup: string): void {
        this.pathParamOpenPopupState.next(newOpenPopup);
    }

    public updateParamReturnUrl(newParamUrl: string): void {
        this.pathParamReturnUrlState.next(newParamUrl);
    }

    public updateParamEmail(newEmail): void {
        this.pathParamEmailState.next(newEmail);
    }

    public getParamReturnUrl(): string {
        return JSON.parse(JSON.stringify(this.pathParamReturnUrlState.getValue()));
    }
}
