/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { TemplateDocumentDTO } from '../model/templateDocumentDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { HtmlComparerDto } from '../model/htmlComparerDto';


@Injectable()
export class TemplateDocumentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDocumentDTO>;
    public templateDocumentIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDocumentDTO>>;
    public templateDocumentIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDocumentDTO>>;
    public templateDocumentIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templateDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDocumentDTO>('get',`${this.basePath}/template-document/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentPost(body?: TemplateDocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<TemplateDocumentDTO>;
    public templateDocumentPost(body?: TemplateDocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDocumentDTO>>;
    public templateDocumentPost(body?: TemplateDocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDocumentDTO>>;
    public templateDocumentPost(body?: TemplateDocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateDocumentDTO>('post',`${this.basePath}/template-document`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentSearchDocumentDocumentIdGet(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDocumentDTO>>;
    public templateDocumentSearchDocumentDocumentIdGet(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchDocumentDocumentIdGet(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchDocumentDocumentIdGet(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling templateDocumentSearchDocumentDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDocumentDTO>>('get',`${this.basePath}/template-document/search/document/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentSearchPendingDocumentDocumentIdGet(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDocumentDTO>;
    public templateDocumentSearchPendingDocumentDocumentIdGet(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDocumentDTO>>;
    public templateDocumentSearchPendingDocumentDocumentIdGet(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDocumentDTO>>;
    public templateDocumentSearchPendingDocumentDocumentIdGet(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling templateDocumentSearchPendingDocumentDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDocumentDTO>('get',`${this.basePath}/template-document/search-pending/document/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentSearchPendingTemplateTemplateIdGet(templateId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDocumentDTO>>;
    public templateDocumentSearchPendingTemplateTemplateIdGet(templateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchPendingTemplateTemplateIdGet(templateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchPendingTemplateTemplateIdGet(templateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling templateDocumentSearchPendingTemplateTemplateIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDocumentDTO>>('get',`${this.basePath}/template-document/search-pending/template/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param templateId
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet(templateId: string, documentId: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDocumentDTO>;
    public templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet(templateId: string, documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDocumentDTO>>;
    public templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet(templateId: string, documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDocumentDTO>>;
    public templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet(templateId: string, documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling templateDocumentSearchTemplateTemplateIdDocumentDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDocumentDTO>('get',`${this.basePath}/template-document/search/template/${encodeURIComponent(String(templateId))}/document/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param templateId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateDocumentSearchTemplateTemplateIdGet(templateId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDocumentDTO>>;
    public templateDocumentSearchTemplateTemplateIdGet(templateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchTemplateTemplateIdGet(templateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDocumentDTO>>>;
    public templateDocumentSearchTemplateTemplateIdGet(templateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (templateId === null || templateId === undefined) {
            throw new Error('Required parameter templateId was null or undefined when calling templateDocumentSearchTemplateTemplateIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDocumentDTO>>('get',`${this.basePath}/template-document/search/template/${encodeURIComponent(String(templateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public templateDocumentCompareIdGet(templateDocumentId: string): Observable<any> {

        if (templateDocumentId === null || templateDocumentId === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templateDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<Array<HtmlComparerDto>>('get',`${this.basePath}/template-document/compare/${encodeURIComponent(String(templateDocumentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
            }
        );

    }

}
