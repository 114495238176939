import { Directive, OnDestroy } from "@angular/core";
import {
    ClickObserver, ContextualBalloon, Dialog, Editor, ViewDocument, ViewDocumentClickEvent, Widget,
    DowncastWriter, Writer, DomEventData, EventInfo, ViewContainerElement, ViewSelection, TreeWalkerValue,
    Element, KeyEventData, ViewDocumentKeyDownEvent, Item, Node, Text, ViewElement, Model } from "ckeditor5";
import getSelectedContent from "@ckeditor/ckeditor5-engine/src/model/utils/getselectedcontent";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import AddInputCommand from "../../commands/input/add-input-command";
import DeleteInputCommand from "../../commands/input/delete-input-command";
import EditInputCommand from "../../commands/input/edit-input-command";
import { InputDataViewToModelConverterService } from "../../converters/input/input-data-view-to-model-converter.service";
import { InputModelToDataViewConverterService } from "../../converters/input/input-model-to-data-view-converter.service";
import { InputModelToEditorViewConverterService } from "../../converters/input/input-model-to-editor-view-converter.service";
import InputBalloonView from "../../ui/input/input-balloon-view.directive";
import InputDialogView, { InputDialogFormViewCallback, InputDialogFormViewDeleteAliasCallback } from "../../ui/input/input-dialog-view.directive";
import { InputEditionViewUtilsService } from "../../utils/input/input-edition-view-utils.service";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { BasePlugin } from "../base/base-plugin";
import { InputSchemaService } from "../../schema/input/input-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { SchemaModel } from "../../models/schema/schema-model";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { GlobalConstant } from "../../models/base/global-constant";
import { ShortTextInput } from "../../models/input/short-text-input-model";
import { GenericDialogService } from "src/app/core/shared/services/generic-dialog/generic-dialog.service";
import { SpecialKeyCode, SpecialKeyControlCode } from "../../models/base/keycode";
import { ContextEditorTypes } from "../../models/base/context-editor-types";
import { PluginElementType } from "../../../models/plugin-element-type.enum";

@Directive({
    selector: "input-plugin",
})
export class InputPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = "Input-pg";
    public static readonly PLUGIN_MODEL = "input-pg";

    public static genericDialog: GenericDialogService;

    public static readonly MODEL_ENTITIES:  {[name:string]: SchemaModel} = {
        'container'     :   { model: 'input-pg',                dataView:'iMed',                    editionView: 'input-plugin'      },
        'content'       :   { model: 'input-content',           dataView:'input-content',           editionView: 'input-content'     },
        'name'          :   { model: 'name',                    dataView:'name',                    editionView: 'name'              },
        'help'          :   { model: 'input-help',              dataView:'data-input-help',         editionView: 'data-input-help'   },
        'type'          :   { model: 'input-type',              dataView:'type',                    editionView: 'type'              },
        'pattern'       :   { model: 'pattern',                 dataView:'data-pattern',            editionView: 'data-pattern'      },
        'transform'     :   { model: 'transform',               dataView:'data-transform',          editionView: 'data-transform'    },
        'isValid'       :   { model: 'is-valid',                dataView:'data-is-valid',           editionView: 'data-is-valid'     },
        'alias'         :   { model: 'alias',                   dataView:'data-alias',              editionView: 'data-alias'        },
        'isLinked'      :   { model: 'is-linked',               dataView:'data-is-linked',          editionView: 'data-is-linked'    },
    };

    public static readonly ATTRIBUTE_INPUT_IS_VALID_HIDDEN_EDITION_VIEW = "hidden";
    public static readonly ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW = "input-is-valid";
    public static readonly ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW = "input-is-not-valid";

    public static readonly INPUT_CLASS_VALIDATE = "input-plugin-validate";
    public static readonly ID_INPUT_PREFFIX = 'ck-labeled-field-view-';
    public static readonly NAME_INPUT_PREFFIX = 'input-name-';
    public static readonly VISUAL_SELECTION_MARKER_NAME = 'input-pg';

    public static readonly ALLOWED_TYPES = ['date', 'email', 'number', 'text'];
    public static readonly TEXT_TYPE = 'text';
    public static readonly ATTRIBUTE_ALIAS_IS_STORED = "data-alias";

    public static readonly DELETE_COMMAND_NAME = "delete-input-pg";
    public static readonly EDIT_COMMAND_NAME = "edit-input-pg";
    public static readonly ADD_COMMAND_NAME = "add-input-pg";

    public static isInputPlugin(element: ViewElement): boolean {
        return element.hasClass(this.MODEL_ENTITIES.container.editionView);
    }

    protected mappers = [
        InputPlugin.MODEL_ENTITIES.container.editionView
    ];

    protected commands = {
        [InputPlugin.ADD_COMMAND_NAME]: AddInputCommand,
        [InputPlugin.DELETE_COMMAND_NAME]: DeleteInputCommand,
        [InputPlugin.EDIT_COMMAND_NAME]: EditInputCommand
    };

    protected inputsFillMessage = $localize`:@@PluginInputBotoneraBotonPrincipalTooltip:Campos rellenables`;

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.ICON_INPUT,
        pluginToolbarElementName: InputPlugin.pluginToolbarElementName,
        buttonText: '',
        tooltip: this.inputsFillMessage,
        hasTooltip: true,
        hasText: true
    };

    private utilsService: InputUtilsService;
    private editionViewUtils: InputEditionViewUtilsService;

    private modelToEditorViewConverter: InputModelToEditorViewConverterService;
    private modelToDataViewConverter: InputModelToDataViewConverterService;
    private dataViewToModelConverter: InputDataViewToModelConverterService;
    private inputSchemaService: InputSchemaService;
    private userInterfaceService: UserInterfaceService;

    private fieldShortTextMessage = $localize`:@@PluginInputTituloAñadir:Campo Texto corto`;
    private fieldShortTextEditionMessage = $localize`:@@PluginInputTituloEdicion:Campo texto corto`;

    private inputWarningRepeated = $localize`:@@PluginInputAliasRepetido:Este alias ya está en el documento. Introduce otro.`;
    private inputWarningLargeAlias =  $localize`:@@PluginInputAliasExcesoCaracteres:El alias no puede tener más de 30 caracteres.`;
    private inputWarningAllowCharacters = $localize`:@@PluginInputAliasValidacionMinusculasYNumeros:Solo se permiten minúsculas sin acentos y números.`;
    private inputWarningLargePlaceholder =  $localize`:@@PluginInputHelpExcesoCaracteres:El texto de ayuda no puede tener más de 100 caracteres.`;
    private inputWarningDeleteInputWithLinkedField = $localize`:@@PluginInputErrorBorrarInputConCampoVinculado:Para eliminar debes primero desvincular o borrar los campos vinculados.`;

    private inputDialogView: InputDialogView;

    private inputBalloonView: InputBalloonView;

    private MAX_ALIAS_LENGTH = 30;
    private MAX_HELP_LENGTH = 100;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new InputUtilsService();
        this.editionViewUtils = new InputEditionViewUtilsService();
        this.pluginUtils = new PluginUtilsService();

        this.modelToEditorViewConverter = new InputModelToEditorViewConverterService();
        this.modelToDataViewConverter = new InputModelToDataViewConverterService();
        this.dataViewToModelConverter = new InputDataViewToModelConverterService();

        this.inputSchemaService = new InputSchemaService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public static get requires() { return [Widget, ContextualBalloon, Dialog]; }
    public static get pluginName(): string { return InputPlugin.PLUGIN_NAME; }
    public static get pluginModelName(): string { return InputPlugin.PLUGIN_MODEL; }
    public static get pluginToolbarElementName(): string { return InputPlugin.PLUGIN_MODEL; }

    public init(): void {
        super.init();
        this.defineCustomListeners();
    }

    public override toolbarExecuteOperation(): void {
        const dialog = this.editor.plugins.get("Dialog");

        const model: ShortTextInput = { id: '', alias: ''};

        this.addEditInputDialogView(model);

        this.inputDialogView.resetFormStatus();
        dialog.show({
            isModal: true,
            content: this.inputDialogView,
            title: this.fieldShortTextMessage,
            id: "",
        });
    }

    protected defineSchema(): void {
        this.inputSchemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.dataViewToModelConverter.configureConverter(this.editor);
        this.modelToDataViewConverter.configureConverter(this.editor);
        this.modelToEditorViewConverter.configureConverter(this.editor);
    }

    protected editorInteractions(): void {
        const viewDocument = this.editor.editing.view.document;
        this.defineObservers();
        this.enableBalloonActivators(viewDocument);
        // TODO Revisar si esto es necesario que se quede deshabilitado una vez funcionen los inputs en modo lectura
        this.listenTo<ViewDocumentKeyDownEvent>(viewDocument, 'keydown', this.handleKeyDownEvent.bind(this), { priority: 'high' });
        this.editor.plugins.get('ClipboardPipeline').on('inputTransformation', this.handlePasteEvent.bind(this), { priority: 'high' });
        this.editor.plugins.get('ClipboardPipeline').on('contentInsertion', this.handlePasteInsertionEvent.bind(this), { priority: 'high' });
    }

    protected override toolbarInteractions(): void { }

    protected override getPluginDataViewClass(): string {
        return InputPlugin.MODEL_ENTITIES.container.dataView;
    }

    protected override getPluginModelName(): string {
        return InputPlugin.MODEL_ENTITIES.container.model;
    }

    protected override processElementsPaste(elements: Node[]): void {
        const aliasAttribute = InputPlugin.MODEL_ENTITIES.alias.editionView;
        const dataIsLinkedAttribute = InputPlugin.MODEL_ENTITIES.isLinked.editionView;

        const existingDataAlias = new Set(
            elements
                .filter(input => input.getAttribute(dataIsLinkedAttribute) !== 'true')
                .map(input => input.getAttribute(aliasAttribute) as string)
                .filter(alias => alias !== null)
        );

        this.updateInputElements(elements, existingDataAlias);
    }

    protected override setupModelPostFixing(): void {
        const model = this.editor.model;
        model.document.registerPostFixer(writer => this.applyInheritedStylesIfNeeded(model, writer, InputPlugin.MODEL_ENTITIES.content.model));
    }

    protected override getStyleAttributesFromPreviousElement(element: Element, writer: Writer): [string, unknown][] {
        const previousElement = this.pluginUtils.findStyledElementBefore(element, writer, PluginElementType.INLINE);
        if(!previousElement) {
            return [];
        }
        return this.pluginUtils.getStyleAttributesFromPreviousElement(previousElement);
    }

    private applyInheritedStylesIfNeeded(model: Model, writer: Writer, containerName: string): boolean {
        const changes = model.document.differ.getChanges();
        let wasFixed = false;
        for (const entry of changes) {
            if (entry.type == 'insert' && entry.name == '$text') {
                const content = entry.position?.parent;
                const isInsideContent = content?.name === containerName;
                if (!isInsideContent || entry.position.parent.parent.childCount !== 1) {
                    return;
                }

                const textNode = (entry.position.parent.parent.getChild(0) as Element).getChild(0).is('$text') ? (entry.position.parent.parent.getChild(0) as Element).getChild(0) as Text : null;
                if (textNode?.data.length === 1) {
                    let outerContainer = content.parent;
                    const attributes = this.getStyleAttributesFromPreviousElement(outerContainer as Element, writer);
                    const previousAttributes = Array.from((entry.position.parent.getChild(0)).getAttributes());
                    wasFixed = !this.pluginUtils.areSameAttributes(attributes, previousAttributes);
                    writer.setAttributes(attributes, entry.position.parent.getChild(0));
                }
            }
        }
        return wasFixed;
    }

    private defineObservers(): void {
        this.editor.editing.view.addObserver(ClickObserver);
        this.editor.editing.view.addObserver(DoubleClickObserver);
    }

    private setValidAttribute(writer: Writer, contentElement: ViewContainerElement, inputElementInModel: TreeWalkerValue): void {
        const elementsWithInvalidClass = contentElement.hasClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW);
        const isValid = elementsWithInvalidClass ? false : true;
        writer.setAttribute(InputPlugin.MODEL_ENTITIES.isValid.model, isValid, inputElementInModel.item);
    }

    private performTrasformation(writer: Writer, contentElement: ViewContainerElement, inputElementInModel: TreeWalkerValue): void {
        const transform = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView)!;
        if (!transform || transform === GlobalConstant.UNDEFINED) {
            return;
        }
        this.utilsService.transformInputValue(writer, inputElementInModel, transform);
    }

    private defineCustomListeners(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo(viewDocument, 'blur', this.handleBlurEvent.bind(this));
        this.listenTo(viewDocument, 'change', this.handleChangeEvent.bind(this));
        this.handleDoubleBlankInRestrictedMode(this.editor);
    }

    private handleBlurEvent(): void {
        this.validAttribute();
        this.trasformInputValue();
        this.changeValueLinkedField();
    }

    private handleChangeEvent(): void {
        const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        if (!contentElement) {
            return;
        }

        const editingView = this.editor.editing.view;
        editingView.change((writer: DowncastWriter) => {
            this.editionViewUtils.applyValidationToInputEditingView(writer, contentElement);
        });
    }

    private validAttribute(): void {
        const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        const id = contentElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        if(id) {
            const inputElementInModel = this.pluginUtils.getElementModelWithId(this.editor, id);

            if (contentElement && inputElementInModel) {
                this.editor.model.change((writer: Writer) => {
                    this.setValidAttribute(writer, contentElement, inputElementInModel);
                });
            }
        }
    }

    private trasformInputValue(): void {
        const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        const id = contentElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        if(id) {
            const inputElementInModel = this.pluginUtils.getElementModelWithId(this.editor, id);

            if (contentElement && inputElementInModel) {
                this.editor.model.change((writer: Writer) => {
                    this.performTrasformation(writer, contentElement, inputElementInModel);
                });
            }
        }
    }

    private changeValueLinkedField(): void {
        const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        const alias = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.alias.editionView);
        const isLinked = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.editionView);
        if (alias && isLinked && isLinked === "false") {
            const inputsAndLinkedFieldsModel = this.utilsService.getInputAndLinkedFieldsElementModelWithAlias(this.editor, alias);
            this.editor.model.change((writer: Writer)  => {
                const id = contentElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const linkedFieldModel = this.pluginUtils.getElementModelWithId(this.editor, id);
                const valueLinkedField = this.utilsService.getInputValue(linkedFieldModel.item as Element);

                inputsAndLinkedFieldsModel.forEach((input:TreeWalkerValue) => {
                    this.utilsService.editInputValue(writer, input, valueLinkedField);
                });
            });

            inputsAndLinkedFieldsModel.forEach((input: TreeWalkerValue) => {
                const inputEditingModel = this.pluginUtils.getElementView(this.editor, input.item.getAttribute('id').toString());
                const editingView = this.editor.editing.view;
                editingView.change((writer: DowncastWriter) => {
                    this.editionViewUtils.applyValidationToInputEditingView(writer, inputEditingModel);
                });
            });
        }
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

    private enableBalloonActivators(viewDocument: ViewDocument): void {
        this.balloon = this.editor.plugins.get(ContextualBalloon);
        this.showBalloonOnClick(viewDocument);
    }

    private showBalloonOnClick(viewDocument: ViewDocument): void {
        this.clickInInputOpenBalloonHandler(viewDocument);
        this.doubleClickInInputToEditionHandler(viewDocument);
        this.selectionChangeClickHandler(viewDocument);
    }

    private clickInInputOpenBalloonHandler(viewDocument: ViewDocument): void {
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', (evt, data) => {
            if (this.isReadOnlyOrRestrictedMode()) {
                return;
            }

            const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            if (!parentElement) {
                return;
            }

            if (!this.pluginUtils.isViewElementInteractableInTargetEditor(parentElement,  InputPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
                return;
            }

            const inputModel = this.dataViewToModelConverter.getModel(parentElement);

            this.showInputBalloon(inputModel);
            this.finishEventPropagation(data, evt);
        });
    }

    private doubleClickInInputToEditionHandler(viewDocument: ViewDocument) {
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', (evt: EventInfo, data: DomEventData) => {
            if (this.isReadOnlyOrRestrictedMode()) {
                return;
            }

            const inputModel: ShortTextInput = this.getInputModel(data);

            if (!inputModel || inputModel.isLinked === true){
                return;
            }

            if (!this.pluginUtils.isElementInteractableInTargetEditor(inputModel.embeddedIn, InputPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
                return;
            }

            this.showEditInputDialog(inputModel);
            this.finishEventPropagation(data, evt);

        }, { priority: "high" });
    }

    private getInputModel(data: DomEventData) : ShortTextInput {
        const elementClicked = data.domTarget;
        const isInputContainer = (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.container.editionView} `);
        const isInputContent = (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.content.editionView} `);

        if (isInputContainer) {
            return this.getModel(elementClicked);
        } else if (isInputContent) {
            return this.getModel(elementClicked.parentElement);
        } else {
            const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

            return !!parentElement ? this.dataViewToModelConverter.getModel(parentElement) : null;
        }
    }

    private getModel(viewElement: HTMLElement): ShortTextInput {
        const id = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const alias = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.editionView) ?? '';
        const helpNote = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.editionView) ?? '';
        const pattern = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView) ?? '';
        const transform = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView) ?? '';
        const value = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_VALUE) ?? '';
        const isValid = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.editionView) ?? '';
        const type = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.editionView) ?? '';
        const isLinked = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.editionView) ?? '';
        const embeddedIn = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ?? '';

        const inputModel: ShortTextInput = {
            id,
            alias: alias,
            help: helpNote,
            value,
            pattern,
            transform,
            isValid: isValid === 'true',
            type: type,
            isLinked: isLinked === 'true',
            embeddedIn: embeddedIn,
        };
        return inputModel;
    }

    private selectionChangeClickHandler(viewDocument: ViewDocument): void {
        this.listenTo(viewDocument, 'selectionChange', (evt, data) => {
            const oldSelection = data.oldSelection;
            const newSelection = data.newSelection;
            const fromInside = oldSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.container.editionView) ||
                oldSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.content.editionView);
            const toOutside = !newSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.container.editionView) &&
                !newSelection.editableElement?.hasClass(InputPlugin.MODEL_ENTITIES.content.editionView);

            if (fromInside && toOutside) {
                this.resetSelectionAfterClick(newSelection);
            }

        }, { priority: "high" });
    }

    private resetSelectionAfterClick(newSelection: ViewSelection): void {
        const storedRanges = [];
        const mapper = this.editor.editing.mapper;
        for (const viewRange of newSelection.getRanges()) {
            storedRanges.push(mapper.toModelRange(viewRange));
        }

        const model = this.editor.editing.model;

        setTimeout(() => {
            const modelSelection = model.createSelection(storedRanges, {
                backward: newSelection.isBackward
            });
            model.change((writer) => {
                writer.setSelection(modelSelection);
            });
        }, 250);
    }

    private showInputBalloon(inputModel?: ShortTextInput, forceVisible: boolean = false): void {
        if (!this.inputBalloonView) {
            this.addInputBalloonView(inputModel);
        }

        const inputElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        if (!inputElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addInputBalloonView(inputModel);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private showEditInputDialog(inputModel?: ShortTextInput, forceVisible: boolean = false): void {
        this.removeInputBalloonView();

        const inputElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        if (!inputElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addEditInputDialogView(inputModel);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private addInputBalloonView(inputModel?: ShortTextInput): void {
        if (!this.inputBalloonView) {
            this.createInputBalloonView();
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView)) {
            const isSameInputEditing = this.inputBalloonView.id === inputModel?.id;
            if (!isSameInputEditing) {
                this.inputBalloonView.inputModel = inputModel!;
            }
            return;
        }

        this.inputBalloonView!.resetFormStatus();
        this.inputBalloonView.inputModel = inputModel!;

        this.balloon.add({
            view: this.inputBalloonView!,
            position: this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME)
        });
    }

    private createInputBalloonView(): void {
        const editor = this.editor;
        this.inputBalloonView = new InputBalloonView(
            editor.locale
        );

        this.inputBalloonView.editButtonView.on('execute', () => {
            this.showEditInputDialog(this.inputBalloonView.inputModelToEdit);
        });

        this.inputBalloonView.deleteButtonView.on('execute', () => {
            if (this.utilsService.hasLinkedFields(this.inputBalloonView.alias, document)) {
                InputPlugin.genericDialog.showMessage(this.inputWarningDeleteInputWithLinkedField);
                this.hideUI();
                return;
            }
            editor.execute(InputPlugin.DELETE_COMMAND_NAME, this.inputBalloonView.id);
            this.hideUI();
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.inputBalloonView, InputPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private addEditInputDialogView(inputModel?: ShortTextInput): void {
        if (!this.inputDialogView) {
            this.inputDialogView = this.createEditInputDialogView(inputModel);
        }

        this.inputDialogView!.resetFormStatus();
        this.inputDialogView.inputModel = inputModel!;

        if (inputModel?.alias !== '') {
            this.inputDialogView.aliasButtonView.label = inputModel.alias;
            this.inputDialogView.deleteButtonView.isVisible = true;
            this.inputDialogView.infoTextView.element.classList.add('ck-icon-info-hidden');
        }

        const dialog = this.editor.plugins.get("Dialog");
        dialog.show({
            isModal: true,
            content: this.inputDialogView,
            title: this.fieldShortTextEditionMessage,
            onHide() { },
            id: "",
        });
        this.inputDialogView!.selectDefaultField();
    }

    private createEditInputDialogView(inputModel?: ShortTextInput): InputDialogView {
        const formView = this.getEditInputDialogView(inputModel);
        return formView;
    }

    private getEditInputDialogView(inputModel?: ShortTextInput): InputDialogView {
        const editor = this.editor;
        const validatorsAliasField = this.getAddInputFormValidatorsAliasField();
        const validatorsHelpField = this.getAddInputFormValidatorsHelpField();
        const validatorDeleteAlias = this.getDeleteAliasInputFormValidator();

        if (!this.inputDialogView) {
            this.inputDialogView = new InputDialogView(validatorsAliasField, validatorsHelpField, validatorDeleteAlias, editor.locale, inputModel);
            this.handlerSubmitCancelInForm();
        } else {
            this.inputDialogView.resetFormStatus();
        }

        return this.inputDialogView;
    }

    private handlerSubmitCancelInForm(): void {
        const dialog = this.editor.plugins.get("Dialog");
        this.listenTo(this.inputDialogView, "submit", () => {
            const input = this.inputDialogView.getInputModel();

            if (!this.inputDialogView.isValidAllValidatorsField()) {
                return;
            }

            if (this.inputDialogView.isInCreation()) {
                this.editor.execute(InputPlugin.ADD_COMMAND_NAME, input);
            } else {
                this.editor.execute(InputPlugin.EDIT_COMMAND_NAME, input);
            }
            this.closeDialog(dialog);
            this.inputDialogView.resetFormStatus();
        });

        this.listenTo(this.inputDialogView, "cancel", () => {
            this.hideUI();
            this.closeDialog(dialog);
            this.inputDialogView.resetFormStatus();
        });
    }

    private hideUI(): void {
        if (!this.isUIInPanel()) {
            return;
        }

        const editor = this.editor;
        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        editor.editing.view.focus();

        this.removeInputBalloonView();
        this.removeInputEditFormView();

        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private removeInputEditFormView(): void {
        this.inputDialogView?.resetFormStatus();
        this.editor.editing.view.focus();
    }

    private removeInputBalloonView(): void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView)) {
            return;
        }

        this.balloon.remove(this.inputBalloonView!);

        this.editor.editing.view.focus();
        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private isUIInPanel(): boolean {
        return this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputBalloonView) ||
            this.userInterfaceService.isBalloonInPanel(this.balloon, this.inputDialogView);
    }

    private isUIVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.inputDialogView) ||
            this.userInterfaceService.areActionsVisible(this.balloon, this.inputBalloonView);
    }

    private startUpdatingUI(): void {
        const editor = this.editor;

        let previousSelectedInput = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        let previousSelectionParent = this.getSelectionParent();

        const update = () => {
            const selectedSignature = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
            const selectionParent = this.getSelectionParent();


            if ((previousSelectedInput && !selectedSignature) ||
                (!previousSelectedInput && selectionParent !== previousSelectionParent)) {
                this.hideUI();
            } else if (this.isUIVisible()) {
                this.balloon.updatePosition(this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME));
            }

            previousSelectedInput = selectedSignature;
            previousSelectionParent = selectionParent;
        };

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private getAddInputFormValidatorsAliasField(): Array<InputDialogFormViewCallback> {
        const lowercaseAlphanumericRegex = /^[a-z0-9ñ]+$/;

        return [
            form => {
                if (form.aliasInputView.fieldView.element.value.toLocaleString() === '') {
                    return '';
                }

                if (
                    this.utilsService.hasAliasInDocument(
                        this.editor,
                        form.aliasInputView.fieldView?.element.value.toLocaleString(),
                        form.id
                    )
                ) {
                    return this.inputWarningRepeated;
                }

                if (form.aliasInputView.fieldView?.element.value.toLocaleString().length > this.MAX_ALIAS_LENGTH) {
                    return this.inputWarningLargeAlias;
                }

                if (!lowercaseAlphanumericRegex.test(form.aliasInputView.fieldView?.element.value.toLocaleString())) {
                    return this.inputWarningAllowCharacters;
                }

                return '';
            }
        ];
    }

    private getAddInputFormValidatorsHelpField(): Array<InputDialogFormViewCallback> {
        return [
            form => {
                if (form.helpInputView.fieldView?.element.value.toLocaleString().length > this.MAX_HELP_LENGTH) {
                    return this.inputWarningLargePlaceholder;
                }

                return '';
            }
        ];
    }

    private getDeleteAliasInputFormValidator(): Array<InputDialogFormViewDeleteAliasCallback> {
        return [
            form => {
                    return this.utilsService.hasLinkedFields(form.aliasInputView.fieldView?.element.value.toLocaleString(), document)
            }
        ];
    }

    private handleKeyDownEvent(event: EventInfo<'keydown'>, data: KeyEventData): void {
        if (this.isCommentsOnlyMode()) {
            return;
        }

        const isControlPressed = data.domEvent.ctrlKey;
        const isCommandWithControl = data.keyCode >= 65 && data.keyCode <= 90;
        const isSpecialKey = Object.values(SpecialKeyCode).includes(data.keyCode);
        const isSpecialControlKey = Object.values(SpecialKeyControlCode).includes(data.keyCode);
        const isCommandExcludedFromDelete = (isControlPressed && isCommandWithControl) || isSpecialKey || (isSpecialControlKey && isControlPressed);

        const insideInput = data?.target?.hasClass("input-content");
        if (this.isRestrictedMode() && !insideInput && !isCommandExcludedFromDelete) {
            data.preventDefault();
            data.stopPropagation();
            event.stop();
            event.return = true;
            return;
        }

        const content = getSelectedContent(this.editor.model, this.editor.model.document.selection);
        let htmlSelection = this.editor.data.stringify(content);
        if (!htmlSelection || isCommandExcludedFromDelete) {
            return;
        }

        this.handlerDeleteInputs();
        this.clearSelection();
        this.handleKeyDownEvent(event, data);
    }

    private updateInputElements(inputElements: Node[], existingDataAlias: Set<string>): void {
        const view = this.editor.editing.view;

        const aliasAttribute = InputPlugin.MODEL_ENTITIES.alias.editionView;
        const dataIsLinkedAttribute = InputPlugin.MODEL_ENTITIES.isLinked.editionView;

        view.change(writer => {
            inputElements.forEach(input => {
                const dataAlias = input.getAttribute(aliasAttribute) as string;
                const isLinked = input.getAttribute(dataIsLinkedAttribute);

                if (isLinked !== 'true') {
                    if (input instanceof ViewElement) {
                        writer.setAttribute('value', '',  input);
                    }
                }

                if (isLinked !== 'true' && dataAlias && existingDataAlias.has(dataAlias)) {
                    if (input instanceof ViewElement) {
                        writer.removeAttribute(aliasAttribute, input);
                    }
                }

                const id = input.getAttribute('id');
                if (id) {
                    const randomSeed = Math.random().toString(36).substring(2, 15);
                    if (input instanceof ViewElement) {
                        writer.setAttribute('id', id + randomSeed, input);
                    }
                }
            });
        });
    }

    private handlerDeleteInputs(): void {
        const inputElements = this.getInputsToDelete();
        this.removeSelection();
        const inputsNotToTemove = this.getInputsNotToRemove(inputElements);
        this.insertInputsNotToRemove(inputsNotToTemove);
    }

    private getInputsToDelete(): Item[] {
        const selection = this.editor.model.document.selection;
        const range = selection.getFirstRange();

        const inputElements: Item[] = [];
        for (const item of range.getItems()) {
            if ((item as Element)?.name === InputPlugin.MODEL_ENTITIES.container.model
                && item.hasAttribute(InputPlugin.MODEL_ENTITIES.alias.model) && item.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.model) === false) {
                if (!inputElements.includes(item)) {
                    inputElements.push(item);
                }
            }
        }
        return inputElements;
    }

    private removeSelection() {
        const selection = this.editor.model.document.selection;
        const range = selection.getFirstRange();

        this.editor.model.change((writer: Writer) => {
            if (range) {
                writer.remove(range);
            }
        });
    }

    private getInputsNotToRemove(inputs: Item[]): Item[] {
        const inputsNoToRemove: Item[] = [];
        inputs.forEach((input: Item) => {
            let alias = input.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model) as string;
            if (this.utilsService.hasLinkedFields(alias, document)) {
                inputsNoToRemove.push(input);
            }
        });
        return inputsNoToRemove;
    }

    private insertInputsNotToRemove(inputs: Item[]) {
        this.editor.model.change( (writer:Writer) => {
            inputs.forEach((input: Item) => {
                writer.insert(input, this.editor.model.document.selection.getFirstPosition());
            });

        });

    }

    private clearSelection(): void {
        this.editor.model.change( writer => {
            let selection = this.editor.model.document.selection;
            const pos = selection.getLastPosition();
            const range = writer.createRange(pos);
            writer.setSelection( range );
       } );
    }
}
