import { Component, Input, OnInit, ElementRef, ViewChild, ViewEncapsulation, OnDestroy, EventEmitter } from '@angular/core';
import { UserInfoDTO } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { Router } from '@angular/router';
import { RouteStateService } from 'src/app/core/shared/services/route-state/route-state.service';
import { PlatformLocation } from '@angular/common';
import { CustomizeConfiguration } from 'src/config/customisation';
import { takeUntil } from 'rxjs/operators';

declare let MyAccountLoginForm: any;

@Component({
    selector: 'app-login-status',
    templateUrl: './login-status.component.html',
    styleUrls: ['./login-status.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginStatusComponent implements OnInit, OnDestroy {

    @ViewChild('modalLoginForm') modalLoginForm: ElementRef;

    @Input() showAccessButton: boolean;

    public canManageLicenses = false;

    public loginStatusUserInfo = new BehaviorSubject<UserInfoDTO>(null);

    public isAdmin = false;

    private userInfoRefreshedOnceObservable: BehaviorSubject<boolean>;
    private openLoginObservable: Subject<boolean> = new Subject<boolean>();

    private openPopup = false;
    private callBackUrl: string = null;
    private predefinedEmail: string = null;

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private readonly loginService: AuthorizeService,
        private readonly router: Router,
        private readonly routeStateService: RouteStateService,
        private readonly platformLocation: PlatformLocation,
        private readonly customisation: CustomizeConfiguration
    ) {
        this.userInfoRefreshedOnceObservable = this.loginService.getUserInfoRefreshedOnce();
    }

    public ngOnInit(): void {

        this.openLoginObservable
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: boolean) => {
                if (!value) {
                    return;
                }
                this.openLoginForm(this.openPopup, this.callBackUrl);
                this.openPopup = false;
            });


        this.routeStateService.pathParamOpenPopup
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.openPopup = (value === 'true');
                this.sendNotificationOpenFormWithUrl();
            });

        this.routeStateService.pathParamReturnUrl
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.callBackUrl = value;
                this.sendNotificationOpenFormWithUrl();
            });

        this.routeStateService.pathParamEmail
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.predefinedEmail = value;
            });

        this.subscribeToUserChanges();
        this.loginService.refreshUserInfo();

        if (this.openPopup && !this.loginService.getUserInfoRefreshingValue()) {
            if (this.predefinedEmail) {
                this.openLoginFormWithRegister(this.callBackUrl, this.predefinedEmail);
            } else {
                this.openLoginForm(true, this.callBackUrl);
            }
        }

        this.canManageLicenses = this.customisation.customizeIdName === 'LALEY';
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public hasUserInfoValue(): boolean {
        const userInfo: UserInfoDTO = this.loginStatusUserInfo.getValue();
        return !!userInfo;
    }

    public subscribeToUserChanges(): void {
        this.loginStatusUserInfo
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: UserInfoDTO) => {
                if (!this.userInfoRefreshedOnceObservable.getValue()) {
                    return;
                }

                let urlToken: string[] = [];
                if(!!(this.platformLocation as any)?.location)  {
                urlToken = (this.platformLocation as any)?.location.pathname.split('/');
                } else {
                    urlToken = (this.platformLocation as any)?.pathname.split('/');
                }
                const isCurrentUrlPublicLanding = urlToken.length > 2 &&
                    urlToken[2] === 'public';

                if (value && !value.hasLicense && !isCurrentUrlPublicLanding) {
                    this.router.navigate(['main/home/no-license']);
                }
            });

        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((user: UserInfoDTO) => {
                this.loginStatusUserInfo.next(user);
                this.isAdmin = user?.isAdmin;
            });
    }

    public openLoginFormWithRegister(returnUrl: string = null, predefinedEmail: string = null): void {
        const myAccount = new MyAccountLoginForm({
            url: environment.myAccountExternalLoginUrlWithRegister,
            loginmodel: true,
            data: {
                withoutRegisterMode: false,
                forceOpenLoginPopup: this.loginStatusUserInfo.getValue() == null,
                PredefinedEmail: predefinedEmail !== null ? predefinedEmail : null
            },
            onSuccess: () => {
                this.loginService.refreshUserInfo();
                if (returnUrl !== null) {
                    window.location.href = returnUrl;
                }
            }
        });
        myAccount.display();
        myAccount.show();
    }

    public openLoginForm(forceOpenLoginPopup = false, returnUrl: string = null): void {
        const myAccount = new MyAccountLoginForm({
            url: environment.myAccountExternalLoginUrl,
            loginmodel: true,
            data: {
                withoutRegisterMode: true,
                forceOpenLoginPopup: forceOpenLoginPopup || (this.loginStatusUserInfo.getValue() == null)
            },
            onSuccess: () => {
                this.loginService.refreshUserInfo();
                if (returnUrl !== null) {
                    this.openPopup = false;

                    this.userInfoRefreshedOnceObservable
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((value) => {
                            if (value) {
                                this.router.navigate([returnUrl]);
                            }
                        });
                }
            }
        });

        myAccount.display();
        myAccount.show();
    }

    public openMyAccount(): void {
        window.open(environment.myAccountExternalLink);
    }

    public openMyLicenses(): void {
        window.open(environment.myAccountOpenMyLicenses);
    }

    public closeSession(): void {
        const returnUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/es';
        document.location.href = environment.myAccountLogoutUrl + '?returnUrl=' + returnUrl;
    }

    private sendNotificationOpenFormWithUrl() {
        if (!!this.callBackUrl && this.openPopup) {
            this.openLoginObservable.next(true);
        }
    }
}
