export class CkeditorConstant {

    public static readonly POSITION_TOOLTIP = {
        south:      's',
        north:      'n',
        east:       'e',
        west:       'w',
        southEast:  'se',
        southWest:  'sw'
    }

    public static readonly CKEDITOR_EVENTS = {
        click: 'click',
        doubleClick: 'dblclick'
    }

    public static readonly TODO_LIST_ATTRIBUTE = 'todoListChecked';
    public static readonly TODO_LIST_PARENT_CLASS = "todo-list__label";
}
